const intlErrorHandler = error => {
  const showErrors = import.meta.env.VITE_SHOW_MISSING_TRANSLATIONS === true;

  if (!showErrors && error?.message?.startsWith('[@formatjs/intl Error MISSING_TRANSLATION]')) {
    return;
  }

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default intlErrorHandler;
