import { defineMessages } from 'react-intl';

const translations = defineMessages({
  title: {
    id: 'authentication.error.emailNotVerified.title',
    defaultMessage: 'Verify your email',
    description: 'Title of the authentication email not verified error page',
  },
  description: {
    id: 'authentication.error.emailNotVerified.description',
    defaultMessage:
      'You have received an email with a verification link. If the verification link has been expired, you can request a new verification email down below.',
    description: 'Description of the authentication email not verified error page',
  },
  emailAlreadyVerifiedMessage: {
    id: 'feedback.emailAlreadyVerifiedMessage',
    defaultMessage: 'Email address is already verified',
    description:
      'Warning message of the request verification email when the email is already verified',
  },
  emailVerifiedSuccessMessage: {
    id: 'feedback.emailVerifiedSuccessMessage',
    defaultMessage: 'Email address is verified',
    description: 'Success message of the request verification email when the email is verified',
  },
  emailVerifiedErrorMessage: {
    id: 'feedback.emailVerifiedErrorMessage',
    defaultMessage: 'Email address is not yet verified',
    description:
      'Error message of the request verification email when the email is not yet verified',
  },
  requestVerificationEmailSuccessMessage: {
    id: 'feedback.requestVerificationEmailSuccessMessage',
    defaultMessage: 'Email with verification link has been sent',
    description: 'Success message of the request verification email action',
  },
  requestVerificationAlreadySentMessage: {
    id: 'feedback.requestVerificationAlreadySentMessage',
    defaultMessage: 'Email with a valid verification link has already been sent',
    description: 'Warning message of the request verification email already been sent',
  },
  requestVerificationEmailErrorTitle: {
    id: 'feedback.requestVerificationEmailErrorTitle',
    defaultMessage: 'Email with verification link could not be sent',
    description: 'Error title of the request verification email action',
  },
  continue: {
    id: 'actions.continue',
    defaultMessage: 'Continue',
    description: 'Label of the continue action',
  },
  resendVerificationEmail: {
    id: 'actions.resendVerificationEmail',
    defaultMessage: 'Resend verification email',
    description: 'Label of the resend verification email button',
  },
  verify: {
    id: 'actions.verify',
    defaultMessage: 'Verify',
    description: 'Label of the verify action',
  },
});

export default translations;
