import { useEffect } from 'react';
import { useGetLatest } from '@afosto/hooks';
import { parseUrlQuery } from '@afosto/utils';
import {
  type AuthenticationMessageEventData,
  type AuthenticationNavigateHandler,
  type AuthenticationSessionResponseData,
  useAuthentication,
} from '../AuthenticationProvider';
import { AUTHENTICATION_REFERRER_SESSION_KEY } from '../constants';

export const AuthenticationCallback = () => {
  const { isAuthenticated, isRunningSilent, createSession, onNavigate, setSessionError } =
    useAuthentication();

  const getLatestOnNavigate = useGetLatest(onNavigate);

  useEffect(() => {
    const navigate = (getLatestOnNavigate() || {}) as AuthenticationNavigateHandler;
    const session = parseUrlQuery(window.location.search) as AuthenticationMessageEventData;
    const hasError = !!session?.error;

    if (!isRunningSilent) {
      if (session?.access_token && !hasError) {
        createSession(session as AuthenticationSessionResponseData);
      } else {
        if (hasError) {
          const { code, error } = session;

          navigate(
            `/auth/error?error=${error || ''}&code=${
              code ? `ATZ-IAM-${code.replace('ATZ-IAM-', '')}` : ''
            }`,
            { replace: true },
          );
          return;
        }

        navigate('/auth/login', { replace: true });
      }
    } else {
      window.parent.postMessage(
        {
          type: 'AUTH_CALLBACK',
          data: session,
        },
        window.location.origin,
      );
    }
  }, [createSession, getLatestOnNavigate, isRunningSilent, setSessionError]);

  useEffect(() => {
    if (isAuthenticated && !isRunningSilent) {
      const navigate = (getLatestOnNavigate() || {}) as AuthenticationNavigateHandler;
      const referrerPath = sessionStorage.getItem(AUTHENTICATION_REFERRER_SESSION_KEY);

      sessionStorage.removeItem(AUTHENTICATION_REFERRER_SESSION_KEY);

      navigate(referrerPath || '/', { replace: true });
    }
  }, [isAuthenticated, getLatestOnNavigate, isRunningSilent]);

  return null;
};
