import { forwardRef } from 'react';
import { LoadingButton as MuiLoadingButton } from '@mui/lab';
import CircularProgress from '../CircularProgress';
import type { ButtonProps } from './types';

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ startIcon, loadingIndicator, endIcon, ...props }, ref) => (
    <MuiLoadingButton
      {...props}
      loadingIndicator={
        loadingIndicator || <CircularProgress color="inherit" size="1em" thickness={5} />
      }
      loadingPosition={startIcon || endIcon ? (startIcon ? 'start' : 'end') : undefined}
      startIcon={startIcon}
      endIcon={endIcon}
      ref={ref}
    />
  ),
);

export default Button;
