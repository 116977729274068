import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

const useToast = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const enqueueToast = useCallback(
    (data = {}, options = {}) => {
      const { message } = data || {};
      return enqueueSnackbar(message, { ...options, ...data });
    },
    [enqueueSnackbar],
  );

  return { enqueueToast, closeToast: closeSnackbar };
};

export default useToast;
