import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Typography } from '@afosto/components';
import { isRelativeUri } from '@afosto/utils';

const EmptyMessage = ({ dense, icon, title, description, action, ...props }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    my="auto"
    pb={dense ? 1.5 : 6}
    pt={dense ? 1.5 : 6}
    {...props}
  >
    {icon && icon}
    {title && (
      <Typography fontWeight={500} mt={2.5} mb={1}>
        {title}
      </Typography>
    )}
    {description && (
      <Typography color="gray.700" sx={{ mb: dense ? 0 : 2.5 }}>
        {description}
      </Typography>
    )}
    {action && !action.href && (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Button onClick={action.onClick} variant="text">
        {action.label}
      </Button>
    )}
    {action && action.href && (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Button
        {...(isRelativeUri(action.href?.pathname ? action.href.pathname : action.href)
          ? { component: RouterLink, to: action.href }
          : { target: '_BLANK', href: action.href?.pathname || action.href })}
        onClick={action.onClick}
        endIcon={action.endIcon}
        variant="text"
      >
        {action.label}
      </Button>
    )}
  </Box>
);

EmptyMessage.propTypes = {
  dense: PropTypes.bool,
  icon: PropTypes.node,
  action: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

EmptyMessage.defaultProps = {
  dense: false,
  icon: undefined,
  action: undefined,
};

export default EmptyMessage;
