import { forwardRef } from 'react';
import { Grow as MuiGrow, type GrowProps } from '@mui/material';

const Grow = forwardRef<unknown, GrowProps>(({ children, ...otherProps }, ref) => (
  <MuiGrow ref={ref} {...otherProps}>
    {children}
  </MuiGrow>
));

export default Grow;
