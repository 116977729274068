import { CheckCircle, ExclamationTriangle, TimesCircle } from '@afosto/icons/solid';

export const JOB_STATUS_MAPPING = {
  UNKNOWN: {
    color: 'gray-400',
    icon: ExclamationTriangle,
  },
  PRINTED: {
    color: 'success',
    icon: CheckCircle,
  },
  FAILED: {
    color: 'error',
    icon: TimesCircle,
  },
};
