export default () => (
  <>
    <path
      style={{ fill: 'var(--icon-primary)' }}
      d="M5.25 19.88A1.13 1.13 0 0 0 6.38 21h11.25a1.12 1.12 0 0 0 1.12-1.13V16.5H5.25v3.38Zm10.69-13.5a.56.56 0 0 1-.56-.57V3h-9a1.13 1.13 0 0 0-1.13 1.13v6.75h13.5v-4.5h-2.81Z"
    />
    <path
      style={{ fill: 'var(--icon-secondary)' }}
      d="M15.94 6.38h2.81V6.2a.63.63 0 0 0-.18-.44l-2.59-2.59a.63.63 0 0 0-.44-.18h-.16V5.8a.56.56 0 0 0 .56.57Zm2.81 3.37v1.13H5.25V9.75A2.25 2.25 0 0 0 3 12v3.94a.56.56 0 0 0 .56.56h16.88a.56.56 0 0 0 .56-.56V12a2.25 2.25 0 0 0-2.25-2.25Zm-.56 4.78a.84.84 0 1 1 0-1.69.84.84 0 0 1 0 1.7Z"
    />
  </>
);
