import PropTypes from 'prop-types';
import { css, Global, useTheme } from '@emotion/react';
import { Box, LinearProgress, Typography } from '@afosto/components';
import Logo from '../Logo';

const SplashScreen = ({ message }) => {
  const theme = useTheme();

  return (
    <>
      <Global
        styles={css`
          body {
            background-color: ${theme.palette.gray[50]};
          }
        `}
      />
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        height="100vh"
      >
        <Logo dark height={32} />
        <LinearProgress sx={{ marginTop: 4, marginBottom: !message ? 9 : 4, width: 250 }} />
        {message && (
          <Typography color="gray.500" smb={-3}>
            {message}
          </Typography>
        )}
      </Box>
    </>
  );
};

SplashScreen.propTypes = {
  message: PropTypes.string,
};

SplashScreen.defaultProps = {
  message: undefined,
};

export default SplashScreen;
