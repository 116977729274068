import PropTypes from 'prop-types';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import history from './history';
import AuthenticationProvider from '../AuthenticationProvider';
import setupApiClient from './utils/setupApiClient';
import setupQueryClient from './utils/setupQueryClient';

setupApiClient();

const queryClient = setupQueryClient();

const AppBase = ({ children, clientId, disableAuthentication, scopes }) => (
  <QueryClientProvider client={queryClient}>
    <HistoryRouter history={history}>
      <AuthenticationProvider
        clientId={clientId}
        disableAuthentication={disableAuthentication}
        scopes={scopes}
      >
        {children}
      </AuthenticationProvider>
    </HistoryRouter>
  </QueryClientProvider>
);

AppBase.propTypes = {
  children: PropTypes.node.isRequired,
  clientId: PropTypes.string.isRequired,
  disableAuthentication: PropTypes.bool,
  scopes: PropTypes.array,
};

AppBase.defaultProps = {
  disableAuthentication: false,
  scopes: [],
};

export default AppBase;
