import { defineMessages } from 'react-intl';

export default defineMessages({
  printerTitle: {
    id: 'components.printDialog.selectPrinter.title',
    defaultMessage: 'Select a printer',
    description: 'select printer title of the printer dialog',
  },
  positionsTitle: {
    id: 'components.printDialog.selectPositions.title',
    defaultMessage: 'Exclude printing positions',
    description: 'select printing positions title of the printer dialog',
  },
  failedJobsTitle: {
    id: 'components.printDialog.failedJobs.title',
    defaultMessage: 'Failed printing jobs',
    description: 'failed jobs title of the printer dialog',
  },
  positionsDescription: {
    id: 'components.printDialog.selectPositions.description',
    defaultMessage: 'Select the position on the sheet where it should not print.',
    description: 'select printing positions description of the printer dialog',
  },
  back: {
    id: 'actions.back',
    defaultMessage: 'Back',
    description: 'label of the back action',
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
    description: 'label of the close action',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
    description: 'label of the cancel action',
  },
  print: {
    id: 'actions.print',
    defaultMessage: 'Print',
    description: 'label of the print action',
  },
  printWithSystemDialog: {
    id: 'actions.printWithSystemDialog',
    defaultMessage: 'Print with system dialog',
    description: 'label of the print action',
  },
  retryAll: {
    id: 'actions.retryAll',
    defaultMessage: 'Retry all',
    description: 'label of the retry all action',
  },
  paperType: {
    id: 'components.printDialog.printerTypes.paper',
    defaultMessage: 'A4 printer',
    description: 'label of the paper printer type',
  },
  labelType: {
    id: 'components.printDialog.printerTypes.label',
    defaultMessage: 'Label printer',
    description: 'label of the label printer type',
  },
  noLabelPrinters: {
    id: 'components.printDialog.selectPrinter.noLabelPrinters',
    defaultMessage: 'No label printers found',
    description: 'empty label printers list message of the printer dialog',
  },
  noA4Printers: {
    id: 'components.printDialog.selectPrinter.noA4Printers',
    defaultMessage: 'No A4 printers found',
    description: 'empty A4 printers list message of the printer dialog',
  },
  emptyTitle: {
    id: 'components.printDialog.selectPrinter.empty.title',
    defaultMessage: "We couldn't find any printer",
    description: 'empty printer list title of the printer dialog',
  },
  emptyDescription: {
    id: 'components.printDialog.selectPrinter.empty.description',
    defaultMessage:
      "We couldn't find any printers because there is no Spooler server running with connected printers. Read through our documentation to start a server or use the system dialog to print.",
    description: 'empty printer list description of the printer dialog',
  },
  goToDocs: {
    id: 'components.printDialog.selectPrinter.empty.goToDocs',
    defaultMessage: 'Open documentation',
    description: 'select printer title of the printer dialog',
  },
  spoolerDesktopDocsLink: {
    id: 'links.spoolerDesktopDocsLink',
    defaultMessage: 'https://afosto.com/docs/apps/spooler/desktop/',
    description: 'Link to the spooler desktop documentation',
  },
});
