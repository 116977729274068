import { createContext } from 'react';
import PropTypes from 'prop-types';
import usePresenceChannel from '../PusherProvider/hooks/usePresenceChannel';

const initialState = {
  jobsChannel: null,
};

export const JobsContext = createContext(initialState);

const JobsProvider = ({ children }) => {
  const jobsChannel = usePresenceChannel('jobs');

  return <JobsContext.Provider value={{ jobsChannel }}>{children}</JobsContext.Provider>;
};

JobsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

JobsProvider.defaultProps = {
  value: initialState,
};

export default JobsProvider;
