import {
  Box,
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogHeader,
  IconButton,
} from '@afosto/components';
import { Rotate, Times } from '@afosto/icons/solid';
import { JOB_STATUS_MAPPING } from '../../constants';
import { FormattedMessage } from 'react-intl';
import Icon from '../../../Icon';
import translations from '../../translations';

const FailedPrintJobs = ({ failedJobs, onRetryJob, onRetryAllJobs, isPrinting, onClose }) => {
  return (
    <>
      <DialogHeader
        title={
          <Box display="flex" alignItems="center">
            <FormattedMessage {...translations.failedJobsTitle} />
            <IconButton sx={{ ml: 'auto' }} variant={'minimal'} size="small" onClick={onClose}>
              <Icon icon={Times} />
            </IconButton>
          </Box>
        }
        sx={{ position: 'sticky', zIndex: 2, top: 0, backgroundColor: 'common.white' }}
      />

      <CardContent>
        {failedJobs.map(job => (
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
                pt: 1,
                px: 2,
                '&:last-of-type': { pb: 1 },
              }}
            >
              <Icon
                icon={JOB_STATUS_MAPPING[job.state].icon}
                color={JOB_STATUS_MAPPING[job.state].color}
                sx={{ fontSize: 16 }}
              />
              {job.content.split('/').at(-1).split('?').at(0)}
              <IconButton
                sx={{ ml: 'auto' }}
                variant="outlined"
                size="small"
                onClick={onRetryJob(job)}
              >
                <Icon icon={Rotate} />
              </IconButton>
            </CardContent>
          </Card>
        ))}
      </CardContent>

      <DialogActions
        sx={{ position: 'sticky', mt: 'auto', bottom: 0, backgroundColor: 'common.white' }}
      >
        <Button onClick={onClose} variant="outlined" color="secondary" size="large">
          <FormattedMessage {...translations.close} />
        </Button>
        <Button size="large" onClick={onRetryAllJobs} loading={isPrinting}>
          <FormattedMessage {...translations.retryAll} />
        </Button>
      </DialogActions>
    </>
  );
};

export default FailedPrintJobs;
