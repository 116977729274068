import md5 from 'md5';

export const generateHash = (value: object | unknown[] | string) => {
  let formattedValue = value;

  if (typeof value === 'object' || Array.isArray(value)) {
    formattedValue = JSON.stringify(value);
  }

  return md5(formattedValue as string);
};

export default generateHash;
