import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { Grow } from '@afosto/components';
import ToastContent from '../ToastContent';
import ToastContentExport from '../ToastContentExport';
import ToastContentUnsavedChanges from '../ToastContentUnsavedChanges';

const ToastProvider = ({ children, maxSnack, autoHideDuration, anchorOrigin }) => (
  <SnackbarProvider
    maxSnack={maxSnack}
    autoHideDuration={autoHideDuration}
    anchorOrigin={anchorOrigin}
    Components={{
      default: ToastContent,
      export: ToastContentExport,
      unsavedChanges: ToastContentUnsavedChanges,
    }}
    TransitionComponent={Grow}
    TransitionProps={{ style: { transformOrigin: 'bottom right' } }}
  >
    {children}
  </SnackbarProvider>
);

ToastProvider.propTypes = {
  anchorOrigin: PropTypes.shape({
    horizontal: PropTypes.oneOf(['center', 'left', 'right']).isRequired,
    vertical: PropTypes.oneOf(['bottom', 'top']).isRequired,
  }),
  autoHideDuration: PropTypes.number,
  children: PropTypes.node,
  maxSnack: PropTypes.number,
};

ToastProvider.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
  autoHideDuration: 4000,
  children: undefined,
  maxSnack: 4,
};

export default ToastProvider;
