import React from 'react';
import clsx from 'clsx';
import { CheckCircle, ExclamationTriangle, InfoCircle, ExclamationCircle} from '@afosto/icons/solid';
import Icon from '../Icon';
import * as Styled from './ToastIcon.styles';

const getSeverityIcon = severity => {
  switch (severity) {
    case 'success':
      return CheckCircle;
    case 'error':
      return ExclamationCircle;
    case 'warning':
      return ExclamationTriangle;
    case 'info':
      return InfoCircle;
    default:
      return null;
  }
};

const ToastIcon = ({ icon, severity }) => (
  <Styled.IconWrapper className={clsx({ [`toast-${severity}`]: severity })}>
    {!!icon && icon}
    {!icon && <Icon icon={getSeverityIcon(severity)} color="inherit" />}
  </Styled.IconWrapper>
);

export default ToastIcon;
