import { QueryClient } from '@tanstack/react-query';

const setupQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
          const statusCode = error?.response?.status;

          if ([404, 403].includes(statusCode)) {
            return false;
          }

          return failureCount < 1;
        },
        retryDelay: (attemptIndex, error) => {
          const statusCode = error?.response?.status;

          if (statusCode === 429) {
            const rateLimitResetHeader = error?.response?.headers['x-ratelimit-reset'];
            const rateLimit = parseInt(rateLimitResetHeader, 10);

            if (rateLimit && !Number.isNaN(rateLimit)) {
              return (rateLimit + 1) * 1000;
            }
          }

          return Math.min(1000 * 2 ** attemptIndex, 30000);
        },
      },
    },
  });

export default setupQueryClient;
