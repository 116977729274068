import { defineMessages } from 'react-intl';

const translations = defineMessages({
  title: {
    id: 'authentication.error.invalidSession.title',
    defaultMessage: 'Session invalid',
    description: 'Title of the authentication session invalid error page',
  },
  description: {
    id: 'authentication.error.invalidSession.description',
    defaultMessage: 'Your current session is invalid or has expired. Please log in again.',
    description: 'Description of the authentication session invalid error page',
  },
  loginButtonLabel: {
    id: 'action.login',
    defaultMessage: 'Login',
    description: 'label of the login button',
  },
});

export default translations;
