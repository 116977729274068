import { useNavigate } from 'react-router-dom';
import { AuthenticationProvider as AuthAuthenticationProvider } from '@afosto/auth-react/AuthenticationProvider';
import type {
  AuthenticationProviderProps,
  AuthenticationSession,
  AuthenticationUser,
} from '@afosto/auth-react/AuthenticationProvider/types';
import { graphQLClient } from '@afosto/graphql-client';
import * as Sentry from '@sentry/react';

const hasCredentialsProxy = import.meta.env['VITE_CREDENTIALS_PROXY'] === 'true';

const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { children, ...otherProps } = props;

  const navigate = useNavigate();

  const handleSessionCleared = () => {
    Sentry.setUser(null);
  };

  const handleSessionCreated = (session: AuthenticationSession | null) => {
    graphQLClient.setAuthorizationHeader(session?.accessToken || '');
  };

  const handleUserChanged = (user: AuthenticationUser | null) => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        username: user.name,
        name: user.name,
        email: user.email,
      });
    }
  };

  return (
    <AuthAuthenticationProvider
      {...otherProps}
      baseUri={hasCredentialsProxy ? window.location.origin : undefined}
      onNavigate={navigate}
      onSessionCleared={handleSessionCleared}
      onSessionCreated={handleSessionCreated}
      onUserChanged={handleUserChanged}
    >
      {children}
    </AuthAuthenticationProvider>
  );
};

export default AuthenticationProvider;
