import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmAddSubscriptionTitle: {
    id: 'components.subscriptionsProvider.confirmAddSubscriptionTitle',
    defaultMessage: 'Start free trial for {app}',
    description: 'Title of the confirm add subscription dialog',
  },
  confirmAddSubscriptionDescription: {
    id: 'components.subscriptionsProvider.confirmAddSubscriptionDescription',
    defaultMessage:
      'You enter into a 14-day trial period for {app}. After the trial period, the service will stop and you will need to add a payment method to your account to continue using it.',
    description: 'Description of the confirm add subscription dialog',
  },
  confirmAddCommerceSubscriptionTitle: {
    id: 'components.subscriptionsProvider.confirmAddCommerceSubscriptionTitle',
    defaultMessage: 'Activate Afosto Commerce',
    description: 'Title of the confirm add commerce subscription dialog',
  },
  confirmAddCommerceSubscriptionDescription: {
    id: 'components.subscriptionsProvider.confirmAddCommerceSubscriptionDescription',
    defaultMessage:
      'Do you have questions about the functionality of Afosto Commerce? Please don’t hesitate to contact us. We are happy to assist you.',
    description: 'Description of the confirm add commerce subscription dialog',
  },
  addAppFreeTrialSuccess: {
    id: 'components.subscriptionsProvider.addAppFreeTrialSuccess',
    defaultMessage: 'Free trial for app {app} started',
    description: 'Success message of the add app free trial action',
  },
  addAppFreeTrialFailed: {
    id: 'components.subscriptionsProvider.addAppFreeTrialFailed',
    defaultMessage: 'Free trial for app {app} could not be started',
    description: 'Error message of the add app free trial action',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
    description: 'Label of the cancel action',
  },
  activate: {
    id: 'actions.activate',
    defaultMessage: 'Activate',
    description: 'Common label for the activate action',
  },
  startFreeTrial: {
    id: 'actions.startFreeTrial',
    defaultMessage: 'Start free trial',
    description: 'Label of the start free trial action',
  },
  commerce: {
    id: 'services.commerce',
    defaultMessage: 'Commerce',
    description: 'label of the commerce service',
  },
  svc: {
    id: 'services.svc',
    defaultMessage: 'Service',
    description: 'label of the svc service',
  },
  cat: {
    id: 'services.cat',
    defaultMessage: 'Catalog',
    description: 'label of the cat service',
  },
  src: {
    id: 'services.src',
    defaultMessage: 'Instant search',
    description: 'label of the src service',
  },
  mes: {
    id: 'services.mes',
    defaultMessage: 'Messaging',
    description: 'label of the mes service',
  },
  lcs: {
    id: 'services.lcs',
    defaultMessage: 'Service',
    description: 'label of the lcs service',
  },
  odr: {
    id: 'services.odr',
    defaultMessage: 'Orders',
    description: 'label of the odr service',
  },
  qcq: {
    id: 'services.qcq',
    defaultMessage: 'Quicq',
    description: 'label of the qcq service',
  },
  spl: {
    id: 'services.spl',
    defaultMessage: 'Spooler',
    description: 'label of the spl service',
  },
  rel: {
    id: 'services.rel',
    defaultMessage: 'Relations',
    description: 'label of the rel service',
  },
  cnt: {
    id: 'services.cnt',
    defaultMessage: 'Files',
    description: 'label of the cnt service',
  },
  iam: {
    id: 'services.iam',
    defaultMessage: 'Identity and access management',
    description: 'label of the iam service',
  },
  wms: {
    id: 'services.wms',
    defaultMessage: 'Warehouse management',
    description: 'label of the wms service',
  },
});
