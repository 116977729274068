import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DialogActions, DialogHeader } from '@afosto/components';
import { getErrorMessage } from '@afosto/utils-shared';
import { AUTHENTICATION_ERROR_CODES, useAuthentication } from '../../../AuthenticationProvider';
import useToast from '../../../hooks/useToast';
import translations from './translations';

const EmailNotVerified = ({ onClose }) => {
  const { requestEmailVerification, silentAuthorization } = useAuthentication();
  const intl = useIntl();
  const { enqueueToast } = useToast();
  const [isVerifying, setIsVerifying] = useState(false);

  const handleRequestEmailVerification = async () => {
    try {
      await requestEmailVerification();

      enqueueToast({
        severity: 'success',
        message: intl.formatMessage(translations.requestVerificationEmailSuccessMessage),
        dismissible: true,
      });
    } catch (error) {
      const message = getErrorMessage(error);
      const { details } = error?.response?.data?.error || {};
      const { reference } = details || {};
      let errorMessage = intl.formatMessage(translations.requestVerificationEmailErrorTitle);
      let errorDescription;
      let errorSeverity = 'error';

      if (reference === AUTHENTICATION_ERROR_CODES.hasValidTokens) {
        errorSeverity = 'warning';
        errorMessage = intl.formatMessage(translations.requestVerificationAlreadySentMessage);
      } else if (reference === AUTHENTICATION_ERROR_CODES.emailAlreadyVerified) {
        errorSeverity = 'warning';
        errorMessage = intl.formatMessage(translations.emailAlreadyVerifiedMessage);
      } else {
        errorDescription = message;
      }

      enqueueToast({
        severity: errorSeverity,
        message: errorMessage,
        description: errorDescription,
        dismissible: true,
      });
    }
  };

  const handleVerify = async () => {
    try {
      setIsVerifying(true);
      const response = await silentAuthorization();

      if (response?.access_token) {
        enqueueToast({
          severity: 'success',
          message: intl.formatMessage(translations.emailVerifiedSuccessMessage),
          dismissible: true,
        });

        onClose();
        return;
      }

      enqueueToast({
        severity: 'error',
        message: intl.formatMessage(translations.emailVerifiedErrorMessage),
        dismissible: true,
      });
    } catch (error) {
      const message = getErrorMessage(error);

      enqueueToast({
        severity: 'error',
        message: intl.formatMessage(translations.emailVerifiedErrorMessage),
        description: message,
        dismissible: true,
      });
    } finally {
      setIsVerifying(false);
    }
  };

  return (
    <>
      <DialogHeader
        title={intl.formatMessage(translations.title)}
        description={intl.formatMessage(translations.description)}
      />
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleRequestEmailVerification}
          disabled={isVerifying}
          size="large"
        >
          <FormattedMessage {...translations.resendVerificationEmail} />
        </Button>
        <Button onClick={handleVerify} loading={isVerifying} size="large">
          <FormattedMessage {...translations.verify} />
        </Button>
      </DialogActions>
    </>
  );
};

EmailNotVerified.propTypes = {
  onClose: PropTypes.func,
};

EmailNotVerified.defaultProps = {
  onClose: undefined,
};

export default EmailNotVerified;
