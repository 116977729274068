import { forwardRef } from 'react';
import { isDefined } from '@afosto/utils';
import clsx from 'clsx';
import * as Styled from './Icon.styles';
import type { IconProps } from './types';

const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const {
    icon: IconComponent,
    size = 'inherit',
    className,
    color = 'inherit',
    style,
    secondColor = 'inherit',
    rotate,
    spinner = false,
    ...rest
  } = props;

  return (
    <Styled.Icon
      fontSize={size}
      className={clsx(className, {
        [`icon-${color}`]: color,
        [`icon-second-${secondColor}`]: secondColor,
        spinner,
        rotate: isDefined(rotate),
        [`rotate-${rotate}`]: isDefined(rotate),
      })}
      style={style}
      color={color}
      {...rest}
      ref={ref}
    >
      <IconComponent />
    </Styled.Icon>
  );
});

export default Icon;
