export const h1 = {
  fontSize: '28px',
  lineHeight: '36px',
  fontWeight: 500,
  letterSpacing: 'initial',
};

export const h2 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '24px',
  lineHeight: '32px',
  fontWeight: 500,
  letterSpacing: 'initial',
};

export const h3 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '22px',
  lineHeight: '32px',
  fontWeight: 500,
  letterSpacing: 'initial',
};

export const h4 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '20px',
  lineHeight: '32px',
  fontWeight: 500,
  letterSpacing: 'initial',
};

export const h5 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: 500,
  letterSpacing: 'initial',
};

export const h6 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 500,
  letterSpacing: 'initial',
};

export const body1 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  lineHeight: '24px',
};

export const body2 = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '12px',
  lineHeight: '20px',
};

export const bodySmall = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '12px',
  lineHeight: '20px',
};

export const bodyMedium = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  lineHeight: '24px',
};

export const bodyLarge = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '16px',
  lineHeight: '24px',
};

export const bodyExtraLarge = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '18px',
  lineHeight: '28px',
};

export const button = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  lineHeight: '16px',
  textTransform: 'none' as const,
};

export const buttonSmall = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '12px',
  lineHeight: '16px',
};

export const buttonMedium = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '14px',
  lineHeight: '16px',
};

export const buttonLarge = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '16px',
  lineHeight: '16px',
};

export const buttonExtraLarge = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '18px',
  lineHeight: '20px',
};

export const overline = {
  fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  fontWeight: 500,
};
