import {
  type Client,
  type Config,
  createClient as createRawClient,
  createConfig,
  formDataBodySerializer,
  jsonBodySerializer,
  urlSearchParamsBodySerializer,
  type Options,
  type QuerySerializerOptions,
  type RequestOptions,
  type RequestResult,
} from '@hey-api/client-fetch';
import { convertKeysToCamelCase } from './utils/convertKeysToCamelCase';
import { convertKeysToSnakeCase } from './utils/convertKeysToSnakeCase';

export type { Client, Config, Options, QuerySerializerOptions, RequestOptions, RequestResult };

export {
  createConfig,
  createRawClient,
  formDataBodySerializer,
  jsonBodySerializer,
  urlSearchParamsBodySerializer,
};

export const createClient = (config: Config & { enableProxy?: boolean } = {}) => {
  const { enableProxy = import.meta.env['VITE_CREDENTIALS_PROXY'] === 'true' } = config;
  const defaultSplitNumberForKeys = ['addressLine1', 'addressLine2'];

  const baseUrl = config.baseUrl || 'https://afosto.app';
  const client = createRawClient({
    ...config,
    baseUrl,
    throwOnError: true,
  });

  client.interceptors.request.use(async (request, options) => {
    const requestUrl = new URL(
      request.credentials === 'include' && enableProxy
        ? request.url?.replace(baseUrl, window.location.origin)
        : request.url,
    );

    const currentSearchParamsObject = Array.from(requestUrl.searchParams.entries()).reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, string>,
    );
    const newSearchParams = Object.entries(
      convertKeysToSnakeCase(currentSearchParamsObject),
    ).reduce((params, [key, value]) => {
      let paramKey = key;

      if (paramKey.startsWith('filter_')) {
        const paramParts = paramKey.split('_');
        const [filterPrefix, ...remainingParts] = paramParts || [];
        const [operator] = remainingParts.slice(-1);
        let filterFieldKey = remainingParts.slice(0, -1).join('_');

        if (
          [
            'actor_entity_id',
            'actor_entity_type',
            'actor_label',
            'context_entity_id',
            'context_entity_type',
          ].includes(filterFieldKey)
        ) {
          filterFieldKey = filterFieldKey.replace('_', '.');
        }

        paramKey = `${filterPrefix}[${filterFieldKey}][${operator}]`;
      }

      if (paramKey.startsWith('metric_')) {
        const paramParts = paramKey.split('_');
        const [metricPrefix, ...remainingParts] = paramParts || [];
        const [aggregationPart] = [...remainingParts].reverse();
        let hasAggregation = false;
        let metricFieldKey = remainingParts.join('_');

        if (['avg', 'count', 'sum'].includes(aggregationPart)) {
          hasAggregation = true;
          metricFieldKey = remainingParts.slice(0, -1).join('_');
        }

        paramKey = `${metricPrefix}[${metricFieldKey}]${
          hasAggregation ? `[${aggregationPart}]` : ''
        }`;
      }

      if (['page_after', 'page_size'].includes(paramKey)) {
        const [pagePrefix, pageField] = paramKey.split('_');
        paramKey = `${pagePrefix}[${pageField}]`;
      }

      params.append(paramKey, value);
      return params;
    }, new URLSearchParams());

    requestUrl.search = newSearchParams?.toString().replace(/%5B/g, '[').replace(/%5D/g, ']');

    if (
      ['PATCH', 'POST', 'PUT'].includes(request.method) &&
      request.headers.get('content-type') === 'application/json'
    ) {
      const body = await request.json();

      return new Request(requestUrl.toString(), {
        credentials: request.credentials,
        method: request.method,
        headers: new Headers(request.headers),
        body: JSON.stringify(
          convertKeysToSnakeCase(body, {
            // TODO: fix type later
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            excludeKeys: options?.transformOptions?.excludeKeys || [],
            splitNumbersForKeys: [
              ...defaultSplitNumberForKeys,
              // TODO: fix type later
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              ...(options?.transformOptions?.splitNumberForKeys || []),
            ],
            // TODO: fix type later
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            stopPaths: options?.transformOptions?.stopPaths || [],
          }),
        ),
      });
    }

    return new Request(requestUrl.toString(), request);
  });

  client.interceptors.response.use(async (response, request, options) => {
    if (!response.ok || response.status === 204) {
      return response;
    }

    const body = await response.json();

    return new Response(
      JSON.stringify(
        convertKeysToCamelCase(body, {
          // TODO: fix type later
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          excludeKeys: options?.transformOptions?.excludeKeys || [],
          // TODO: fix type later
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          mergeAmbiguousCharacters: options?.transformOptions?.mergeAmbiguousCharacters,
          // TODO: fix type later
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          stopPaths: options?.transformOptions?.stopPaths || [],
        }),
      ),
      {
        headers: response.headers,
        status: response.status,
        statusText: response.statusText,
      },
    );
  });

  return client;
};

export const client = createClient(createConfig());
