import { forwardRef } from 'react';
import clsx from 'clsx';
import { capitalize } from '@afosto/utils';
import getButtonVariantProps from '../utils/getButtonVariantProps';
import * as Styled from './IconButton.styles';
import type { IconButtonProps, IconButtonSizeClasses } from './types';
import type { IconButtonSizeCapitalized } from './types';
import { CUSTOM_ICON_BUTTON_SIZES } from './constants';

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { variant = 'minimal', size = 'medium', className, ...otherProps } = props || {};
  const variantProps = getButtonVariantProps(variant);
  const isCustom = (key: string) => CUSTOM_ICON_BUTTON_SIZES.includes(key);
  const capitalizedSize = capitalize(size) as IconButtonSizeCapitalized;
  const sizeKey: IconButtonSizeClasses = `size${capitalizedSize}`;

  return (
    <Styled.IconButton
      ref={ref}
      {...otherProps}
      size={isCustom(sizeKey) ? undefined : size}
      className={clsx(
        className,
        `size-${sizeKey}`,
        `color-${variantProps?.color} button-type-${variantProps?.variant}`,
      )}
    />
  );
});

export default IconButton;
