import PropTypes from 'prop-types';
import AppFrame from '../AppFrame';
import AppMain from '../AppMain';

const App = ({ children, clientId, disableAuthentication, scopes, ...otherProps }) => {
  const isRunningFromFrame = window.self !== window.top && clientId !== 'storybook';

  if (isRunningFromFrame) {
    return (
      <AppFrame clientId={clientId} disableAuthentication={disableAuthentication} scopes={scopes} />
    );
  }

  return (
    <AppMain
      {...otherProps}
      clientId={clientId}
      disableAuthentication={disableAuthentication}
      scopes={scopes}
    >
      {children}
    </AppMain>
  );
};

App.propTypes = {
  // eslint-disable-next-line react/require-default-props
  appState: PropTypes.object,
  /** The content in this wrapper will have acces to the theme, the user and other app level data. */
  children: PropTypes.node.isRequired,
  clientId: PropTypes.string.isRequired,
  /** Disable the Authentication provider. Mainly used for the storybook */
  disableAuthentication: PropTypes.bool,
  /** Disable HubSpot live chat. Mainly use fore the storybook */
  disableLiveChat: PropTypes.bool,
  /** Disable LogRocket. Mainly used for the storybook */
  disableLogRocket: PropTypes.bool,
  /** Disable the Notifications provider. Mainly used for the storybook */
  disableNotifications: PropTypes.bool,
  /** Disable the ReactQuery dev tools. Mainly used for the storybook */
  disableReactQueryDevTools: PropTypes.bool,
  /** Disable the Subscriptions provider. Mainly used for the storybook */
  disableSubscriptions: PropTypes.bool,
  scopes: PropTypes.array,
  userMenuItems: PropTypes.array,
};

App.defaultProps = {
  appState: {},
  disableAuthentication: false,
  disableLogRocket: false,
  disableNotifications: false,
  disableReactQueryDevTools: false,
  disableSubscriptions: false,
  scopes: [],
  userMenuItems: undefined,
};

export default App;
