import { isDefined } from './isDefined';

export const createUrlWithParams = (url: string, params: object, { encode = true } = {}) => {
  const [baseUrl, hash] = url.split('#');
  const newUrl = Object.keys(params)
    .reduce((acc, param) => {
      const paramKey = param as keyof typeof params;

      if (!isDefined(params[paramKey])) {
        return acc;
      }

      const encodedParamValue = encode ? encodeURIComponent(params[paramKey]) : params[paramKey];

      return `${acc}${param}=${encodedParamValue}&`;
    }, `${baseUrl}?`)
    .replace(/.$/, '')
    .trim();

  return `${newUrl}${hash ? `#${hash}` : ''}`;
};

export default createUrlWithParams;
