import { useEffect, useRef } from 'react';

const DEFAULT_EVENT_OPTIONS = {
  enabled: true,
};

const useEvent = (channel, eventName, callback, options = {}) => {
  const { enabled } = { ...DEFAULT_EVENT_OPTIONS, ...options };
  const eventBindedRef = useRef(false);

  useEffect(() => {
    if (!channel || !eventName) {
      return;
    }

    if (enabled && !eventBindedRef.current) {
      channel.bind(eventName, callback);
      eventBindedRef.current = true;
    } else if (eventBindedRef.current) {
      channel.unbind(eventName, callback);
      eventBindedRef.current = false;
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (channel && eventName && eventBindedRef.current) {
        channel.unbind(eventName, callback);
        eventBindedRef.current = false;
      }
    };
  }, [channel, enabled, eventName, callback]);
};

export default useEvent;
