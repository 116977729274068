import { forwardRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ExclamationTriangle, TurnDownLeft } from '@afosto/icons/solid';
import { Box, Button, Typography } from '@afosto/components';
import Icon from '../Icon';
import ToastIcon from '../ToastIcon';
import * as Styled from './ToastContentUnsavedChanges.styles';
import translations from './translations';
import type { ToastContentUnsavedChangesProps } from './types';

const ToastContentUnsavedChanges = forwardRef<HTMLDivElement, ToastContentUnsavedChangesProps>(
  (props, ref) => {
    const { message, onDiscard, onSubmit, severity, style } = props || {};

    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async () => {
      try {
        setIsSubmitting(true);

        if (onSubmit && typeof onSubmit === 'function') {
          await onSubmit();
        }

        setIsSubmitting(false);
      } catch {
        setIsSubmitting(false);
      }
    };

    return (
      <Styled.ToastBase ref={ref} style={style}>
        {severity && (
          <ToastIcon
            severity={severity}
            icon={<Icon icon={ExclamationTriangle} color="inherit" />}
          />
        )}
        <Typography fontWeight={500} color="white">
          {message}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 'auto' }}>
          <Button
            disabled={isSubmitting}
            type="button"
            onClick={onDiscard}
            variant="text"
            sx={{
              color: theme => theme.palette.common.white,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.12)!important',
              },
            }}
          >
            <FormattedMessage {...translations.discard} />
          </Button>
          <Button
            loading={isSubmitting}
            onClick={handleSubmit}
            startIcon={<Icon icon={TurnDownLeft} color="inherit" />}
          >
            <FormattedMessage {...translations.save} />
          </Button>
        </Box>
      </Styled.ToastBase>
    );
  },
);

export default ToastContentUnsavedChanges;
