import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'actions.close',
    defaultMessage: 'Close',
    description: 'Label for the close action',
  },
  download: {
    id: 'actions.download',
    defaultMessage: 'Download',
    description: 'Label for the download action',
  },
  retry: {
    id: 'actions.retry',
    defaultMessage: 'Retry',
    description: 'Label for the retry action',
  },
});
