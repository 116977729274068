export const parseUrlQuery = (query: string): { [key: string]: unknown } => {
  const [, queryParams] = query.split('?');
  const params = new URLSearchParams(queryParams);
  return Array.from(params.keys()).reduce(
    (acc, key) => ({
      ...acc,
      [key]: params.get(key),
    }),
    {},
  );
};

export default parseUrlQuery;
