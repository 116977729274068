import { generateHash } from './generateHash';

export interface GravatarOptions {
  d?: string;
  size?: number;
}

export const gravatarUrl = (email: string, options: GravatarOptions = {}) => {
  const { d, size = 40 } = options;
  return `https://www.gravatar.com/avatar/${generateHash(email)}?s=${size}${d ? `&d=${d}` : ''}`;
};

export default gravatarUrl;
