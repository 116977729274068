import { forwardRef } from 'react';
import { Alert as MuiAlert } from '@mui/material';
import { isDefined } from '@afosto/utils';
import Divider from '../Divider';
import IconButton from '../IconButton';
import SvgIcon from '../SvgIcon';
import { DEFAULT_ALERT_ICON_MAPPING } from './constants';
import type { AlertProps } from './types';

const Alert = forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
  const {
    children,
    icon,
    color,
    iconMapping: providedIconMapping,
    severity = 'secondary',
    action,
    onClose,
    variant = 'standard',
    ...otherProps
  } = props || {};
  const iconMapping = {
    ...DEFAULT_ALERT_ICON_MAPPING,
    ...(providedIconMapping || {}),
  };
  const dividerColor = severity === 'secondary' ? 'gray.75' : `${color || severity}.200`;
  const alertIcon = icon === true ? iconMapping[severity] : icon;
  const actionArray = Array.isArray(action) ? action : [action];
  const actionsList = [
    ...(isDefined(action) && variant !== 'text' ? actionArray : []),
    ...(onClose && typeof onClose === 'function'
      ? [
          <IconButton variant="minimalLight2" size="small" onClick={onClose}>
            <SvgIcon>
              <path d="M13.6 12l4.8-4.8.99-1a.38.38 0 000-.53l-1.06-1.06a.38.38 0 00-.53 0l-5.8 5.8-5.8-5.8a.38.38 0 00-.53 0L4.61 5.67a.38.38 0 000 .53l5.8 5.8-5.8 5.8a.38.38 0 000 .53l1.06 1.06c.15.15.38.15.53 0l5.8-5.8 4.8 4.8 1 1c.15.15.38.15.53 0l1.06-1.06a.38.38 0 000-.53l-5.8-5.8z" />
            </SvgIcon>
          </IconButton>,
        ]
      : []),
  ];
  const formattedAction = actionsList.reduce(
    (acc, item, idx) => [
      ...acc,
      item,
      ...(idx + 1 !== actionsList.length
        ? [
            <Divider
              key={`divider_${item.key}`}
              orientation="vertical"
              sx={{ height: 20, borderColor: dividerColor, mx: 1.5 }}
            />,
          ]
        : []),
    ],
    [],
  );

  return (
    <MuiAlert
      ref={ref}
      icon={icon !== false ? alertIcon : false}
      color={color || severity}
      variant={variant}
      action={formattedAction}
      {...otherProps}
    >
      <div>{children}</div>
      {variant === 'text' && actionArray}
    </MuiAlert>
  );
});

export default Alert;
