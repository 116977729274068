import { forwardRef } from 'react';
import {
  Dialog as MuiDialog,
  type DialogProps,
  type GrowProps,
  type SlideProps,
} from '@mui/material';
import Grow from '../Grow';
import Slide from '../Slide';

const GrowTransition = forwardRef<unknown, GrowProps>((props, ref) => {
  const { children, ...rest } = props || {};

  return (
    <Grow ref={ref} {...rest}>
      {children}
    </Grow>
  );
});

const SlideTransition = forwardRef<unknown, SlideProps>((props, ref) => {
  const { children, direction = 'up', ...rest } = props || {};

  return (
    <Slide ref={ref} {...rest} direction={direction}>
      {children}
    </Slide>
  );
});

const Dialog = forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
  const { TransitionComponent = props.fullScreen ? SlideTransition : GrowTransition, ...rest } =
    props || {};

  return <MuiDialog ref={ref} {...rest} TransitionComponent={TransitionComponent} />;
});

export default Dialog;
