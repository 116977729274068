import { useEffect, useState } from 'react';
import { Dialog } from '@afosto/components';
import { AUTHENTICATION_ERROR_CODES, useAuthentication } from '../AuthenticationProvider';
import DefaultError from './components/DefaultError';
import EmailNotVerified from './components/EmailNotVerified';
import InvalidSession from './components/InvalidSession';

const SessionErrorDialog = () => {
  const { isAuthenticated, sessionError } = useAuthentication();
  const [showDialog, setShowDialog] = useState(false);
  const { code } = sessionError || {};

  useEffect(() => {
    if (isAuthenticated && sessionError) {
      setShowDialog(true);
    }
  }, [isAuthenticated, sessionError]);

  const isEmailVerifiedError = code === AUTHENTICATION_ERROR_CODES.emailNotVerified;
  const isInvalidSessionError = code === AUTHENTICATION_ERROR_CODES.sessionInvalid;
  const isDefaultError = !isEmailVerifiedError && !isInvalidSessionError;

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <Dialog maxWidth="xs" fullWidth open={showDialog}>
      {isEmailVerifiedError && <EmailNotVerified onClose={handleCloseDialog} />}
      {isInvalidSessionError && <InvalidSession onClose={handleCloseDialog} />}
      {isDefaultError && <DefaultError onClose={handleCloseDialog} />}
    </Dialog>
  );
};

export default SessionErrorDialog;
