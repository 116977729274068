import { defineMessages } from 'react-intl';

export default defineMessages({
  discard: {
    id: 'actions.discard',
    defaultMessage: 'Discard',
    description: 'Label for the discard action',
  },
  save: {
    id: 'actions.save',
    defaultMessage: 'Save',
    description: 'Label for the save action',
  },
});
