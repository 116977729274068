import { createBrowserHistory } from 'history';
import { getUrlWithPreservedQueryParams } from '@afosto/utils';

const PRESERVED_QUERY_PARAMS = ['t'];

const browserHistory = createBrowserHistory();
const history = {
  ...browserHistory,
  push(to, state) {
    const path = to?.pathname || to;
    const pathState = to?.state || state;
    const search = to?.search || '';
    browserHistory.push(
      getUrlWithPreservedQueryParams(`${path}${search}`, PRESERVED_QUERY_PARAMS),
      pathState,
    );
  },
  replace(path, state) {
    if (typeof path === 'string') {
      browserHistory.replace(getUrlWithPreservedQueryParams(path, PRESERVED_QUERY_PARAMS), state);
    } else {
      const { pathname, search, state: pathState } = path;
      browserHistory.replace(
        getUrlWithPreservedQueryParams(`${pathname}${search}`, PRESERVED_QUERY_PARAMS),
        pathState || state,
      );
    }
  },
};

export default history;
