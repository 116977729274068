import { createContext } from 'react';
import PropTypes from 'prop-types';

const initialAppState = {};

export const AppContext = createContext(initialAppState);

const AppProvider = ({ children, value }) => (
  <AppContext.Provider value={value}>{children}</AppContext.Provider>
);

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
};

AppProvider.defaultProps = {
  value: initialAppState,
};

export default AppProvider;
