import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route } from 'react-router-dom';
import Callback from '../Authentication/components/Callback';
import AppBase from '../AppBase';

const AppFrame = ({ clientId, disableAuthentication, scopes }) => {
  return (
    <AppBase clientId={clientId} disableAuthentication={disableAuthentication} scopes={scopes}>
      <React.StrictMode>
        <Routes>
          <Route path="/auth/callback" element={<Callback />} />
        </Routes>
      </React.StrictMode>
    </AppBase>
  );
};

AppFrame.propTypes = {
  clientId: PropTypes.string.isRequired,
  disableAuthentication: PropTypes.bool,
  scopes: PropTypes.array,
};

AppFrame.defaultProps = {
  disableAuthentication: false,
  scopes: [],
};

export default AppFrame;
