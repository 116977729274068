const buttonVariantProps = {
  primary: {
    variant: 'contained',
    color: 'primary',
  },
  secondary: {
    variant: 'contained',
    color: 'secondary',
  },
  error: {
    variant: 'contained',
    color: 'error',
  },
  warning: {
    variant: 'contained',
    color: 'warning',
  },
  outlined: {
    variant: 'outlined',
    color: 'default',
  },
  minimal: {
    variant: 'text',
    color: 'textPrimary',
  },
  minimalDark1: {
    variant: 'text',
    color: 'minimalDark1',
  },
  minimalDark2: {
    variant: 'text',
    color: 'minimalDark2',
  },
  minimalLight1: {
    variant: 'text',
    color: 'minimalLight1',
  },
  minimalLight2: {
    variant: 'text',
    color: 'minimalLight2',
  },
  success: {
    variant: 'contained',
    color: 'success',
  },
};

const getButtonVariantProps = (
  variant:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'outlined'
    | 'minimal'
    | 'minimalDark1'
    | 'minimalDark2'
    | 'minimalLight1'
    | 'minimalLight2'
    | undefined,
) => {
  if (variant && buttonVariantProps[variant]) {
    return buttonVariantProps[variant];
  }

  return buttonVariantProps.primary;
};

export default getButtonVariantProps;
