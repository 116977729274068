import { defineMessages } from 'react-intl';

const translations = defineMessages({
  title: {
    id: 'authentication.error.sessionError.title',
    defaultMessage: 'Session invalid or expired',
    description: 'Title of the default error in the session error dialog',
  },
  description: {
    id: 'authentication.error.sessionError.description',
    defaultMessage: 'Something went wrong with the session.',
    description: 'description of the default error in the session error dialog',
  },
  titleExtendError: {
    id: 'authentication.error.sessionError.titleExtendError',
    defaultMessage: 'Invalid session',
    description:
      'Title of the default error in the session error dialog when the session could not be extended',
  },
  descriptionExtendError: {
    id: 'authentication.error.sessionError.descriptionExtendError',
    defaultMessage:
      'Session is invalid and could not be extended. Log in again to renew your session.',
    description:
      'Description of the default error in the session error dialog when the session could not be extended',
  },
  loginAgain: {
    id: 'actions.logInAgain',
    defaultMessage: 'Log in again',
    description: 'label of the log in again action',
  },
  extendSession: {
    id: 'actions.extendSession',
    defaultMessage: 'Extend session',
    description: 'label of the extend session action',
  },
  extendSessionErrorTitle: {
    id: 'feedback.extendSessionErrorTitle',
    defaultMessage: 'Session could not be extended',
    description: 'Error title of the extend session action',
  },
});

export default translations;
