import { createContext, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addSubscriptionMutation,
  listSubscriptionsOptions,
} from '@afosto/subscription-service/@tanstack/queries';
import { capitalize } from '@afosto/utils';
import { getErrorMessage } from '@afosto/utils-shared';
import { useAuthentication } from '../AuthenticationProvider';
import { useConfirm } from '../ConfirmDialog';
import useToast from '../hooks/useToast';
import translations from './translations';

export const SubscriptionsContext = createContext({});

const SubscriptionsProvider = ({ children, disableSubscriptions }) => {
  const confirm = useConfirm();
  const intl = useIntl();
  const { isAuthenticated } = useAuthentication();
  const { enqueueToast } = useToast();

  const [isAddingSubscription, setIsAddingSubscription] = useState(false);

  const addSubscriptionRequest = useMutation(addSubscriptionMutation());

  const {
    data: response,
    isLoading: isFetchingSubscriptions,
    refetch: refetchSubscriptions,
  } = useQuery({
    ...listSubscriptionsOptions(),
    enabled: isAuthenticated && !disableSubscriptions,
  });
  const subscriptions = response?.data || [];

  const subscriptionCodes = useMemo(
    () => [...new Set((subscriptions || []).map(subscription => subscription.code))],
    [subscriptions],
  );

  const handleAddSubscription = useCallback(
    (appCode, codes) => async response => {
      const subscriptionLabel = translations[appCode]
        ? intl.formatMessage(translations[appCode])
        : appCode;

      try {
        if (response === 'add') {
          setIsAddingSubscription(true);

          const notInstalledSubscriptionCodes = codes.filter(
            subscriptionCode => !subscriptionCodes.includes(subscriptionCode),
          );

          await Promise.all(
            notInstalledSubscriptionCodes.map(code =>
              addSubscriptionRequest.mutateAsync({
                body: {
                  data: {
                    code,
                  },
                },
              }),
            ),
          );
          refetchSubscriptions();
          enqueueToast({
            message: intl.formatMessage(translations.addAppFreeTrialSuccess, {
              app: subscriptionLabel,
            }),
            severity: 'success',
            dismissible: true,
          });
        }

        return Promise.resolve();
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueToast({
          message: intl.formatMessage(translations.addAppFreeTrialFailed, {
            app: subscriptionLabel,
          }),
          severity: 'error',
          ...(message ? { description: message } : {}),
        });
        return Promise.reject(error);
      }
    },
    [addSubscriptionRequest, enqueueToast, intl, refetchSubscriptions, subscriptionCodes],
  );

  const addSubscription = useCallback(
    async (appCode, codes, options) => {
      try {
        if (options?.disableConfirm) {
          return handleAddSubscription(appCode, codes, options)('add');
        }

        const subscriptionLabel = translations[appCode]
          ? intl.formatMessage(translations[appCode])
          : appCode;
        await confirm({
          title: intl.formatMessage(
            translations[
              ['commerce'].includes(appCode)
                ? `confirmAdd${capitalize(appCode)}SubscriptionTitle`
                : 'confirmAddSubscriptionTitle'
            ],
            ['commerce'].includes(appCode)
              ? {}
              : {
                  app: subscriptionLabel,
                },
          ),
          description: intl.formatMessage(
            translations[
              ['commerce'].includes(appCode)
                ? `confirmAdd${capitalize(appCode)}SubscriptionDescription`
                : 'confirmAddSubscriptionDescription'
            ],
            ['commerce'].includes(appCode)
              ? {}
              : {
                  app: subscriptionLabel,
                },
          ),
          catchOnCancel: false,
          buttons: [
            {
              label: intl.formatMessage(translations.cancel),
              type: 'cancel',
              variant: 'outlined',
              color: 'secondary',
            },
            {
              label: intl.formatMessage(
                translations[['commerce'].includes(appCode) ? 'activate' : 'startFreeTrial'],
              ),
              response: 'add',
              type: 'submit',
              variant: 'contained',
              color: 'primary',
              onClick: handleAddSubscription(appCode, codes, options),
            },
          ],
        });

        setIsAddingSubscription(false);
      } catch (error) {
        setIsAddingSubscription(false);
        return Promise.reject(error);
      }
    },
    [confirm, handleAddSubscription, intl],
  );

  return (
    <SubscriptionsContext.Provider
      value={{
        addSubscription,
        isAddingSubscription,
        isFetchingSubscriptions,
        refetchSubscriptions,
        subscriptionCodes,
        subscriptions,
      }}
    >
      {children}
    </SubscriptionsContext.Provider>
  );
};

SubscriptionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  disableSubscriptions: PropTypes.bool,
};

SubscriptionsProvider.defaultProps = {
  disableSubscriptions: false,
};

export default SubscriptionsProvider;
