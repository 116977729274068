import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { Typography, LinearProgress, Box, Link } from '@afosto/components';
import { Download } from '@afosto/icons/solid';
import { getJobOptions } from '@afosto/project-service/@tanstack/queries';
import Icon from '../Icon';
import { useJobs } from '../JobsProvider';
import { useEvent } from '../PusherProvider';
import ToastCloseButton from '../ToastCloseButton';
import ToastIcon from '../ToastIcon';
import useToast from '../hooks/useToast';
import * as Styled from './ToastContentExport.styles';
import translations from './translations';

const ToastContentExport = React.forwardRef(
  (
    {
      id,
      icon,
      jobId,
      message,
      description,
      failedMessage,
      failedDescription,
      successMessage,
      successDescription,
      dismissible,
      onRetry,
      style,
    },
    ref,
  ) => {
    const { jobsChannel } = useJobs();
    const queryClient = useQueryClient();
    const { closeToast } = useToast();
    const [isFinished, setIsFinished] = useState(false);
    const [job, setJob] = useState(null);
    const [severity, setSeverity] = useState('default');
    const { label, url } = job?.result?.data || {};
    const downloadUrl = url ? `${url}?as=${label}` : null;
    const hasDownload = !!url;
    const jobStatus = job?.status?.toLowerCase();

    const handleClose = () => {
      closeToast(id);
    };

    const handleRetry = () => {
      if (onRetry && typeof onRetry === 'function') {
        onRetry();
      }

      closeToast(id);
    };

    useEvent(
      jobsChannel,
      'job.finished',
      async result => {
        if (result?.id === jobId) {
          const response = await queryClient.fetchQuery(
            getJobOptions({
              path: {
                id: jobId,
              },
            }),
          );
          setJob(response?.data || null);
          setSeverity(response?.data?.status?.toLowerCase() === 'failed' ? 'error' : 'success');
          setIsFinished(true);
        }
      },
      {
        enabled: !!jobId,
      },
    );

    return (
      <Styled.ToastBase ref={ref} style={style}>
        <Box display="flex" alignItems="flex-start">
          {isFinished && <ToastIcon severity={severity} />}
          {!isFinished && (
            <ToastIcon severity={severity} icon={<Icon icon={Download} color="inherit" />} />
          )}
          <Box flex={1}>
            <Box display="flex" alignItems="flex-start">
              <div>
                <Typography fontWeight={500} color="white">
                  {!isFinished && message}
                  {isFinished && jobStatus !== 'failed' && (successMessage || message)}
                  {isFinished && jobStatus === 'failed' && (failedMessage || message)}
                </Typography>
                {description && (
                  <Typography color="gray.200" mt={0.5}>
                    {!isFinished && description}
                    {isFinished && jobStatus !== 'failed' && (successDescription || description)}
                    {isFinished && jobStatus === 'failed' && (failedDescription || description)}
                  </Typography>
                )}
              </div>
              {dismissible && <ToastCloseButton onClose={handleClose} />}
            </Box>
            {!isFinished && (
              <Box my={2}>
                <LinearProgress color="success" variant="indeterminate" />
              </Box>
            )}
            {isFinished && jobStatus !== 'failed' && hasDownload && (
              <Box mt={1.5}>
                <Link
                  href={downloadUrl}
                  target="_blank"
                  onClick={handleClose}
                  sx={{ color: theme => theme.palette.common.white }}
                >
                  <FormattedMessage {...translations.download} />
                </Link>
              </Box>
            )}
            {isFinished && jobStatus === 'failed' && (
              <Box display="flex" gap={2.5} mt={1.5}>
                {onRetry && (
                  <Link
                    component="button"
                    type="button"
                    onClick={handleRetry}
                    sx={{ color: theme => theme.palette.common.white }}
                  >
                    <FormattedMessage {...translations.retry} />
                  </Link>
                )}
                <Link
                  component="button"
                  type="button"
                  onClick={handleClose}
                  sx={{ color: theme => theme.palette.common.white }}
                >
                  <FormattedMessage {...translations.close} />
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Styled.ToastBase>
    );
  },
);

ToastContentExport.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.elementType,
  severity: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  dismissible: PropTypes.bool,
  style: PropTypes.object,
};

ToastContentExport.defaultProps = {
  description: undefined,
  icon: undefined,
  severity: undefined,
  dismissible: false,
  style: undefined,
};

export default ToastContentExport;
