import { createTheme, listItemIconClasses } from '@mui/material';
import { deepmerge } from '@mui/utils';
import {
  cerulean,
  cyan,
  darkGray,
  gray,
  magenta,
  orange,
  primary,
  purple,
  red,
  rose,
  secondary,
  springGreen,
  vermilion,
  vibrant,
  volta,
  yellow,
} from './colors';
import * as typography from './typography';
import SvgIcon from '../SvgIcon';
import type { AvatarProps } from '../Avatar/types';
import type { AvatarGroupProps } from '../AvatarGroup/types';

const baseTheme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 900,
      xl: 1200,
      xxl: 1536,
    },
  },
  drawerWidths: {
    mobile: 320,
    desktop: 240,
  },
  palette: {
    background: {
      default: gray[50],
      paper: '#fff',
    },
    navigation: {
      background: darkGray[300],
      border: 'rgba(255, 255, 255, 0.1)',
    },
    table: {
      head: gray[25],
    },
    divider: gray[100],
    text: {
      primary: gray[900],
      secondary: gray[800],
      tertiary: gray[700],
      disabled: gray[500],
    },
    primary: {
      ...primary,
      dark: primary[700],
      light: primary[400],
      main: primary[500],
    },
    secondary: {
      ...secondary,
      dark: secondary[700],
      light: secondary[400],
      main: secondary[500],
    },
    error: {
      ...red,
      dark: red[700],
      light: red[400],
      main: red[500],
    },
    info: {
      ...primary,
      dark: primary[700],
      light: primary[400],
      main: primary[500],
    },
    success: {
      ...springGreen,
      dark: springGreen[700],
      light: springGreen[400],
      main: springGreen[500],
    },
    warning: {
      ...orange,
      dark: orange[700],
      light: orange[400],
      main: orange[500],
      contrastText: '#fff',
    },
    grey: {
      ...gray,
      100: gray[75],
      200: gray[100],
      300: gray[200],
      A100: gray[100],
      A200: gray[200],
      A400: gray[400],
      A700: gray[700],
    },
    cerulean,
    cyan,
    darkGray,
    gray,
    magenta,
    orange,
    purple,
    red,
    rose,
    springGreen,
    vermilion,
    vibrant,
    volta,
    yellow,
  },
  typography: {
    fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
    ...typography,
  },
});

export const theme = createTheme(
  deepmerge(baseTheme, {
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(1.5, 2.5),
          },
          standard: {
            padding: '15px 19px',
            border: '1px solid',
          },
          text: {
            padding: 0,
          },
          standardSecondary: {
            color: baseTheme.palette.gray[700],
            backgroundColor: baseTheme.palette.gray[25],
            borderColor: baseTheme.palette.gray[100],
            '.MuiAlert-action': {
              color: baseTheme.palette.gray[700],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.gray[75],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.gray[100],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.gray[600],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.gray[900],
            },
          },
          standardInfo: {
            color: baseTheme.palette.primary[600],
            backgroundColor: baseTheme.palette.primary[100],
            borderColor: baseTheme.palette.primary[300],
            '.MuiAlert-action': {
              color: baseTheme.palette.primary[600],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.primary[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.primary[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.primary[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.primary[700],
            },
          },
          standardError: {
            color: baseTheme.palette.red[600],
            backgroundColor: baseTheme.palette.red[100],
            borderColor: baseTheme.palette.red[300],
            '.MuiAlert-action': {
              color: baseTheme.palette.red[600],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.red[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.red[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.red[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.red[700],
            },
          },
          standardSuccess: {
            color: baseTheme.palette.springGreen[600],
            backgroundColor: baseTheme.palette.springGreen[100],
            borderColor: baseTheme.palette.springGreen[300],
            '.MuiAlert-action': {
              color: baseTheme.palette.springGreen[600],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.springGreen[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.springGreen[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.springGreen[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.springGreen[700],
            },
          },
          standardWarning: {
            color: baseTheme.palette.orange[700],
            backgroundColor: baseTheme.palette.orange[100],
            borderColor: baseTheme.palette.orange[300],
            '.MuiAlert-action': {
              color: baseTheme.palette.orange[700],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.orange[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.orange[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.orange[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.orange[800],
            },
          },
          textSecondary: {
            color: baseTheme.palette.gray[700],
            '.MuiAlert-action': {
              color: baseTheme.palette.gray[700],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.gray[75],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.gray[100],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.gray[600],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.gray[900],
            },
          },
          textInfo: {
            color: baseTheme.palette.primary[600],
            '.MuiAlert-action': {
              color: baseTheme.palette.primary[600],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.primary[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.primary[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.primary[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.primary[700],
            },
          },
          textError: {
            color: baseTheme.palette.red[600],
            '.MuiAlert-action': {
              color: baseTheme.palette.red[600],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.red[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.red[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.red[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.red[700],
            },
          },
          textSuccess: {
            color: baseTheme.palette.springGreen[600],
            '.MuiAlert-action': {
              color: baseTheme.palette.springGreen[600],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.springGreen[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.springGreen[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.springGreen[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.springGreen[700],
            },
          },
          textWarning: {
            color: baseTheme.palette.orange[700],
            '.MuiAlert-action': {
              color: baseTheme.palette.orange[700],
              '.MuiButton-root:hover': {
                backgroundColor: baseTheme.palette.orange[200],
              },
              '.MuiButton-root:active': {
                backgroundColor: baseTheme.palette.orange[300],
              },
            },
            '.MuiAlert-icon': {
              color: baseTheme.palette.orange[500],
            },
            '.MuiAlertTitle-root': {
              color: baseTheme.palette.orange[800],
            },
          },
          icon: {
            marginRight: baseTheme.spacing(1.5),
            paddingTop: 3,
            paddingBottom: 0,
            fontSize: 18,
          },
          action: {
            alignItems: 'center',
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: baseTheme.spacing(-0.5),
            marginRight: baseTheme.spacing(-0.5),
            marginBottom: baseTheme.spacing(-0.5),
          },
          message: {
            paddingTop: 0,
            paddingBottom: 0,
            ...baseTheme.typography.bodyMedium,
          },
        },
        defaultProps: {
          icon: false,
          role: 'alert',
        },
      },
      MuiAlertTitle: {
        styleOverrides: {
          root: {
            marginTop: 0,
            marginBottom: baseTheme.spacing(0.5),
            ...baseTheme.typography.bodyMedium,
            fontWeight: 500,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorDefault: {
            backgroundColor: baseTheme.palette.common.white,
          },
        },
        defaultProps: {
          elevation: 0,
          color: 'default',
          position: 'sticky',
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: ({ size }: AvatarProps) => ({
            ...(size === 'extraExtraSmall' && {
              width: 20,
              height: 20,
              fontSize: 11,
              lineHeight: 20,
            }),
            ...(size === 'extraSmall' && {
              width: 24,
              height: 24,
              fontSize: 11,
              lineHeight: 20,
            }),
            ...(size === 'small' && {
              width: 32,
              height: 32,
              fontSize: 13,
              lineHeight: 24,
            }),
            ...((size === 'medium' ||
              !size ||
              !['extraExtraSmall', 'extraSmall', 'small', 'medium', 'large'].includes(size)) && {
              width: 40,
              height: 40,
              fontSize: 15,
              lineHeight: 24,
            }),
            ...(size === 'large' && {
              width: 48,
              height: 48,
              fontSize: 17,
              lineHeight: 28,
            }),
            fontWeight: 500,
          }),
          fallback: {
            width: '65%',
            height: '65%',
            color: baseTheme.palette.gray[300],
          },
          colorDefault: {
            boxShadow: `inset 0 0 0 1px ${baseTheme.palette.gray[200]}`,
            backgroundColor: baseTheme.palette.gray[75],
            color: baseTheme.palette.gray[400],
          },
        },
        defaultProps: {
          variant: 'rounded',
        },
      },
      MuiAvatarGroup: {
        styleOverrides: {
          root: ({ size }: AvatarGroupProps) => ({
            '& .MuiAvatarGroup-avatar': {
              ...(size === 'extraExtraSmall' && {
                width: 20,
                height: 20,
                fontSize: 11,
                lineHeight: 20,
              }),
              ...(size === 'extraSmall' && {
                width: 24,
                height: 24,
                fontSize: 11,
                lineHeight: 20,
              }),
              ...(size === 'small' && {
                width: 32,
                height: 32,
                fontSize: 13,
                lineHeight: 24,
              }),
              ...((size === 'medium' ||
                !size ||
                !['extraExtraSmall', 'extraSmall', 'small', 'medium', 'large'].includes(size)) && {
                width: 40,
                height: 40,
                fontSize: 15,
                lineHeight: 24,
              }),
              ...(size === 'large' && {
                width: 48,
                height: 48,
                fontSize: 17,
                lineHeight: 28,
              }),
              fontWeight: 500,
            },
          }),
          avatar: {
            borderWidth: 3,
            borderColor: `var(--border-color, ${baseTheme.palette.gray[25]})`,
          },
        },
        defaultProps: {
          size: 'medium',
        },
      },
      MuiBadge: {
        variants: [
          {
            props: {
              variant: 'minimal',
              color: 'default',
            },
            style: {
              '.MuiBadge-badge': {
                color: baseTheme.palette.gray[700],
                backgroundColor: baseTheme.palette.gray[100],
              },
            },
          },
          {
            props: {
              variant: 'minimal',
              color: 'warning',
            },
            style: {
              '.MuiBadge-badge': {
                color: baseTheme.palette.orange[700],
                backgroundColor: baseTheme.palette.orange[300],
              },
            },
          },
          {
            props: {
              variant: 'minimal',
              color: 'primary',
            },
            style: {
              '.MuiBadge-badge': {
                color: baseTheme.palette.primary[600],
                backgroundColor: baseTheme.palette.primary[300],
              },
            },
          },
          {
            props: {
              variant: 'minimal',
              color: 'error',
            },
            style: {
              '.MuiBadge-badge': {
                color: baseTheme.palette.red[600],
                backgroundColor: baseTheme.palette.red[300],
              },
            },
          },
          {
            props: {
              variant: 'minimal',
              color: 'success',
            },
            style: {
              '.MuiBadge-badge': {
                color: baseTheme.palette.springGreen[600],
                backgroundColor: baseTheme.palette.springGreen[300],
              },
            },
          },
        ],
        defaultProps: {
          color: 'primary',
          overlap: 'none',
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(1, 1.5),
            minWidth: 'initial',
            textTransform: 'none',
            letterSpacing: 'initial',
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
            '&.Mui-focusVisible, &:focus-visible': {
              boxShadow: `0 0 0 3px ${baseTheme.palette.primary[300]}`,
            },
          },
          contained: {
            '&.Mui-disabled': {
              color: baseTheme.palette.gray[400],
            },
            '&.Mui-disabled, &.Mui-disabled.MuiLoadingButton-loading': {
              backgroundColor: baseTheme.palette.gray[100],
            },
          },
          containedPrimary: {
            backgroundColor: baseTheme.palette.primary[500],
            color: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.primary[600],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.primary[700],
            },
          },
          containedSecondary: {
            backgroundColor: baseTheme.palette.gray[75],
            color: baseTheme.palette.primary[900],
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.gray[100],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.gray[200],
            },
          },
          containedError: {
            backgroundColor: baseTheme.palette.red[500],
            color: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.red[600],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.red[700],
            },
          },
          containedWarning: {
            backgroundColor: baseTheme.palette.orange[500],
            color: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.orange[600],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.orange[700],
            },
          },
          containedSuccess: {
            backgroundColor: baseTheme.palette.springGreen[500],
            color: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.springGreen[600],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.springGreen[700],
            },
          },
          sizeSmall: {
            padding: baseTheme.spacing(0.5, 1),
            ...baseTheme.typography.buttonSmall,
            fontWeight: 500,
          },
          sizeLarge: {
            padding: baseTheme.spacing(1.5, 2),
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
          },
          sizeExtraLarge: {
            padding: baseTheme.spacing(2, 3),
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
          },
          outlined: {
            padding: '7px 11px',
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
            '&.Mui-disabled': {
              color: baseTheme.palette.gray[500],
            },
            '&.Mui-disabled, &.Mui-disabled.MuiLoadingButton-loading': {
              backgroundColor: baseTheme.palette.common.white,
              borderColor: baseTheme.palette.gray[200],
              color: baseTheme.palette.gray[400],
              '> .MuiButton-icon': {
                color: baseTheme.palette.gray[300],
              },
            },
          },
          outlinedPrimary: {
            color: baseTheme.palette.primary[500],
            borderColor: baseTheme.palette.gray[200],
            backgroundColor: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&:active, &[aria-expanded="true"]': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          outlinedSecondary: {
            color: baseTheme.palette.gray[900],
            borderColor: baseTheme.palette.gray[200],
            backgroundColor: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&:active, &[aria-expanded="true"]': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          outlinedError: {
            color: baseTheme.palette.red[500],
            borderColor: baseTheme.palette.gray[200],
            backgroundColor: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&:active, &[aria-expanded="true"]': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          outlinedWarning: {
            color: baseTheme.palette.orange[500],
            borderColor: baseTheme.palette.gray[200],
            backgroundColor: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&:active, &[aria-expanded="true"]': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          outlinedSuccess: {
            color: baseTheme.palette.springGreen[500],
            borderColor: baseTheme.palette.gray[200],
            backgroundColor: baseTheme.palette.common.white,
            '&:hover:not(.Mui-disabled):not(:active)': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&:active, &[aria-expanded="true"]': {
              borderColor: baseTheme.palette.gray[200],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          outlinedSizeSmall: {
            padding: '3px 7px',
            ...baseTheme.typography.buttonSmall,
            fontWeight: 500,
          },
          outlinedSizeLarge: {
            padding: '11px 15px',
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
          },
          text: {
            padding: baseTheme.spacing(1, 1.5),
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
            '&.Mui-disabled': {
              color: baseTheme.palette.gray[500],
            },
            '&.Mui-disabled, &.Mui-disabled.MuiLoadingButton-loading': {
              backgroundColor: 'transparent',
            },
          },
          textPrimary: {
            color: baseTheme.palette.primary[500],
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.primary[100],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.primary[200],
            },
          },
          textSecondary: {
            color: baseTheme.palette.gray[900],
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.gray[75],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.gray[100],
            },
          },
          textError: {
            color: baseTheme.palette.red[500],
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.red[100],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.red[200],
            },
          },
          textWarning: {
            color: baseTheme.palette.orange[500],
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.orange[100],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.orange[200],
            },
          },
          textSuccess: {
            color: baseTheme.palette.springGreen[500],
            '&:hover:not(.Mui-disabled):not(:active)': {
              backgroundColor: baseTheme.palette.springGreen[100],
            },
            '&:active, &[aria-expanded="true"]': {
              backgroundColor: baseTheme.palette.springGreen[200],
            },
          },
          textSizeSmall: {
            padding: baseTheme.spacing(0.5, 1),
            ...baseTheme.typography.buttonSmall,
            fontWeight: 500,
          },
          textSizeLarge: {
            padding: baseTheme.spacing(1.5, 2),
            ...baseTheme.typography.buttonMedium,
            fontWeight: 500,
          },
          startIcon: {
            display: 'inherit',
            marginRight: baseTheme.spacing(1),
            marginLeft: 0,
            '&$iconSizeSmall': {
              marginLeft: 0,
            },
          },
          endIcon: {
            display: 'inherit',
            marginRight: 0,
            marginLeft: baseTheme.spacing(1),
            '&$iconSizeSmall': {
              marginRight: 0,
            },
          },
          iconSizeSmall: {
            '& > *:first-of-type': {
              fontSize: 16,
            },
          },
          iconSizeMedium: {
            '& > *:first-of-type': {
              fontSize: 16,
            },
          },
          iconSizeLarge: {
            '& > *:first-of-type': {
              fontSize: 16,
            },
          },
        },
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableElevation: true,
          variant: 'contained',
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
          focusRipple: false,
        },
      },
      MuiButtonGroup: {
        styleOverrides: {
          root: {
            '.MuiIconButton-root:not(:first-child):not(:last-child),*:not(:first-child):not(:last-child) .MuiIconButton-root':
              {
                borderRadius: 0,
                borderLeft: '0 !important',
              },
            '.MuiIconButton-root:first-child:not(:last-child),*:first-child:not(:last-child) .MuiIconButton-root':
              {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
            '.MuiIconButton-root:last-child:not(:first-child), *:last-child:not(:first-child) .MuiIconButton-root':
              {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderLeft: 0,
              },
          },
        },
        defaultProps: {
          disableElevation: true,
          disableFocusRipple: true,
          disableRipple: true,
          variant: 'contained',
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            marginLeft: baseTheme.spacing(0.5),
            marginRight: baseTheme.spacing(0.5),
          },
        },
      },
      MuiCardActionArea: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: baseTheme.palette.gray[25],
              opacity: 1,
            },
            '&:active': {
              backgroundColor: baseTheme.palette.gray[50],
              opacity: 1,
            },
          },
          focusHighlight: {
            backgroundColor: baseTheme.palette.gray[25],
          },
        },
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiCardActions: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(0, 3, 3),
            justifyContent: 'flex-end',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(2, 2.5),
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(2, 2.5),
            borderBottom: `1px solid ${baseTheme.palette.gray[100]}`,
            '&:last-child': {
              borderBottom: 0,
            },
          },
        },
        defaultProps: {
          titleTypographyProps: {
            variant: 'h6',
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: 0,
            color: baseTheme.palette.gray[200],
            transition: 'color .1s ease',
            backgroundColor: 'transparent !important',
            '&:hover': {
              color: baseTheme.palette.gray[300],
              '&:active': {
                color: baseTheme.palette.gray[300],
                'svg rect': {
                  fill: baseTheme.palette.gray[75],
                },
              },
            },
            '&:active': {
              color: baseTheme.palette.gray[300],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
        },
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            height: 'auto',
            backgroundColor: baseTheme.palette.gray[50],
            border: `1px solid ${baseTheme.palette.gray[200]}`,
            overflow: 'hidden',
            transition: baseTheme.transitions.create('border-color', {
              duration: baseTheme.transitions.duration.shortest,
            }),
          },
          clickable: {
            '&:hover, &:focus-visible': {
              backgroundColor: baseTheme.palette.gray[50],
              borderColor: baseTheme.palette.gray[300],
            },
            '&:active': {
              boxShadow: 'none',
            },
          },
          deletable: {
            '.MuiChip-label': {
              paddingRight: baseTheme.spacing(1.5),
            },
            '.MuiChip-label.MuiChip-labelSmall': {
              paddingRight: baseTheme.spacing(1),
            },
            '&:focus-visible': {
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          label: {
            paddingTop: baseTheme.spacing(1),
            paddingBottom: baseTheme.spacing(1),
            paddingRight: baseTheme.spacing(1.5),
            paddingLeft: baseTheme.spacing(1.5),
            color: baseTheme.palette.gray[800],
            ...baseTheme.typography.buttonSmall,
            fontWeight: 400,
          },
          labelSmall: {
            paddingTop: baseTheme.spacing(0.5),
            paddingBottom: baseTheme.spacing(0.5),
            paddingRight: baseTheme.spacing(1),
            paddingLeft: baseTheme.spacing(1),
            fontSize: '12px',
            lineHeight: baseTheme.spacing(2),
          },
          deleteIcon: {
            width: baseTheme.spacing(4),
            height: baseTheme.spacing(4),
            padding: baseTheme.spacing(1.25),
            marginRight: 0,
            marginLeft: baseTheme.spacing(-1.75),
            color: baseTheme.palette.gray[400],
            transition: baseTheme.transitions.create('color', {
              duration: baseTheme.transitions.duration.shortest,
            }),
            '&:hover': {
              color: baseTheme.palette.gray[700],
            },
          },
          deleteIconSmall: {
            width: baseTheme.spacing(3),
            height: baseTheme.spacing(3),
            padding: baseTheme.spacing(0.75),
            marginRight: 0,
            marginLeft: baseTheme.spacing(-1.25),
          },
        },
        variants: [
          {
            props: { shape: 'rounded' },
            style: {
              borderRadius: 4,
            },
          },
        ],
        defaultProps: {
          shape: 'default',
          deleteIcon: (
            <SvgIcon>
              <path d="M14.23 12l5.05-5.05c.3-.3.3-.77 0-1.06l-1.17-1.17a.75.75 0 00-1.06 0L12 9.77 6.95 4.72a.75.75 0 00-1.06 0L4.72 5.89c-.3.3-.3.77 0 1.06L9.77 12l-5.05 5.05c-.3.3-.3.77 0 1.06l1.17 1.17c.3.3.77.3 1.06 0L12 14.23l5.05 5.05c.3.3.77.3 1.06 0l1.17-1.17c.3-.3.3-.77 0-1.06L14.23 12z" />
            </SvgIcon>
          ),
        },
      },
      MuiContainer: {
        defaultProps: {
          maxWidth: false,
        },
      },
      MuiCollapse: {
        defaultProps: {
          in: false,
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            gap: baseTheme.spacing(1.5),
            paddingTop: baseTheme.spacing(2),
            paddingRight: baseTheme.spacing(3),
            paddingBottom: baseTheme.spacing(2),
            paddingLeft: baseTheme.spacing(3),
            borderTop: `1px solid ${baseTheme.palette.gray[100]}`,
            '& > :not(:first-of-type)': {
              marginLeft: 0,
            },
          },
        },
      },
      MuiDialogContent: {
        defaultProps: {
          variant: 'bodyMedium',
          color: 'textSecondary',
        },
        styleOverrides: {
          root: {
            paddingRight: baseTheme.spacing(3),
            paddingBottom: baseTheme.spacing(4),
            paddingLeft: baseTheme.spacing(3),
            overflow: 'visible',
          },
        },
      },
      MuiDialogTitle: {
        defaultProps: {
          variant: 'h4',
          component: 'div',
        },
        styleOverrides: {
          root: {
            paddingTop: baseTheme.spacing(4),
            paddingRight: baseTheme.spacing(4),
            paddingBottom: baseTheme.spacing(1),
            paddingLeft: baseTheme.spacing(4),
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: baseTheme.palette.gray[100],
            '&::before, &::after': {
              borderColor: baseTheme.palette.gray[100],
            },
          },
          light: {
            borderColor: baseTheme.palette.gray[75],
            '&::before, &::after': {
              borderColor: baseTheme.palette.gray[75],
            },
          },
        },
        defaultProps: {
          role: 'separator',
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            gap: baseTheme.spacing(0.5),
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            alignItems: 'flex-start',
            color: baseTheme.palette.gray[800],
            marginLeft: baseTheme.spacing(-1),
            marginRight: baseTheme.spacing(3),
            '& .MuiSwitch-root': {
              marginLeft: 0,
            },
            '& .MuiCheckbox-root, & .MuiRadio-root, & .MuiSwitch-root': {
              marginTop: baseTheme.spacing(-0.5),
              marginRight: baseTheme.spacing(1),
              marginBottom: baseTheme.spacing(-0.5),
            },
          },
          labelPlacementBottom: {
            marginLeft: 0,
            marginRight: baseTheme.spacing(3),
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginTop: baseTheme.spacing(0.5),
            marginLeft: 0,
            marginRight: 0,
            ...baseTheme.typography.bodyMedium,
            color: baseTheme.palette.gray[700],
          },
        },
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            borderRadius: 2,
          },
        },
        defaultProps: {
          disableFocusRipple: true,
          disableRipple: true,
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            color: baseTheme.palette.gray[600],
            zIndex: 1,

            '.Mui-disabled &, .Mui-disabled & .MuiTypography-root': {
              color: baseTheme.palette.gray[300],
            },
          },
          positionEnd: {
            marginRight: baseTheme.spacing(1),
            marginLeft: baseTheme.spacing(1),
          },
          positionStart: {
            marginLeft: baseTheme.spacing(1),
            marginRight: 0,
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            position: 'relative',
            transform: 'none',
            ...baseTheme.typography.bodyMedium,
            fontWeight: 500,
            color: baseTheme.palette.gray[900],
            pointerEvents: 'all',
            '&.Mui-focused, &.Mui-error, &.Mui-disabled': {
              color: baseTheme.palette.gray[900],
            },
          },
        },
        defaultProps: {
          disableAnimation: true,
          shrink: true,
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          bar1Determinate: {
            transition: '.4s ease',
          },
          colorPrimary: {
            backgroundColor: baseTheme.palette.gray[100],
          },
          barColorPrimary: {
            backgroundColor: baseTheme.palette.primary[500],
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            fontFamily: '"Graphik", "Roboto", "Helvetica", "Arial", sans-serif',
          },
        },
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            '&.Mui-selected,&.Mui-selected:hover': {
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          divider: {
            borderBottom: `1px solid ${baseTheme.palette.gray[75]}`,
          },
          gutters: {
            paddingLeft: baseTheme.spacing(3),
            paddingRight: baseTheme.spacing(2.5),
          },
          padding: {
            paddingTop: baseTheme.spacing(1.25),
            paddingBottom: baseTheme.spacing(1.25),
          },
        },
      },
      MuiListItemAvatar: {
        styleOverrides: {
          root: {
            marginTop: 0,
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&.Mui-selected,&.Mui-selected:hover': {
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
          divider: {
            borderBottom: `1px solid ${baseTheme.palette.gray[75]}`,
          },
          gutters: {
            paddingLeft: baseTheme.spacing(3),
            paddingRight: baseTheme.spacing(2.5),
          },
          padding: {
            paddingTop: baseTheme.spacing(1.25),
            paddingBottom: baseTheme.spacing(1.25),
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: baseTheme.spacing(2),
            marginRight: baseTheme.spacing(1.5),
            fontSize: '16px',
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            marginTop: 0,
            marginBottom: 0,
            '& .MuiTypography-body1': {
              ...baseTheme.typography.buttonMedium,
            },
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            paddingTop: baseTheme.spacing(3),
            paddingBottom: baseTheme.spacing(0.75),
            lineHeight: baseTheme.spacing(2.75),
            fontSize: 14,
            fontWeight: 400,
            color: '#6D808F',
          },
          gutters: {
            paddingLeft: baseTheme.spacing(2.5),
            paddingRight: baseTheme.spacing(2.5),
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          loadingIndicator: {
            color: baseTheme.palette.gray[400],
          },
        },
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
          disableElevation: true,
          variant: 'contained',
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: { border: 0 },
          list: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            margin: baseTheme.spacing(1),
          },
        },
      },
      MuiMenuList: {
        styleOverrides: {
          root: {
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            margin: baseTheme.spacing(1),
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          divider: {
            borderBottom: `1px solid ${baseTheme.palette.gray[75]}`,
          },
          root: {
            padding: baseTheme.spacing(1, 1.5),
            borderRadius: 4,
            minWidth: baseTheme.spacing(10),
            minHeight: 'auto',
            color: baseTheme.palette.gray[800],
            '&:hover': {
              backgroundColor: baseTheme.palette.gray[50],
            },
            '&.Mui-selected': {
              backgroundColor: baseTheme.palette.gray[50],
              '&:hover': {
                backgroundColor: baseTheme.palette.gray[50],
              },
            },
            ...baseTheme.typography.bodyMedium,
            lineHeight: baseTheme.spacing(3),
            [`& .${listItemIconClasses.root}`]: {
              minWidth: baseTheme.spacing(2),
            },
            [`& .${listItemIconClasses.root} svg`]: {
              fontSize: 'inherit',
            },
          },
        },
        defaultProps: {
          component: 'div',
          role: 'menuitem',
        },
      },
      MuiMobileStepper: {
        styleOverrides: {
          positionStatic: {
            backgroundColor: 'transparent',
          },
          dot: {
            margin: baseTheme.spacing(0, 0.75),
            backgroundColor: baseTheme.palette.gray[300],
            transition:
              'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          },
          dotActive: {
            backgroundColor: baseTheme.palette.primary[500],
            boxShadow: `0 0 0 4px ${baseTheme.palette.primary[300]}`,
          },
        },
        defaultProps: {
          position: 'static',
        },
      },
      MuiPaper: {
        styleOverrides: {
          rounded: {
            borderRadius: '4px',
          },
          elevation1: {
            border: `1px solid ${baseTheme.palette.gray[100]}`,
            boxShadow: 'none',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            marginTop: baseTheme.spacing(1.5),
            border: 0,
            boxShadow: `0 0 0 1px ${baseTheme.palette.gray[100]}, 0px 10px 20px rgba(40, 61, 74, 0.15), 0px 3px 6px rgba(40, 61, 74, 0.1)`,
          },
        },
        defaultProps: {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          marginThreshold: 48,
          transitionDuration: 10,
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            padding: 0,
            color: baseTheme.palette.gray[200],
            transition: 'color .1s ease',
            backgroundColor: 'transparent !important',
            '&:hover': {
              color: baseTheme.palette.gray[300],
              '&:active': {
                color: baseTheme.palette.gray[300],
                'svg rect': {
                  fill: baseTheme.palette.gray[75],
                },
              },
            },
            '&:active': {
              color: baseTheme.palette.gray[300],
              backgroundColor: baseTheme.palette.gray[75],
            },
          },
        },
        defaultProps: {
          checkedIcon: <div className="toggle checked" />,
          disableRipple: true,
          icon: <div className="toggle" />,
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.common.white,
          },
        },
        defaultProps: {
          variant: 'outlined',
        },
      },
      MuiSkeleton: {
        defaultProps: {
          animation: 'pulse',
          variant: 'text',
        },
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.gray[100],
          },
        },
      },
      MuiSnackbarContent: {
        styleOverrides: {
          root: {
            padding: baseTheme.spacing(2, 2.5),
            ...baseTheme.typography.bodyMedium,
            fontWeight: 500,
            backgroundColor: baseTheme.palette.gray[900],
          },
          message: {
            padding: 0,
          },
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          label: {
            ...baseTheme.typography.bodyMedium,
            fontWeight: 500,
            color: baseTheme.palette.gray[500],
            '&.Mui-completed': {
              color: baseTheme.palette.gray[500],
            },
          },
          root: {
            '&.Mui-active': {
              color: baseTheme.palette.gray[800],
            },
          },
        },
      },
      MuiSwitch: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: 'auto',
            minWidth: 'auto !important',
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            color: baseTheme.palette.gray[600],
            ...baseTheme.typography.bodyMedium,
            fontWeight: 500,
            textTransform: 'none',
            transition: baseTheme.transitions.create(['color'], {
              duration: baseTheme.transitions.duration.shortest,
            }),
            '&:hover': {
              color: baseTheme.palette.gray[900],
            },
            [baseTheme.breakpoints.down('xs')]: {
              paddingLeft: baseTheme.spacing(2.5),
              paddingRight: baseTheme.spacing(2.5),
            },
            '&.Mui-selected': {
              color: baseTheme.palette.gray[900],
            },
          },
          textColorInherit: {
            color: baseTheme.palette.gray[700],
            opacity: 1,
          },
        },
        defaultProps: {
          disableRipple: true,
          disableFocusRipple: true,
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            marginTop: baseTheme.spacing(1),
            position: 'relative',
            minHeight: 'auto',
            zIndex: 0,
            '&:after': {
              position: 'absolute',
              bottom: 0,
              left: 0,
              content: '""',
              width: '100%',
              height: 1,
              backgroundColor: baseTheme.palette.gray[100],
              zIndex: -1,
            },
          },
          flexContainer: {
            gap: baseTheme.spacing(2.5),
          },
          indicator: {
            height: 1,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: '8px 20px 7px',
            ...baseTheme.typography.bodyMedium,
            color: baseTheme.palette.gray[800],
            borderBottom: `1px solid ${baseTheme.palette.gray[100]}`,
            whiteSpace: 'nowrap',
            '&:first-of-type': {
              paddingLeft: baseTheme.spacing(2.5),
            },
            '&:last-of-type': {
              paddingRight: baseTheme.spacing(2.5),
            },
            '&:empty::before': {
              position: 'relative',
              content: '"–"',
              color: baseTheme.palette.gray[500],
              ...baseTheme.typography.bodyMedium,
            },
          },
          head: {
            ...baseTheme.typography.bodyMedium,
            fontWeight: 500,
            paddingTop: baseTheme.spacing(1),
            paddingBottom: baseTheme.spacing(1),
            color: baseTheme.palette.gray[900],
          },
          sizeSmall: {
            padding: baseTheme.spacing(1, 3),
          },
          paddingNone: {
            padding: 0,
          },
          paddingCheckbox: {
            width: baseTheme.spacing(4.5),
            padding: baseTheme.spacing(1),
            '&:first-of-type': {
              paddingLeft: baseTheme.spacing(2),
              paddingRight: 0,
            },
            '&:last-of-type': {
              paddingLeft: 0,
              paddingRight: baseTheme.spacing(2.5),
            },
            '&.MuiTableCell-head': {
              paddingTop: baseTheme.spacing(0.5),
              paddingBottom: baseTheme.spacing(0.5),
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.table.head,
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            // position: 'sticky',
            // bottom: 0,
            display: 'flex',
            alignItems: 'center',
            // height: 56,
            padding: baseTheme.spacing(1.5, 2.5),
            // backgroundColor: '#fff',
            // borderTop: `1px solid ${gray[40]}`,
            marginTop: -1,
            // zIndex: 2,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            position: 'relative',
            '&.selectable::before': {
              position: 'sticky',
              left: 0,
              display: 'table-cell',
              paddingLeft: baseTheme.spacing(0.5),
              height: '100%',
              width: 1,
              content: '""',
              backgroundColor: baseTheme.palette.primary[500],
              borderBottom: `1px solid ${baseTheme.palette.gray[100]}`,
              transform: 'scaleX(0)',
              transformOrigin: 'left center',
              transition: '.2s ease',
              zIndex: 1,
            },
            '&.MuiTableRow-hover': {
              '&:hover': {
                backgroundColor: baseTheme.palette.gray[25],
                cursor: 'pointer',
              },
            },
            '&.Mui-selected': {
              backgroundColor: baseTheme.palette.common.white,
              '&:hover': {
                backgroundColor: baseTheme.palette.gray[25],
              },
              '&::before': {
                transform: 'scaleX(1)',
              },
            },
            '&:last-of-type > td': {
              borderBottom: 0,
            },
          },
        },
      },
      MuiTimelineConnector: {
        styleOverrides: {
          root: {
            width: 1,
            backgroundColor: baseTheme.palette.gray[200],
          },
        },
      },
      MuiTimelineDot: {
        styleOverrides: {
          root: {
            margin: baseTheme.spacing(1.5, 0),
            boxShadow: 'none',
          },
        },
      },
      MuiTimelineItem: {
        styleOverrides: {
          positionRight: {
            '&::before': {
              display: 'none',
            },
          },
          positionLeft: {
            '&::before': {
              display: 'none',
            },
          },
        },
      },
      MuiToggleButton: {
        defaultProps: {
          disableFocusRipple: true,
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            padding: '7px 11px',
            color: baseTheme.palette.gray[900],
            ...baseTheme.typography.buttonMedium,
            borderColor: baseTheme.palette.gray[100],
            textTransform: 'none',
            transition: baseTheme.transitions.create(['color', 'background-color', 'border-color']),
            '&:hover': {
              color: baseTheme.palette.gray[900],
              backgroundColor: baseTheme.palette.gray[75],
              borderLeftColor: `${baseTheme.palette.gray[100]} !important`,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              color: baseTheme.palette.primary.main,
              backgroundColor: baseTheme.palette.gray[75],
              borderLeftColor: `${baseTheme.palette.gray[100]} !important`,
            },
          },
        },
      },
      MuiTooltip: {
        defaultProps: {
          arrow: true,
          disableInteractive: true,
          placement: 'top',
        },
        styleOverrides: {
          popper: {
            filter:
              'drop-shadow(0px 1px 3px rgba(40, 61, 74, 0.12)) drop-shadow(0px 1px 2px rgba(40, 61, 74, 0.24))',
            '&[data-popper-placement*="top"] .MuiTooltip-tooltip': {
              marginBottom: '4px !important',
            },
            '&[data-popper-placement*="right"] .MuiTooltip-tooltip': {
              marginLeft: '4px !important',
            },
            '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip': {
              marginTop: '4px !important',
            },
            '&[data-popper-placement*="left"] .MuiTooltip-tooltip': {
              marginRight: '4px !important',
            },
          },
          tooltip: {
            padding: baseTheme.spacing(0.5, 1),
            backgroundColor: baseTheme.palette.gray[900],
            borderRadius: 4,
            color: baseTheme.palette.gray[100],
            ...baseTheme.typography.buttonMedium,
            fontWight: 500,
          },
          arrow: {
            color: baseTheme.palette.gray[900],
            '&::before': {
              borderBottomRightRadius: 2,
            },
          },
        },
      },
      MuiTypography: {
        defaultProps: {
          variant: 'bodyMedium',
          variantMapping: {
            h1: 'h1',
            h2: 'h2',
            h3: 'h3',
            h4: 'h4',
            h5: 'h5',
            h6: 'h6',
            subtitle1: 'h6',
            subtitle2: 'h6',
            body1: 'p',
            body2: 'p',
            inherit: 'p',
            bodySmall: 'p',
            bodyMedium: 'p',
            bodyLarge: 'p',
            bodyExtraLarge: 'p',
            buttonSmall: 'span',
            buttonMedium: 'span',
            buttonLarge: 'span',
            buttonExtraLarge: 'span',
          },
        },
      },
    },
  }),
);

export default theme;
