import { useCallback, useRef } from 'react';

export const useGetLatest = <T = unknown>(value: T) => {
  const ref = useRef<T>();
  ref.current = value;

  return useCallback(() => ref.current as T, []);
};

export default useGetLatest;
