import styled from '@emotion/styled';
import { Paper, IconButton } from '@afosto/components';

export const ToastBase = styled(Paper)`
  min-width: 400px;
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2.5)};
  ${props => props.theme.typography.bodyMedium}
  font-weight: 500;
  background-color: ${props => props.theme.palette.gray[900]};
  border: 0;
  box-shadow: 0 10px 20px rgba(40, 61, 74, 0.15), 0 3px 6px rgba(40, 61, 74, 0.1);
`;

export const CloseButton = styled(IconButton)`
  && {
    color: ${props => props.theme.palette.gray[300]} !important;

    &:hover {
      color: ${props => props.theme.palette.gray[75]} !important;
    }

    &:active {
      color: ${props => props.theme.palette.gray[75]} !important;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: -${props => props.theme.spacing(0.5)};
  margin-bottom: -${props => props.theme.spacing(0.5)};
  margin-left: ${props => props.theme.spacing(3)};

  .MuiButton-root.MuiButton-root {
    color: ${props => props.theme.palette.common.white};
    background-color: transparent;

    &:hover {
      background-color: ${props => props.theme.palette.gray[800]};
    }

    &:active {
      background-color: ${props => props.theme.palette.gray[700]};
    }
  }
`;
