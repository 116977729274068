import styled from '@emotion/styled';
import { IconButton as MuiIconButton } from '@mui/material';

export const IconButton = styled(MuiIconButton)`
  && {
    transition: color 0.2s ease, background-color 0.2s ease, box-shadow 0.2s ease;

    &.size-sizeExtraSmall {
      padding: 4px;
      font-size: 12px;
      border-radius: 4px;
    }

    &.size-sizeSmall {
      padding: 4px;
      font-size: 16px;
      border-radius: 4px;
    }

    &.size-sizeMedium {
      padding: 6px;
      font-size: 20px;
      border-radius: 4px;
    }

    &.size-sizeLarge {
      padding: 8px;
      font-size: 24px;
      border-radius: 6px;
    }

    &.Mui-focusVisible {
      box-shadow: 0 0 0 3px ${props => props.theme.palette.primary[200]};
    }

    &.color-primary {
      background-color: ${props => props.theme.palette.primary.main};
      color: ${props => props.theme.palette.primary.contrastText};

      &:hover {
        background-color: ${props => props.theme.palette.primary[600]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.primary[700]};
      }
    }

    &.color-success {
      background-color: ${props => props.theme.palette.success.main};
      color: ${props => props.theme.palette.success.contrastText};

      &:hover {
        background-color: ${props => props.theme.palette.success[600]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.success[700]};
      }
    }

    &.color-error {
      background-color: ${props => props.theme.palette.error.main};
      color: ${props => props.theme.palette.error.contrastText};

      &:hover {
        background-color: ${props => props.theme.palette.error[600]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.error[700]};
      }
    }

    &.color-warning {
      background-color: ${props => props.theme.palette.warning.main};
      color: ${props => props.theme.palette.warning.contrastText};

      &:hover {
        background-color: ${props => props.theme.palette.warning[600]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.warning[700]};
      }
    }

    &.color-secondary {
      background-color: ${props => props.theme.palette.gray[75]};
      color: ${props => props.theme.palette.gray[900]};

      &:hover {
        background-color: ${props => props.theme.palette.gray[100]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.gray[200]};
      }
    }

    &.button-type-outlined.color-default {
      border: 1px solid ${props => props.theme.palette.gray[200]};
      background-color: ${props => props.theme.palette.common.white};
      color: ${props => props.theme.palette.gray[900]};

      &:hover {
        background-color: ${props => props.theme.palette.gray[50]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.gray[75]};
      }

      &.size-sizeExtraSmall {
        padding: 3px;
      }
      &.size-sizeSmall {
        padding: 3px;
      }
      &.size-sizeMedium {
        padding: 5px;
      }
      &.size-sizeLarge {
        padding: 7px;
      }

      &.Mui-disabled {
        color: ${props => props.theme.palette.gray[200]};
      }
    }

    &.button-type-text.color-textPrimary {
      color: ${props => props.theme.palette.gray[900]};

      &:hover {
        background-color: ${props => props.theme.palette.gray[75]};
      }

      &:active,
      &[aria-expanded='true'] {
        background-color: ${props => props.theme.palette.gray[100]};
      }
    }

    &.button-type-text.color-minimalDark1 {
      color: ${props => props.theme.palette.gray[900]};

      &:hover {
        color: ${props => props.theme.palette.gray[500]};
        background-color: transparent;
      }

      &:active,
      &[aria-expanded='true'] {
        color: ${props => props.theme.palette.gray[500]};
        background-color: transparent;
      }

      &:focus:not(:hover),
      &.Mui-focusVisible:not(:hover) {
        color: ${props => props.theme.palette.gray[900]};
      }
    }

    &.button-type-text.color-minimalDark2 {
      color: ${props => props.theme.palette.gray[500]};

      &:hover {
        color: ${props => props.theme.palette.gray[300]};
        background-color: transparent;
      }

      &:active,
      &[aria-expanded='true'] {
        color: ${props => props.theme.palette.gray[300]};
        background-color: transparent;
      }

      &:focus:not(:hover),
      &.Mui-focusVisible:not(:hover) {
        color: ${props => props.theme.palette.gray[500]};
      }
    }

    &.button-type-text.color-minimalLight1 {
      color: ${props => props.theme.palette.gray[300]};

      &:hover {
        color: ${props => props.theme.palette.gray[500]};
        background-color: transparent;
      }

      &:active,
      &[aria-expanded='true'] {
        color: ${props => props.theme.palette.gray[500]};
        background-color: transparent;
      }

      &:focus:not(:hover),
      &.Mui-focusVisible:not(:hover) {
        color: ${props => props.theme.palette.gray[300]};
      }
    }

    &.button-type-text.color-minimalLight2 {
      color: ${props => props.theme.palette.gray[500]};

      &:hover {
        color: ${props => props.theme.palette.gray[900]};
        background-color: transparent;
      }

      &:active,
      &[aria-expanded='true'] {
        color: ${props => props.theme.palette.gray[900]};
        background-color: transparent;
      }

      &:focus:not(:hover),
      &.Mui-focusVisible:not(:hover) {
        color: ${props => props.theme.palette.gray[500]};
      }
    }
  }

  &&.Mui-disabled {
    background-color: ${props => props.theme.palette.gray[200]};
    color: ${props => props.theme.palette.common.white};
  }
`;
