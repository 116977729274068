import { createContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import useSetupPusherClient from './hooks/useSetupPusherClient';

const environment = import.meta.env.PROD ? 'production' : 'development';

if (environment !== 'production' && import.meta.env.VITE_PUSHER_ENABLE_LOGGING === 'true') {
  Pusher.logToConsole = true;
}

export const PusherContext = createContext({
  client: null,
  subscribeChannel: undefined,
  subscribeChannelAsync: undefined,
  unsubscribeChannel: undefined,
  waitForEvent: undefined,
  waitForEventAfterFunction: undefined,
});

const PusherProvider = ({ appID: providedAppID, cluster, children }) => {
  const appID = providedAppID || import.meta.env.VITE_PUSHER_APP_ID;
  const client = useSetupPusherClient({ appID, cluster });

  const subscribeChannel = useCallback(name => client.subscribe(name), [client]);

  const unsubscribeChannel = useCallback(
    name => {
      client.unsubscribe(name);
    },
    [client],
  );

  const waitForEvent = useCallback(async (channel, eventName, callback) => {
    if (!channel || !eventName) {
      return Promise.reject(new Error('Invalid channel or event'));
    }

    return new Promise(resolve => {
      const resolver = (result, handler) => () => {
        channel.unbind(eventName, handler);
        resolve(result);
      };

      const handler = async result => {
        if (callback) {
          callback(result, resolver(result, handler));
        } else {
          resolver(result, handler)();
        }
      };

      channel.bind(eventName, handler);
    });
  }, []);

  const waitForEventAfterFunction = useCallback(
    async (channel, eventName, onBind, callback, options = {}) => {
      if (!channel || !eventName) {
        return Promise.reject(new Error('Invalid channel or event'));
      }

      const { timeout = 0 } = options || {};
      let timer;

      return new Promise((resolve, reject) => {
        const resolver = (result, handler) => () => {
          if (timer) {
            clearTimeout(timer);
          }

          channel.unbind(eventName, handler);
          resolve(result);
        };

        const handler = async result => {
          if (callback) {
            callback(result, resolver(result, handler));
          } else {
            resolver(result, handler)();
          }
        };

        channel.bind(eventName, handler);
        onBind()
          .then(() => {
            if (timeout) {
              timer = setTimeout(() => {
                channel.unbind(eventName, handler);
                resolve();
              }, timeout);
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    [],
  );

  const subscribeChannelAsync = useCallback(
    async name => {
      const channel = client.subscribe(name);
      await waitForEvent(channel, 'pusher:subscription_succeeded', (result, resolve) => {
        resolve();
      });

      return channel;
    },
    [client, waitForEvent],
  );

  return (
    <PusherContext.Provider
      value={{
        client,
        subscribeChannel,
        subscribeChannelAsync,
        unsubscribeChannel,
        waitForEvent,
        waitForEventAfterFunction,
      }}
    >
      {children}
    </PusherContext.Provider>
  );
};

PusherProvider.propTypes = {
  appID: PropTypes.string,
  children: PropTypes.node.isRequired,
  cluster: PropTypes.string,
};

PusherProvider.defaultProps = {
  appID: undefined,
  cluster: 'eu',
};

export default PusherProvider;
