import styled from '@emotion/styled';
import { type CustomColor, SvgIcon } from '@afosto/components';

export const Icon = styled(SvgIcon)`
  && {
    &.icon-white {
      color: ${props => props.theme.palette.common.white};
    }

    &.icon-primary {
      color: ${props => props.theme.palette.primary.main};
      --icon-primary: ${props => props.theme.palette.primary[300]};
      --icon-secondary: ${props => props.theme.palette.primary[600]};
    }
    &.icon-success {
      color: ${props => props.theme.palette.success.main};
      --icon-primary: ${props => props.theme.palette.success[300]};
      --icon-secondary: ${props => props.theme.palette.success[600]};
    }
    &.icon-secondary {
      color: ${props => props.theme.palette.secondary.main};
      --icon-primary: ${props => props.theme.palette.secondary[300]};
      --icon-secondary: ${props => props.theme.palette.secondary[600]};
    }
    &.icon-error {
      color: ${props => props.theme.palette.error.main};
      --icon-primary: ${props => props.theme.palette.error[300]};
      --icon-secondary: ${props => props.theme.palette.error[600]};
    }
    &.icon-warning {
      color: ${props => props.theme.palette.warning.main};
      --icon-primary: ${props => props.theme.palette.warning[300]};
      --icon-secondary: ${props => props.theme.palette.warning[600]};
    }
    &.icon-info {
      color: ${props => props.theme.palette.info.main};
      --icon-primary: ${props => props.theme.palette.info[300]};
      --icon-secondary: ${props => props.theme.palette.info[600]};
    }

    ${props =>
      Object.keys(props.theme.palette.gray).map(key => {
        const colorKey = key as keyof CustomColor;

        return `
              &.icon-gray-${key} {
                color: ${props.theme.palette.gray[colorKey]};
                --icon-primary: ${props.theme.palette.gray[colorKey]};
              }
            `;
      })}
    ${props =>
      Object.keys(props.theme.palette.gray).map(key => {
        const colorKey = key as keyof CustomColor;

        return `
              &.icon-second-gray-${key} {
                --icon-secondary: ${props.theme.palette.gray[colorKey]};
              }
            `;
      })}

    &.icon-inherit {
      color: inherit;
    }

    &.rotate {
      transition: ${props =>
        props.theme.transitions.create(['transform'], {
          easing: props.theme.transitions.easing.easeOut,
          duration: props.theme.transitions.duration.shorter,
        })};

      &-0 {
        transform: none;
      }

      &-90 {
        transform: rotate(90deg);
      }
      &-n90 {
        transform: rotate(-90deg);
      }

      &-180 {
        transform: rotate(180deg);
      }
      &-n180 {
        transform: rotate(-180deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(1turn);
      }
    }

    &.spinner {
      animation: spin 1s linear infinite;
    }
  }
`;
