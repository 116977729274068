import { defineMessages } from 'react-intl';

export default defineMessages({
  serverError: {
    id: 'errors.serverError',
    defaultMessage: 'A server error has occurred',
    description: 'default server error translation',
  },
  printingSuccessful: {
    id: 'components.printProvider.feedback.printingSuccessful',
    defaultMessage: 'Documents were printed',
    description: 'success message when printing job is successful',
  },
});
