import { client, type Options } from "@afosto/client-fetch";
import type {
  GetListNavigationData,
  GetListNavigationError,
  GetListNavigationResponse,
  ListJobsData,
  ListJobsError,
  ListJobsResponse,
  CreateJobData,
  CreateJobError,
  CreateJobResponse,
  GetJobData,
  GetJobError,
  GetJobResponse,
  UpdateJobStatusData,
  UpdateJobStatusError,
  UpdateJobStatusResponse,
  ListNotificationsData,
  ListNotificationsError,
  ListNotificationsResponse,
  MarkNotificationsData,
  MarkNotificationsError,
  MarkNotificationsResponse,
  GetNotificationData,
  GetNotificationError,
  GetNotificationResponse,
  ListProjectListsData,
  ListProjectListsError,
  ListProjectListsResponse,
  CreateProjectListData,
  CreateProjectListError,
  CreateProjectListResponse,
  DeleteProjectListData,
  DeleteProjectListError,
  DeleteProjectListResponse,
  GetProjectListData,
  GetProjectListError,
  GetProjectListResponse,
  UpdateProjectListData,
  UpdateProjectListError,
  UpdateProjectListResponse,
  ListTasksData,
  ListTasksError,
  ListTasksResponse,
  CreateProjectTaskData,
  CreateProjectTaskError,
  CreateProjectTaskResponse,
  DeleteProjectTaskData,
  DeleteProjectTaskError,
  DeleteProjectTaskResponse,
  GetProjectTaskData,
  GetProjectTaskError,
  GetProjectTaskResponse,
  UpdateProjectTaskData,
  UpdateProjectTaskError,
  UpdateProjectTaskResponse,
  GetProjectTaskCommentsData,
  GetProjectTaskCommentsError,
  GetProjectTaskCommentsResponse,
  CreateProjectCommentData,
  CreateProjectCommentError,
  CreateProjectCommentResponse,
  DeleteProjectCommentData,
  DeleteProjectCommentError,
  DeleteProjectCommentResponse,
  GetProjectCommentData,
  GetProjectCommentError,
  GetProjectCommentResponse,
  UpdateProjectCommentData,
  UpdateProjectCommentError,
  UpdateProjectCommentResponse,
  GetProjectTaskAttachmentsData,
  GetProjectTaskAttachmentsError,
  GetProjectTaskAttachmentsResponse,
  CreateAttachmentData,
  CreateAttachmentError,
  CreateAttachmentResponse,
  DeleteProjectAttachmentData,
  DeleteProjectAttachmentError,
  DeleteProjectAttachmentResponse,
  GetAttachmentData,
  GetAttachmentError,
  GetAttachmentResponse,
  ReceivePusherWebhooksData,
  ReceivePusherWebhooksError,
  ReceivePusherWebhooksResponse,
  GetPusherChannelStateData,
  GetPusherChannelStateError,
  GetPusherChannelStateResponse,
} from './types';

export { client };

/**
 * Get navigation
 * Get navigation for project lists
 */
export const getListNavigation = <ThrowOnError extends boolean = false>(
  options?: Options<GetListNavigationData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetListNavigationResponse,
    GetListNavigationError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/navigation',
  });
};

/**
 * List jobs
 * Returns list of jobs
 */
export const listJobs = <ThrowOnError extends boolean = false>(
  options?: Options<ListJobsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListJobsResponse, ListJobsError, ThrowOnError>({
    ...options,
    url: '/api/jobs',
  });
};

/**
 * Create job
 * Create a new job
 */
export const createJob = <ThrowOnError extends boolean = false>(
  options: Options<CreateJobData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<CreateJobResponse, CreateJobError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/jobs',
  });
};

/**
 * Get job
 * Returns a single
 */
export const getJob = <ThrowOnError extends boolean = false>(
  options: Options<GetJobData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetJobResponse, GetJobError, ThrowOnError>({
    ...options,
    url: '/api/jobs/{id}',
  });
};

/**
 * Update job
 * Update job state
 */
export const updateJobStatus = <ThrowOnError extends boolean = false>(
  options: Options<UpdateJobStatusData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateJobStatusResponse,
    UpdateJobStatusError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/jobs/{id}',
  });
};

/**
 * List notifications
 * Returns list of notifications lists
 */
export const listNotifications = <ThrowOnError extends boolean = false>(
  options?: Options<ListNotificationsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListNotificationsResponse,
    ListNotificationsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/notifications',
  });
};

/**
 * Mark notifications
 * Mark the submitted notifications as read
 */
export const markNotifications = <ThrowOnError extends boolean = false>(
  options: Options<MarkNotificationsData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    MarkNotificationsResponse,
    MarkNotificationsError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/notifications',
  });
};

/**
 * Get notification
 * Returns a single notification
 */
export const getNotification = <ThrowOnError extends boolean = false>(
  options: Options<GetNotificationData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetNotificationResponse,
    GetNotificationError,
    ThrowOnError
  >({
    ...options,
    url: '/api/notifications/{id}',
  });
};

/**
 * List tasks
 * Returns list of task lists
 */
export const listProjectLists = <ThrowOnError extends boolean = false>(
  options?: Options<ListProjectListsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListProjectListsResponse,
    ListProjectListsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/lists',
  });
};

/**
 * Create list
 * Creates a new task list
 */
export const createProjectList = <ThrowOnError extends boolean = false>(
  options: Options<CreateProjectListData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    CreateProjectListResponse,
    CreateProjectListError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/lists',
  });
};

/**
 * Delete taskslist
 * Delete a task list
 */
export const deleteProjectList = <ThrowOnError extends boolean = false>(
  options: Options<DeleteProjectListData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<
    DeleteProjectListResponse,
    DeleteProjectListError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/lists/{id}',
  });
};

/**
 * Get list
 * Returns a single list
 */
export const getProjectList = <ThrowOnError extends boolean = false>(
  options: Options<GetProjectListData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetProjectListResponse, GetProjectListError, ThrowOnError>(
    {
      ...options,
      url: '/api/projects/lists/{id}',
    },
  );
};

/**
 * Update list
 * Update a task list
 */
export const updateProjectList = <ThrowOnError extends boolean = false>(
  options: Options<UpdateProjectListData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateProjectListResponse,
    UpdateProjectListError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/lists/{id}',
  });
};

/**
 * List tasks
 * Returns results from a view
 */
export const listTasks = <ThrowOnError extends boolean = false>(
  options?: Options<ListTasksData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListTasksResponse, ListTasksError, ThrowOnError>({
    ...options,
    url: '/api/projects/tasks',
  });
};

/**
 * Create task
 * Creates a new task
 */
export const createProjectTask = <ThrowOnError extends boolean = false>(
  options: Options<CreateProjectTaskData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    CreateProjectTaskResponse,
    CreateProjectTaskError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/tasks',
  });
};

/**
 * Delete task
 * Delete a task
 */
export const deleteProjectTask = <ThrowOnError extends boolean = false>(
  options: Options<DeleteProjectTaskData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<
    DeleteProjectTaskResponse,
    DeleteProjectTaskError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/tasks/{id}',
  });
};

/**
 * Get task
 * Returns a single task
 */
export const getProjectTask = <ThrowOnError extends boolean = false>(
  options: Options<GetProjectTaskData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetProjectTaskResponse, GetProjectTaskError, ThrowOnError>(
    {
      ...options,
      url: '/api/projects/tasks/{id}',
    },
  );
};

/**
 * Update task
 * Update a task
 */
export const updateProjectTask = <ThrowOnError extends boolean = false>(
  options: Options<UpdateProjectTaskData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateProjectTaskResponse,
    UpdateProjectTaskError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/tasks/{id}',
  });
};

/**
 * Get comments
 * Returns a list of comments
 */
export const getProjectTaskComments = <ThrowOnError extends boolean = false>(
  options?: Options<GetProjectTaskCommentsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetProjectTaskCommentsResponse,
    GetProjectTaskCommentsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/comments',
  });
};

/**
 * Create comment
 * Creates a new comment
 */
export const createProjectComment = <ThrowOnError extends boolean = false>(
  options: Options<CreateProjectCommentData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    CreateProjectCommentResponse,
    CreateProjectCommentError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/comments',
  });
};

/**
 * Delete comment
 * Delete a comment
 */
export const deleteProjectComment = <ThrowOnError extends boolean = false>(
  options: Options<DeleteProjectCommentData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<
    DeleteProjectCommentResponse,
    DeleteProjectCommentError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/comments/{id}',
  });
};

/**
 * Get task
 * Returns a single comment
 */
export const getProjectComment = <ThrowOnError extends boolean = false>(
  options: Options<GetProjectCommentData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetProjectCommentResponse,
    GetProjectCommentError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/comments/{id}',
  });
};

/**
 * Update comment
 * Update a comment
 */
export const updateProjectComment = <ThrowOnError extends boolean = false>(
  options: Options<UpdateProjectCommentData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateProjectCommentResponse,
    UpdateProjectCommentError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/comments/{id}',
  });
};

/**
 * Get attachments
 * Returns a list of attachments
 */
export const getProjectTaskAttachments = <ThrowOnError extends boolean = false>(
  options?: Options<GetProjectTaskAttachmentsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetProjectTaskAttachmentsResponse,
    GetProjectTaskAttachmentsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/attachments',
  });
};

/**
 * Create attachment
 * Creates a new attachment
 */
export const createAttachment = <ThrowOnError extends boolean = false>(
  options: Options<CreateAttachmentData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    CreateAttachmentResponse,
    CreateAttachmentError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/projects/attachments',
  });
};

/**
 * Delete attachment
 * Delete an attachment
 */
export const deleteProjectAttachment = <ThrowOnError extends boolean = false>(
  options: Options<DeleteProjectAttachmentData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<
    DeleteProjectAttachmentResponse,
    DeleteProjectAttachmentError,
    ThrowOnError
  >({
    ...options,
    url: '/api/projects/attachments/{id}',
  });
};

/**
 * Get attachment
 * Returns an attachment
 */
export const getAttachment = <ThrowOnError extends boolean = false>(
  options: Options<GetAttachmentData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetAttachmentResponse, GetAttachmentError, ThrowOnError>({
    ...options,
    url: '/api/projects/attachments/{id}',
  });
};

/**
 * Handle webhook
 * Handles webhooks for pusher
 */
export const receivePusherWebhooks = <ThrowOnError extends boolean = false>(
  options: Options<ReceivePusherWebhooksData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    ReceivePusherWebhooksResponse,
    ReceivePusherWebhooksError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/realtime/pusher/webhooks',
  });
};

/**
 * Get channel
 * Returns a channel state
 */
export const getPusherChannelState = <ThrowOnError extends boolean = false>(
  options: Options<GetPusherChannelStateData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetPusherChannelStateResponse,
    GetPusherChannelStateError,
    ThrowOnError
  >({
    ...options,
    url: '/api/realtime/pusher/channels/{name}',
  });
};
