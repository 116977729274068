import { forwardRef } from 'react';
import { Slide as MuiSlide, type SlideProps } from '@mui/material';

const Slide = forwardRef<unknown, SlideProps>(({ children, ...props }, ref) => (
  <MuiSlide ref={ref} {...props}>
    {children}
  </MuiSlide>
));

export default Slide;
