import {
  CssBaseline,
  createTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';
import defaultThemeConfig from '../theme';
import type { ThemeProviderProps } from './types';

export const defaultTheme = createMuiTheme(defaultThemeConfig);

export const createTheme = createMuiTheme;

const ThemeProvider = (props: ThemeProviderProps) => {
  const { children, theme } = props || {};

  return (
    <MuiThemeProvider theme={theme ?? defaultTheme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  );
};

export default ThemeProvider;
