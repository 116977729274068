import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  enqueuePrintJob as enqueuePrintJobRequest,
  getPrintAccount,
  listPrintServers,
  listPrinters,
} from '@afosto/print-service/api';
import { uuid } from '@afosto/utils';
import { getErrorMessage } from '@afosto/utils-shared';
import useToast from '../hooks/useToast';
import { useAuthentication } from '../AuthenticationProvider';
import PrintDialog from '../PrintDialog';
import translations from './translations';

const PrintContext = createContext({
  credentials: {},
  printers: [],
  servers: [],
  enqueuePrintJob: undefined,
  hasPrintNode: false,
});

export const usePrint = () => useContext(PrintContext);

const PrintProvider = ({ children, disablePrintProvider }) => {
  const intl = useIntl();
  const { isAuthenticated } = useAuthentication();
  const { enqueueToast } = useToast();
  const [account, setAccount] = useState({});
  const [printers, setPrinters] = useState([]);
  const [isFetchingPrinters, setIsFetchingPrinters] = useState(false);
  const [servers, setServers] = useState([]);
  const [isFetchingServers, setIsFetchingServers] = useState(false);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [printState, setPrintState] = useState({});
  const [isPrinting, setIsPrinting] = useState(false);
  const [failedJobs, setFailedJobs] = useState([]);
  const awaitingPromiseRef = useRef(null);

  const handleCloseDialog = () => setDialogIsOpen(false);

  const handleOnExitedDialog = () => {
    setFailedJobs([]);
    setIsPrinting(false);
    setPrintState({});
    awaitingPromiseRef?.current?.reject();
  };

  const fetchPrinters = useCallback(async () => {
    try {
      setIsFetchingPrinters(true);
      const { data: printersResponse } = await listPrinters();
      const { data: returnedPrinters } = printersResponse || {};

      setPrinters(returnedPrinters);
    } catch (error) {
      const message = getErrorMessage(error);

      enqueueToast({
        severity: 'error',
        message: intl.formatMessage(translations.serverError),
        description: message,
        dismissible: true,
      });
    } finally {
      setIsFetchingPrinters(false);
    }
  }, [enqueueToast, intl]);

  const fetchServers = useCallback(async () => {
    try {
      setIsFetchingServers(true);
      const { data: serversResponse } = await listPrintServers();
      const { data: returnedServers } = serversResponse || {};

      setServers(returnedServers);
    } catch (error) {
      const message = getErrorMessage(error);

      enqueueToast({
        severity: 'error',
        message: intl.formatMessage(translations.serverError),
        description: message,
        dismissible: true,
      });
    } finally {
      setIsFetchingServers(false);
    }
  }, [enqueueToast, intl]);

  const fetchPrintNodeCredentials = useCallback(async () => {
    try {
      const { data: accountData } = await getPrintAccount();
      setAccount(accountData?.data || {});
    } catch (error) {
      setAccount({});
    }
  }, []);

  const enqueuePrintJob = async printJob =>
    enqueuePrintJobRequest({
      body: {
        data: printJob,
      },
    });

  const print = useCallback(
    config => {
      setPrintState(config || {});
      setDialogIsOpen(true);
      fetchPrinters();

      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject };
      });
    },
    [fetchPrinters],
  );

  const handlePrintDocuments = async ({ printer, documents }) => {
    try {
      const formattedJobs = documents.map(url => ({
        id: uuid(),
        printerId: printer?.id,
        contentType: 'URL',
        content: url,
      }));

      const body = {
        await: 'PRINTED',
        jobs: formattedJobs,
      };

      setIsPrinting(true);
      const response = await enqueuePrintJob(body);
      const { data: responseData } = response || {};
      const { data: jobsResponse } = responseData || {};
      const failedJobs = jobsResponse.filter(({ state }) => state !== 'PRINTED');

      if (failedJobs.length > 0) {
        const formattedFailedJobs = failedJobs.map(job => ({
          ...job,
          ...(formattedJobs.find(({ id }) => id === job.id) || {}),
        }));

        setFailedJobs(prevState => {
          const filteredCurrentFailedJobs = prevState.filter(
            ({ content }) => !documents.includes(content),
          );

          return [...formattedFailedJobs, ...filteredCurrentFailedJobs];
        });
        return;
      }

      if (awaitingPromiseRef.current) {
        awaitingPromiseRef.current.resolve(response);
      }

      enqueueToast({
        severity: 'success',
        message: intl.formatMessage(translations.printingSuccessful),
      });

      setDialogIsOpen(false);
    } catch (error) {
      const message = getErrorMessage(error);

      enqueueToast({
        severity: 'error',
        message: intl.formatMessage(translations.serverError),
        description: message,
        dismissible: true,
      });
    } finally {
      setIsPrinting(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !disablePrintProvider) {
      fetchPrintNodeCredentials();
    }
  }, [fetchPrintNodeCredentials, isAuthenticated, disablePrintProvider]);

  return (
    <PrintContext.Provider
      value={{
        account,
        printers,
        servers,
        hasPrintNode: !!account?.isActive,
        enqueuePrintJob,
        print,
        refetchPrinters: fetchPrinters,
        isFetchingPrinters,
        refetchServers: fetchServers,
        isFetchingServers,
      }}
    >
      {children}
      <PrintDialog
        open={dialogIsOpen}
        onClose={handleCloseDialog}
        onPrint={handlePrintDocuments}
        onExited={handleOnExitedDialog}
        printers={printers}
        isFetchingPrinters={isFetchingPrinters}
        defaultType={printState?.defaultType}
        shouldSelectPosition={printState?.shouldSelectPosition}
        documents={printState?.documents}
        allowMerging={printState?.allowMerging}
        isPrinting={isPrinting}
        failedJobs={failedJobs}
      />
    </PrintContext.Provider>
  );
};

PrintProvider.propTypes = {
  children: PropTypes.node,
};

PrintProvider.defaultProps = {
  children: undefined,
};

export default PrintProvider;
