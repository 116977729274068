import styled from '@emotion/styled';
import { Paper } from '@afosto/components';

export const ToastBase = styled(Paper)`
  display: flex;
  align-items: flex-start;
  min-width: 400px;
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2.5)};
  ${props => props.theme.typography.bodyMedium}
  font-weight: 500;
  background-color: ${props => props.theme.palette.gray[900]};
  border: 0;
  box-shadow: 0 10px 20px rgba(40, 61, 74, 0.15), 0 3px 6px rgba(40, 61, 74, 0.1);
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing(2)};
  margin-top: ${props => props.theme.spacing(1.5)};

  .MuiButton-root.MuiButton-root {
    color: ${props => props.theme.palette.common.white};
    background-color: transparent;

    &:hover:not(.Mui-disabled):not(:active) {
      background-color: ${props => props.theme.palette.gray[800]};
    }

    &:active {
      background-color: ${props => props.theme.palette.gray[700]};
    }
  }
`;
