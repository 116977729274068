import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  DialogActions,
  DialogContent,
  DialogHeader,
  IconButton,
  Link,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@afosto/components';
import { Print } from '@afosto/icons/duotone';
import { AngleRight } from '@afosto/icons/light';
import { ExternalLink } from '@afosto/icons/solid';
import { Times } from '@afosto/icons/solid';
import { arrayFromNumber } from '@afosto/utils';
import EmptyMessage from '../../../EmptyMessage';
import translations from '../../translations';
import Icon from '../../../Icon';

const PrinterSelection = ({
  activeType,
  onChangeActiveType,
  printers,
  isFetchingPrinters,
  onClickPrinter,
  onClose,
  documents,
}) => {
  const intl = useIntl();

  const printersForActiveType = printers.filter(({ type }) => type === activeType);

  return (
    <>
      <DialogHeader
        title={
          <Box display="flex" alignItems="center">
            <FormattedMessage {...translations.printerTitle} />
            <IconButton sx={{ ml: 'auto' }} variant={'minimal'} size="small" onClick={onClose}>
              <Icon icon={Times} />
            </IconButton>
          </Box>
        }
        sx={{ position: 'sticky', zIndex: 2, top: 0, backgroundColor: 'common.white' }}
      />
      {!isFetchingPrinters && printers.length === 0 && (
        <CardContent sx={{ textAlign: 'center', maxWidth: 440, mx: 'auto' }}>
          <EmptyMessage
            title={intl.formatMessage(translations.emptyTitle)}
            description={intl.formatMessage(translations.emptyDescription)}
            icon={
              <Icon icon={Print} secondColor="gray-200" color="gray-400" sx={{ fontSize: 40 }} />
            }
            action={{
              label: (
                <>
                  <FormattedMessage {...translations.goToDocs} />{' '}
                  <Icon icon={ExternalLink} sx={{ ml: 1 }} />
                </>
              ),
              href: intl.formatMessage(translations.spoolerDesktopDocsLink),
            }}
          />
        </CardContent>
      )}
      {(isFetchingPrinters || printers.length > 0) && (
        <>
          <Tabs
            sx={{
              position: 'sticky',
              top: 65,
              zIndex: 3,
              px: 3,
              backgroundColor: 'grey.25',
              mt: 0,
              flexShrink: 0,
            }}
            onChange={onChangeActiveType}
            value={activeType}
          >
            <Tab
              label={intl.formatMessage(translations.paperType)}
              value="PAPER"
              sx={{ py: 1.5 }}
            />
            <Tab
              label={intl.formatMessage(translations.labelType)}
              value="LABEL"
              sx={{ py: 1.5 }}
            />
          </Tabs>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, pb: 3, overflow: 'auto' }}
          >
            {isFetchingPrinters &&
              arrayFromNumber(5).map(idx => (
                <Card key={idx} sx={{ flexShrink: 0 }}>
                  <CardActionArea>
                    <CardContent sx={{ display: 'flex', alignItems: 'center', py: 1.5 }}>
                      <Typography mr="auto">
                        <Skeleton width={300} />
                      </Typography>
                      <Icon icon={AngleRight} sx={{ fontSize: 20 }} color="gray-300" />
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            {!isFetchingPrinters && (
              <>
                {printersForActiveType.length === 0 && (
                  <Typography color="grey.700" sx={{ fontStyle: 'italic' }}>
                    <FormattedMessage
                      {...translations[activeType === 'LABEL' ? 'noLabelPrinters' : 'noA4Printers']}
                    />
                  </Typography>
                )}
                {printersForActiveType.length > 0 &&
                  printersForActiveType.map(printer => (
                    <Card key={printer?.id} sx={{ flexShrink: 0 }}>
                      <CardActionArea onClick={onClickPrinter(printer)}>
                        <CardContent sx={{ display: 'flex', alignItems: 'center', py: 1.5 }}>
                          <Typography mr="auto">{printer?.name}</Typography>
                          <Icon icon={AngleRight} sx={{ fontSize: 20 }} color="gray-300" />
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))}
              </>
            )}
          </DialogContent>
        </>
      )}
      <DialogActions
        sx={{ position: 'sticky', mt: 'auto', bottom: 0, backgroundColor: 'common.white' }}
      >
        <Link
          target="_blank"
          rel="noopener"
          href={
            documents?.length > 1
              ? `https://afosto.app/api/tools/pdf?files=${documents.join(',')}`
              : documents.at(0)
          }
          onClick={onClose}
          mr="auto"
          display="flex"
          alignItems="center"
          gap={0.5}
          fontWeight={500}
        >
          <FormattedMessage {...translations.printWithSystemDialog} />
          <Icon icon={ExternalLink} />
        </Link>
        <Button onClick={onClose} variant="outlined" color="secondary" size="large">
          <FormattedMessage {...translations.cancel} />
        </Button>
      </DialogActions>
    </>
  );
};

export default PrinterSelection;
