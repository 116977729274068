import { snakeCase, splitSeparateNumbers } from 'change-case';

interface ConvertKeysToSnakeCaseOptions {
  excludeKeys?: string[];
  splitNumbersForKeys?: string[];
  stopPaths?: (string | RegExp)[];
}

export const convertKeysToSnakeCase = <T>(
  obj: T,
  options: ConvertKeysToSnakeCaseOptions = {},
  currentPath: string[] = [],
): T => {
  if (Array.isArray(obj)) {
    return obj.map((item, index) =>
      convertKeysToSnakeCase(item, options, [...currentPath, String(index)]),
    ) as unknown as T;
  } else if (obj && typeof obj === 'object' && obj.constructor === Object) {
    return Object.keys(obj).reduce((result: Record<string, unknown>, key: string) => {
      const newPath = [...currentPath, key];

      const shouldStop = options.stopPaths?.some(path => {
        if (typeof path === 'string') {
          const pathParts = path.split('.');
          return (
            pathParts.every((part, partIndex) => part === newPath[partIndex]) &&
            pathParts.length === newPath.length
          );
        }

        return path.test(newPath.join('.'));
      });

      if (shouldStop) {
        result[key] = (obj as Record<string, unknown>)[key];
      } else if (options?.excludeKeys?.includes(key)) {
        result[key] = convertKeysToSnakeCase(
          (obj as Record<string, unknown>)[key],
          options,
          newPath,
        );
      } else {
        const newKey = snakeCase(key, {
          ...(options?.splitNumbersForKeys?.includes(key)
            ? {
                split: splitSeparateNumbers,
              }
            : {}),
        });
        result[newKey] = convertKeysToSnakeCase(
          (obj as Record<string, unknown>)[key],
          options,
          newPath,
        );
      }

      return result;
    }, {}) as T;
  }
  return obj;
};
