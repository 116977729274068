import PropTypes from 'prop-types';
import React from 'react';
import { Times } from '@afosto/icons/light';
import Icon from '../Icon';
import * as Styled from './ToastCloseButton.styles';

const ToastCloseButton = ({ onClose }) => (
  <Styled.CloseButton size="small" variant="minimalLight2" onClick={onClose} sx={{ ml: 'auto' }}>
    <Icon icon={Times} />
  </Styled.CloseButton>
);

ToastCloseButton.propTypes = {
  onClose: PropTypes.func,
};

ToastCloseButton.defaultProps = {
  onClose: undefined,
};

export default ToastCloseButton;
