import { defineMessages } from 'react-intl';

export default defineMessages({
  accept: {
    id: 'actions.accept',
    defaultMessage: 'Accept',
    description: 'default accept label',
  },
  cancel: {
    id: 'actions.cancel',
    defaultMessage: 'Cancel',
    description: 'default cancel label',
  },
});
