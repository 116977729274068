import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { requestPusherToken } from '@afosto/iam-service/api';

const useSetupPusherClient = ({ appID, cluster }) => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (!appID) {
      // eslint-disable-next-line no-console
      console.error('An app ID is required for pusher');
    }

    if (!cluster) {
      // eslint-disable-next-line no-console
      console.error('A cluster is required for pusher');
    }

    if (appID && cluster) {
      const pusher = new Pusher(appID, {
        cluster,
        authorizer: channel => ({
          authorize: (socketId, callback) => {
            requestPusherToken({
              body: {
                data: {
                  socket_id: socketId,
                  channel_name: channel.name,
                },
              },
              credentials: 'include',
            })
              .then(response => {
                const { data: responseData } = response || {};
                const { data: authData } = responseData || {};
                const { auth, channelData } = authData || {};

                callback(null, authData ? { auth, channel_data: channelData } : { auth: '' });
              })
              .catch(error => {
                callback(error, { auth: '' });
              });
          },
        }),
      });

      setClient(pusher);
    }
  }, [appID, cluster]);

  return client;
};

export default useSetupPusherClient;
