import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@afosto/components';
import translations from './translations';
import type { ConfirmDialogButtonProps, ConfirmDialogProps } from './types';

const ConfirmDialog = (props: ConfirmDialogProps) => {
  const intl = useIntl();
  const {
    disablePadding = false,
    open = false,
    title,
    description,
    buttons = [
      {
        label: intl.formatMessage(translations.cancel),
        type: 'cancel',
        response: 'cancelled',
        variant: 'outlined',
        color: 'secondary',
      },
      {
        label: intl.formatMessage(translations.accept),
        type: 'submit',
        response: 'accepted',
      },
    ],
    onSubmit,
    onClose,
    TransitionProps,
    ...rest
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick =
    ({
      type,
      response,
      onClick,
      onError,
    }: Pick<ConfirmDialogButtonProps, 'type' | 'response' | 'onClick' | 'onError'>) =>
    async () => {
      try {
        if (type === 'link') {
          return;
        }

        if (type === 'cancel') {
          onClose('cancel');
          return;
        }

        setIsSubmitting(true);

        if (onClick && typeof onClick === 'function') {
          await onClick(response);
        }

        onSubmit(response);
      } catch (error) {
        if (onError && typeof onError === 'function') {
          await onError(error as Error);
        }
      } finally {
        setIsSubmitting(false);
      }
    };

  const handleClose = () => {
    onClose('cancel');
  };

  const handleExited = (node: HTMLElement) => {
    setIsSubmitting(false);

    if (TransitionProps?.onExited) {
      TransitionProps.onExited(node);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...rest}
      open={open}
      onClose={handleClose}
      TransitionProps={{
        ...(TransitionProps || {}),
        onExited: handleExited,
      }}
    >
      <DialogHeader
        title={title}
        onClose={handleClose}
        closeButtonProps={{
          disabled: isSubmitting,
        }}
      />
      {disablePadding && description}
      {!disablePadding && description && <DialogContent>{description}</DialogContent>}
      <DialogActions>
        {buttons.map(({ label, type, response, onClick, onError, ...buttonProps }) => (
          <Button
            key={label}
            onClick={handleClick({ type, response, onClick, onError })}
            size="large"
            disabled={type !== 'submit' && isSubmitting}
            loading={type === 'submit' && isSubmitting}
            {...buttonProps}
          >
            {label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
