export const AUTHENTICATION_DEFAULT_SCOPES = [
  'openid',
  'email',
  'profile',
  'iam:tenants:read',
  'iam:users:read',
  'iam:subscriptions:read',
  'iam:2fa:read',
];

export const AUTHENTICATION_ERROR_CODES = {
  emailNotVerified: 'ATZ-IAM-1305',
  emailAlreadyVerified: 'ATZ-IAM-1308',
  hasValidTokens: 'ATZ-IAM-1005',
  noAccessToSubtenant: 'ATZ-IAM-1306',
  sessionInvalid: 'ATZ-IAM-1303',
};

export const AUTHENTICATION_REFERRER_SESSION_KEY = 'referrerPath';

export const AUTHENTICATION_ROUTES_MAPPING = {
  authorize: '/auth/authorize',
  callback: '/auth/callback',
  error: '/auth/error',
  twoFactor: '/auth/2fa',
};
