import { createContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  listNotificationsOptions,
  markNotificationsMutation,
} from '@afosto/project-service/@tanstack/queries';
import { useAuthentication } from '../AuthenticationProvider';
import { useEvent, usePresenceChannel } from '../PusherProvider';

export const NotificationsContext = createContext({
  hasUnreadNotifications: false,
});

const NotificationsProvider = ({ children, disableNotifications }) => {
  const { isAuthenticated, user } = useAuthentication();
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const markAsRead = useMutation(markNotificationsMutation());

  const { data: response, refresh: refreshUnreadNotifications } = useQuery({
    ...listNotificationsOptions({
      query: {
        filterIsUnreadEq: '1',
        pageSize: 1,
      },
    }),
    enabled: isAuthenticated && !disableNotifications,
  });
  const unreadNotificationsCount = response?.data?.length;

  useEffect(() => {
    setHasUnreadNotifications(unreadNotificationsCount > 0);
  }, [unreadNotificationsCount]);

  const handleMarkAsRead = useCallback(
    async (ids, requestOptions) => {
      await markAsRead.mutateAsync({
        body: {
          data: {
            ids,
            isUnread: false,
          },
        },
        ...requestOptions,
      });

      if (ids.includes('*')) {
        setHasUnreadNotifications(false);
      } else {
        refreshUnreadNotifications();
      }
    },
    [markAsRead, refreshUnreadNotifications],
  );

  const handleNotificationsEvent = () => {
    if (!hasUnreadNotifications) {
      setHasUnreadNotifications(true);
    }
  };

  const notificationsChannel = usePresenceChannel(`notifications_${user?.id}`, {
    enabled: !!user?.id,
  });

  useEvent(notificationsChannel, 'notification.created', handleNotificationsEvent);

  useEvent(notificationsChannel, 'notification.updated', handleNotificationsEvent);

  return (
    <NotificationsContext.Provider
      value={{ hasUnreadNotifications, notificationsChannel, markAsRead: handleMarkAsRead }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  disableNotifications: PropTypes.bool,
};

NotificationsProvider.defaultProps = {
  disableNotifications: false,
};

export default NotificationsProvider;
