import { client, type Options, urlSearchParamsBodySerializer } from "@afosto/client-fetch";
import type {
  GetHeadersData,
  GetHeadersError,
  GetHeadersResponse,
  FlushRedisData,
  FlushRedisError,
  FlushRedisResponse,
  GetJwksData,
  GetJwksError,
  GetJwksResponse,
  GetOpenIdConfigData,
  GetOpenIdConfigError,
  GetOpenIdConfigResponse,
  ListTenantsData,
  ListTenantsError,
  ListTenantsResponse,
  CreateTenantData,
  CreateTenantError,
  CreateTenantResponse,
  UpdateTenantData,
  UpdateTenantError,
  UpdateTenantResponse,
  GetTenantSettingsData,
  GetTenantSettingsError,
  GetTenantSettingsResponse,
  UpdateTenantSettingsData,
  UpdateTenantSettingsError,
  UpdateTenantSettingsResponse,
  GetSignupInfoData,
  GetSignupInfoError,
  GetSignupInfoResponse,
  GetTenantData,
  GetTenantError,
  GetTenantResponse,
  RequestPurgeTokenData,
  RequestPurgeTokenError,
  RequestPurgeTokenResponse,
  GetPurgeTokenData,
  GetPurgeTokenError,
  GetPurgeTokenResponse,
  StartPurgeData,
  StartPurgeError,
  StartPurgeResponse,
  GetPurgeTokenByIdData,
  GetPurgeTokenByIdError,
  GetPurgeTokenByIdResponse,
  ListSubtenantsData,
  ListSubtenantsError,
  ListSubtenantsResponse,
  ListPendingInvitesData,
  ListPendingInvitesError,
  ListPendingInvitesResponse,
  GetDefaultSubtenantData,
  GetDefaultSubtenantError,
  GetDefaultSubtenantResponse,
  UpdateInviteData,
  UpdateInviteError,
  UpdateInviteResponse,
  ListPendingSubtenantsData,
  ListPendingSubtenantsError,
  ListPendingSubtenantsResponse,
  SendSubtenantRequestData,
  SendSubtenantRequestError,
  SendSubtenantRequestResponse,
  ListAuthorizationsData,
  ListAuthorizationsError,
  ListAuthorizationsResponse,
  ListPendingAuthorizationRequestsData,
  ListPendingAuthorizationRequestsError,
  ListPendingAuthorizationRequestsResponse,
  UpdateRequestData,
  UpdateRequestError,
  UpdateRequestResponse,
  ListPendingAuthorizationInvitesData,
  ListPendingAuthorizationInvitesError,
  ListPendingAuthorizationInvitesResponse,
  SendInviteData,
  SendInviteError,
  SendInviteResponse,
  ListUsersData,
  ListUsersError,
  ListUsersResponse,
  CreateUserData,
  CreateUserError,
  CreateUserResponse,
  DeleteUserData,
  DeleteUserError,
  DeleteUserResponse,
  GetUserData,
  GetUserError,
  GetUserResponse,
  UpdateUserData,
  UpdateUserError,
  UpdateUserResponse,
  UndoUserData,
  UndoUserError,
  UndoUserResponse,
  UpdateUserPasswordData,
  UpdateUserPasswordError,
  UpdateUserPasswordResponse,
  ListTokensData,
  ListTokensError,
  ListTokensResponse,
  UpdateUserRolesData,
  UpdateUserRolesError,
  UpdateUserRolesResponse,
  JoinTenantData,
  JoinTenantError,
  JoinTenantResponse,
  ResetPasswordData,
  ResetPasswordError,
  ResetPasswordResponse,
  VerifyUserData,
  VerifyUserError,
  VerifyUserResponse,
  GetUserTokenData,
  GetUserTokenError,
  GetUserTokenResponse,
  RequestTokenData,
  RequestTokenError,
  RequestTokenResponse,
  InvalidateTokensData,
  InvalidateTokensError,
  InvalidateTokensResponse,
  ListRolesData,
  ListRolesError,
  ListRolesResponse,
  CreateRoleData,
  CreateRoleError,
  CreateRoleResponse,
  DeleteRoleData,
  DeleteRoleError,
  DeleteRoleResponse,
  GetRoleData,
  GetRoleError,
  GetRoleResponse,
  UpdateRoleData,
  UpdateRoleError,
  UpdateRoleResponse,
  ListSystemRolesData,
  ListSystemRolesError,
  ListSystemRolesResponse,
  UpdateSystemRolesData,
  UpdateSystemRolesError,
  UpdateSystemRolesResponse,
  GetSystemRoleData,
  GetSystemRoleError,
  GetSystemRoleResponse,
  ListClientsData,
  ListClientsError,
  ListClientsResponse,
  CreateClientData,
  CreateClientError,
  CreateClientResponse,
  DeleteClientData,
  DeleteClientError,
  DeleteClientResponse,
  GetClientData,
  GetClientError,
  GetClientResponse,
  UpdateClientData,
  UpdateClientError,
  UpdateClientResponse,
  ListOpenClientsData,
  ListOpenClientsError,
  ListOpenClientsResponse,
  UserLogoutData,
  UserLogoutError,
  UserLogoutResponse,
  GetSessionData,
  GetSessionError,
  GetSessionResponse,
  LoginUserData,
  LoginUserError,
  LoginUserResponse,
  GetSessionSubtenantsData,
  GetSessionSubtenantsError,
  GetSessionSubtenantsResponse,
  RequestVerifyData,
  RequestVerifyError,
  RequestVerifyResponse,
  RequestPusherTokenData,
  RequestPusherTokenError,
  RequestPusherTokenResponse,
  Verify2FaData,
  Verify2FaError,
  Verify2FaResponse,
  SessionSignupData,
  SessionSignupError,
  Backup2FaData,
  Backup2FaError,
  Backup2FaResponse,
  GetRedirectData,
  GetRedirectError,
  GetRedirectResponse,
  GetAccessTokenData,
  GetAccessTokenError,
  GetAccessTokenResponse,
  GetRedirect302Data,
  GetRedirect302Error,
  GetRedirect302Response,
  ListNumbersData,
  ListNumbersError,
  ListNumbersResponse,
  CreateNumberData,
  CreateNumberError,
  CreateNumberResponse,
  FindSlaNumberData,
  FindSlaNumberError,
  FindSlaNumberResponse,
  StartOnboardingSessionData,
  StartOnboardingSessionError,
  GetOnboardingSessionData,
  GetOnboardingSessionError,
  GetOnboardingSessionResponse,
  ConfirmOnboardingSessionData,
  ConfirmOnboardingSessionError,
  ConfirmOnboardingSessionResponse,
  ActivateOnboardingSessionData,
  ActivateOnboardingSessionError,
  ActivateOnboardingSessionResponse,
  ReturnAfterOnboardingData,
  ReturnAfterOnboardingError,
  GetHostedConfigData,
  GetHostedConfigError,
  GetHostedConfigResponse,
  ListAuthTokensData,
  ListAuthTokensError,
  ListAuthTokensResponse,
  CreateAuthTokenData,
  CreateAuthTokenError,
  CreateAuthTokenResponse,
  RetractAuthTokenData,
  RetractAuthTokenError,
  RetractAuthTokenResponse,
  GetAuthTokenData,
  GetAuthTokenError,
  GetAuthTokenResponse,
  ValidateTokenData,
  ValidateTokenError,
  ValidateTokenResponse,
} from './types';

export { client };

/**
 * Get headers
 * Returns a list headers
 */
export const getHeaders = <ThrowOnError extends boolean = false>(
  options?: Options<GetHeadersData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetHeadersResponse, GetHeadersError, ThrowOnError>({
    ...options,
    url: '/api/echo-headers',
  });
};

/**
 * Flush Redis
 * Flush Redis DB
 */
export const flushRedis = <ThrowOnError extends boolean = false>(
  options?: Options<FlushRedisData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<FlushRedisResponse, FlushRedisError, ThrowOnError>({
    ...options,
    url: '/api/__flush',
  });
};

/**
 * Get JWKS
 * Returns a list of public key's to validate jwt
 */
export const getJwks = <ThrowOnError extends boolean = false>(
  options?: Options<GetJwksData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetJwksResponse, GetJwksError, ThrowOnError>({
    ...options,
    url: '/api/.well-known/jwks.json',
  });
};

/**
 * OpenID configuration
 * Returns openid config
 */
export const getOpenIdConfig = <ThrowOnError extends boolean = false>(
  options?: Options<GetOpenIdConfigData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetOpenIdConfigResponse,
    GetOpenIdConfigError,
    ThrowOnError
  >({
    ...options,
    url: '/api/.well-known/openid-configuration',
  });
};

/**
 * Get tenants
 * Returns a list of tenants
 */
export const listTenants = <ThrowOnError extends boolean = false>(
  options?: Options<ListTenantsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListTenantsResponse, ListTenantsError, ThrowOnError>({
    ...options,
    url: '/api/iam/tenants',
  });
};

/**
 * Create tenant
 * Create a new tenant
 */
export const createTenant = <ThrowOnError extends boolean = false>(
  options: Options<CreateTenantData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<CreateTenantResponse, CreateTenantError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/tenants',
  });
};

/**
 * Update tenant
 * Update tenant name
 */
export const updateTenant = <ThrowOnError extends boolean = false>(
  options: Options<UpdateTenantData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UpdateTenantResponse, UpdateTenantError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/tenants',
  });
};

/**
 * Get settings
 * Get settings for tenant
 */
export const getTenantSettings = <ThrowOnError extends boolean = false>(
  options?: Options<GetTenantSettingsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetTenantSettingsResponse,
    GetTenantSettingsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/platform/settings',
  });
};

/**
 * Update settings
 * Update tenant settings
 */
export const updateTenantSettings = <ThrowOnError extends boolean = false>(
  options: Options<UpdateTenantSettingsData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateTenantSettingsResponse,
    UpdateTenantSettingsError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/platform/settings',
  });
};

/**
 * Get signup
 * Return signup information
 */
export const getSignupInfo = <ThrowOnError extends boolean = false>(
  options: Options<GetSignupInfoData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetSignupInfoResponse, GetSignupInfoError, ThrowOnError>({
    ...options,
    url: '/api/iam/signup/{email}',
  });
};

/**
 * Get tenant
 * Return the current tenant information
 */
export const getTenant = <ThrowOnError extends boolean = false>(
  options: Options<GetTenantData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetTenantResponse, GetTenantError, ThrowOnError>({
    ...options,
    url: '/api/iam/tenants/{id}',
  });
};

/**
 * Request token
 * Requests a purge token
 */
export const requestPurgeToken = <ThrowOnError extends boolean = false>(
  options?: Options<RequestPurgeTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    RequestPurgeTokenResponse,
    RequestPurgeTokenError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/purges',
  });
};

/**
 * View token
 * View a purge token
 */
export const getPurgeToken = <ThrowOnError extends boolean = false>(
  options: Options<GetPurgeTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetPurgeTokenResponse, GetPurgeTokenError, ThrowOnError>({
    ...options,
    url: '/api/iam/purges/{token}',
  });
};

/**
 * Apply token
 * Start purge for token
 */
export const startPurge = <ThrowOnError extends boolean = false>(
  options: Options<StartPurgeData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<StartPurgeResponse, StartPurgeError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/purges/{token}',
  });
};

/**
 * View token
 * View a purge token by ID
 */
export const getPurgeTokenById = <ThrowOnError extends boolean = false>(
  options: Options<GetPurgeTokenByIdData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetPurgeTokenByIdResponse,
    GetPurgeTokenByIdError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/purges/{id}/id',
  });
};

/**
 * Get subtenants
 * Returns a list of accessible subtenants
 */
export const listSubtenants = <ThrowOnError extends boolean = false>(
  options?: Options<ListSubtenantsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListSubtenantsResponse, ListSubtenantsError, ThrowOnError>(
    {
      ...options,
      url: '/api/iam/subtenants',
    },
  );
};

/**
 * Get invites
 * Returns a list of pending invites to subtenants
 */
export const listPendingInvites = <ThrowOnError extends boolean = false>(
  options?: Options<ListPendingInvitesData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListPendingInvitesResponse,
    ListPendingInvitesError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subtenants/invites',
  });
};

/**
 * Get default
 * Returns the default tenant
 */
export const getDefaultSubtenant = <ThrowOnError extends boolean = false>(
  options?: Options<GetDefaultSubtenantData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetDefaultSubtenantResponse,
    GetDefaultSubtenantError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subtenants/default',
  });
};

/**
 * Update invite
 * Updates the invite state
 */
export const updateInvite = <ThrowOnError extends boolean = false>(
  options: Options<UpdateInviteData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UpdateInviteResponse, UpdateInviteError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/subtenants/invites/{id}',
  });
};

/**
 * Get requests
 * Returns a list of pending subtenant requests
 */
export const listPendingSubtenants = <ThrowOnError extends boolean = false>(
  options?: Options<ListPendingSubtenantsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListPendingSubtenantsResponse,
    ListPendingSubtenantsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subtenants/requests',
  });
};

/**
 * Create request
 * Create a new subtenant request, send a request to access another tenant
 */
export const sendSubtenantRequest = <ThrowOnError extends boolean = false>(
  options: Options<SendSubtenantRequestData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    SendSubtenantRequestResponse,
    SendSubtenantRequestError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/subtenants/requests',
  });
};

/**
 * Get accounts
 * Returns a list of approved authorizations to the tenant's account
 */
export const listAuthorizations = <ThrowOnError extends boolean = false>(
  options?: Options<ListAuthorizationsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListAuthorizationsResponse,
    ListAuthorizationsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subtenants/authorizations',
  });
};

/**
 * Get pending
 * Returns a list of pending requests for users to the tenant's account
 */
export const listPendingAuthorizationRequests = <ThrowOnError extends boolean = false>(
  options?: Options<ListPendingAuthorizationRequestsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListPendingAuthorizationRequestsResponse,
    ListPendingAuthorizationRequestsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subtenants/authorizations/requests',
  });
};

/**
 * Update request
 * Updates the request state
 */
export const updateRequest = <ThrowOnError extends boolean = false>(
  options: Options<UpdateRequestData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UpdateRequestResponse, UpdateRequestError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/subtenants/authorizations/requests/{id}',
  });
};

/**
 * Get invites
 * Returns a list of pending invites for users to the tenant's account
 */
export const listPendingAuthorizationInvites = <ThrowOnError extends boolean = false>(
  options?: Options<ListPendingAuthorizationInvitesData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListPendingAuthorizationInvitesResponse,
    ListPendingAuthorizationInvitesError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subtenants/authorizations/invites',
  });
};

/**
 * Create invite
 * Invite another user / tenant to access this account
 */
export const sendInvite = <ThrowOnError extends boolean = false>(
  options: Options<SendInviteData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<SendInviteResponse, SendInviteError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/subtenants/authorizations/invites',
  });
};

/**
 * Get users
 * Returns a list of users
 */
export const listUsers = <ThrowOnError extends boolean = false>(
  options?: Options<ListUsersData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListUsersResponse, ListUsersError, ThrowOnError>({
    ...options,
    url: '/api/iam/users',
  });
};

/**
 * Create user
 * Create a new user
 */
export const createUser = <ThrowOnError extends boolean = false>(
  options: Options<CreateUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<CreateUserResponse, CreateUserError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users',
  });
};

/**
 * Delete user
 * Deletes a user
 */
export const deleteUser = <ThrowOnError extends boolean = false>(
  options: Options<DeleteUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<DeleteUserResponse, DeleteUserError, ThrowOnError>({
    ...options,
    url: '/api/iam/users/{id}',
  });
};

/**
 * Get user
 * Returns a single user
 */
export const getUser = <ThrowOnError extends boolean = false>(
  options: Options<GetUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetUserResponse, GetUserError, ThrowOnError>({
    ...options,
    url: '/api/iam/users/{id}',
  });
};

/**
 * Update user
 * Updates a user
 */
export const updateUser = <ThrowOnError extends boolean = false>(
  options: Options<UpdateUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UpdateUserResponse, UpdateUserError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/{id}',
  });
};

/**
 * Undo delete
 * Undo delete for a user
 */
export const undoUser = <ThrowOnError extends boolean = false>(
  options: Options<UndoUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UndoUserResponse, UndoUserError, ThrowOnError>({
    ...options,
    url: '/api/iam/users/{id}/undo',
  });
};

/**
 * Update password
 * Updates a users password
 */
export const updateUserPassword = <ThrowOnError extends boolean = false>(
  options: Options<UpdateUserPasswordData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateUserPasswordResponse,
    UpdateUserPasswordError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/{id}/password',
  });
};

/**
 * Get tokens
 * Returns a list of tokens
 */
export const listTokens = <ThrowOnError extends boolean = false>(
  options: Options<ListTokensData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListTokensResponse, ListTokensError, ThrowOnError>({
    ...options,
    url: '/api/iam/users/{id}/tokens',
  });
};

/**
 * Update roles
 * Updates the roles that belong to a user
 */
export const updateUserRoles = <ThrowOnError extends boolean = false>(
  options: Options<UpdateUserRolesData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateUserRolesResponse,
    UpdateUserRolesError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/{id}/roles',
  });
};

/**
 * Join tenant
 * Accept an invite, set the password and mark user's email as verified
 */
export const joinTenant = <ThrowOnError extends boolean = false>(
  options: Options<JoinTenantData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<JoinTenantResponse, JoinTenantError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/join',
  });
};

/**
 * Reset password
 * So the user can login
 */
export const resetPassword = <ThrowOnError extends boolean = false>(
  options: Options<ResetPasswordData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<ResetPasswordResponse, ResetPasswordError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/password',
  });
};

/**
 * Mark verified
 * Verify a user
 */
export const verifyUser = <ThrowOnError extends boolean = false>(
  options: Options<VerifyUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<VerifyUserResponse, VerifyUserError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/verify',
  });
};

/**
 * Get token
 * Returns a user token by ID
 */
export const getUserToken = <ThrowOnError extends boolean = false>(
  options: Options<GetUserTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetUserTokenResponse, GetUserTokenError, ThrowOnError>({
    ...options,
    url: '/api/iam/users/tokens/{id}',
  });
};

/**
 * Request token
 * So the user can receive a password reset mail or confirm mail
 */
export const requestToken = <ThrowOnError extends boolean = false>(
  options: Options<RequestTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<RequestTokenResponse, RequestTokenError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/tokens',
  });
};

/**
 * Disable tokens
 * Invalidate all pending tokens of given type for user
 */
export const invalidateTokens = <ThrowOnError extends boolean = false>(
  options: Options<InvalidateTokensData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    InvalidateTokensResponse,
    InvalidateTokensError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/users/tokens/invalidate',
  });
};

/**
 * Get roles
 * Returns a list of available rbac roles
 */
export const listRoles = <ThrowOnError extends boolean = false>(
  options?: Options<ListRolesData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListRolesResponse, ListRolesError, ThrowOnError>({
    ...options,
    url: '/api/iam/roles/tenant',
  });
};

/**
 * Create role
 * Make a new RBAC role available
 */
export const createRole = <ThrowOnError extends boolean = false>(
  options: Options<CreateRoleData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<CreateRoleResponse, CreateRoleError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/roles/tenant',
  });
};

/**
 * Delete role
 * Delete a RBAC role
 */
export const deleteRole = <ThrowOnError extends boolean = false>(
  options: Options<DeleteRoleData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<DeleteRoleResponse, DeleteRoleError, ThrowOnError>({
    ...options,
    url: '/api/iam/roles/tenant/{id}',
  });
};

/**
 * View role
 * Returns the rbac role
 */
export const getRole = <ThrowOnError extends boolean = false>(
  options: Options<GetRoleData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetRoleResponse, GetRoleError, ThrowOnError>({
    ...options,
    url: '/api/iam/roles/tenant/{id}',
  });
};

/**
 * Update role
 * Update a RBAC role
 */
export const updateRole = <ThrowOnError extends boolean = false>(
  options: Options<UpdateRoleData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UpdateRoleResponse, UpdateRoleError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/roles/tenant/{id}',
  });
};

/**
 * Get systemroles
 * Returns a list of available system rbac roles
 */
export const listSystemRoles = <ThrowOnError extends boolean = false>(
  options?: Options<ListSystemRolesData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListSystemRolesResponse,
    ListSystemRolesError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/roles/system',
  });
};

/**
 * Update systemroles
 * Payload will overwrite all the existing client roles
 */
export const updateSystemRoles = <ThrowOnError extends boolean = false>(
  options: Options<UpdateSystemRolesData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateSystemRolesResponse,
    UpdateSystemRolesError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/roles/system',
  });
};

/**
 * Get systemrole
 * Returns a single rbac role
 */
export const getSystemRole = <ThrowOnError extends boolean = false>(
  options: Options<GetSystemRoleData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetSystemRoleResponse, GetSystemRoleError, ThrowOnError>({
    ...options,
    url: '/api/iam/roles/system/{id}',
  });
};

/**
 * Get clients
 * Returns a list of clients
 */
export const listClients = <ThrowOnError extends boolean = false>(
  options?: Options<ListClientsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListClientsResponse, ListClientsError, ThrowOnError>({
    ...options,
    url: '/api/oauth/clients',
  });
};

/**
 * Create client
 * Returns a new client
 */
export const createClient = <ThrowOnError extends boolean = false>(
  options: Options<CreateClientData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<CreateClientResponse, CreateClientError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/clients',
  });
};

/**
 * Delete client
 * Returns the deleted client
 */
export const deleteClient = <ThrowOnError extends boolean = false>(
  options: Options<DeleteClientData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<DeleteClientResponse, DeleteClientError, ThrowOnError>({
    ...options,
    url: '/api/oauth/clients/{id}',
  });
};

/**
 * Get client
 * Returns the oauth client for the given client id
 */
export const getClient = <ThrowOnError extends boolean = false>(
  options: Options<GetClientData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetClientResponse, GetClientError, ThrowOnError>({
    ...options,
    url: '/api/oauth/clients/{id}',
  });
};

/**
 * Update client
 * Returns an updated client
 */
export const updateClient = <ThrowOnError extends boolean = false>(
  options: Options<UpdateClientData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<UpdateClientResponse, UpdateClientError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/clients/{id}',
  });
};

/**
 * Get apps
 * Returns a list of open clients
 */
export const listOpenClients = <ThrowOnError extends boolean = false>(
  options?: Options<ListOpenClientsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListOpenClientsResponse,
    ListOpenClientsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/oauth/apps',
  });
};

/**
 * Logout user
 * Log the active user out
 */
export const userLogout = <ThrowOnError extends boolean = false>(
  options?: Options<UserLogoutData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<UserLogoutResponse, UserLogoutError, ThrowOnError>({
    ...options,
    url: '/api/oauth/session',
  });
};

/**
 * Get session
 * Get the active session state
 */
export const getSession = <ThrowOnError extends boolean = false>(
  options?: Options<GetSessionData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetSessionResponse, GetSessionError, ThrowOnError>({
    ...options,
    url: '/api/oauth/session',
  });
};

/**
 * Login user
 * Login with username and password
 */
export const loginUser = <ThrowOnError extends boolean = false>(
  options: Options<LoginUserData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<LoginUserResponse, LoginUserError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/session',
  });
};

/**
 * List subtenants
 * Get list of available subtenants
 */
export const getSessionSubtenants = <ThrowOnError extends boolean = false>(
  options?: Options<GetSessionSubtenantsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetSessionSubtenantsResponse,
    GetSessionSubtenantsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/oauth/session/subtenants',
  });
};

/**
 * Request token
 * Request a new verify token
 */
export const requestVerify = <ThrowOnError extends boolean = false>(
  options?: Options<RequestVerifyData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<RequestVerifyResponse, RequestVerifyError, ThrowOnError>({
    ...options,
    url: '/api/oauth/session/token',
  });
};

/**
 * Pusher token
 * Request a new pusher presence channel token
 */
export const requestPusherToken = <ThrowOnError extends boolean = false>(
  options: Options<RequestPusherTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    RequestPusherTokenResponse,
    RequestPusherTokenError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/session/pusher',
  });
};

/**
 * Verify 2FA
 * Send a 2fa token
 */
export const verify2Fa = <ThrowOnError extends boolean = false>(
  options: Options<Verify2FaData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<Verify2FaResponse, Verify2FaError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/session/2fa',
  });
};

/**
 * Create account
 * Create a new account
 */
export const sessionSignup = <ThrowOnError extends boolean = false>(
  options: Options<SessionSignupData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<unknown, SessionSignupError, ThrowOnError>({
    ...options,
    ...urlSearchParamsBodySerializer,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...options?.headers,
    },
    url: '/api/oauth/session/signup',
  });
};

/**
 * Backup 2FA
 * Send a backup otp
 */
export const backup2Fa = <ThrowOnError extends boolean = false>(
  options: Options<Backup2FaData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<Backup2FaResponse, Backup2FaError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/session/2fa/backup',
  });
};

/**
 * Get authorization
 * Returns a new authorization response
 */
export const getRedirect = <ThrowOnError extends boolean = false>(
  options?: Options<GetRedirectData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetRedirectResponse, GetRedirectError, ThrowOnError>({
    ...options,
    url: '/api/oauth/session/authorize',
  });
};

/**
 * Obtain token
 * Returns a new access token
 */
export const getAccessToken = <ThrowOnError extends boolean = false>(
  options: Options<GetAccessTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    GetAccessTokenResponse,
    GetAccessTokenError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/oauth/authorize',
  });
};

/**
 * Redirect client
 * Log the authorization, store approval and redirect the user
 */
export const getRedirect302 = <ThrowOnError extends boolean = false>(
  options: Options<GetRedirect302Data, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetRedirect302Response, GetRedirect302Error, ThrowOnError>(
    {
      ...options,
      url: '/api/oauth/session/forward/{reference}',
    },
  );
};

/**
 * List numbers
 * List SLA numbers
 */
export const listNumbers = <ThrowOnError extends boolean = false>(
  options?: Options<ListNumbersData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListNumbersResponse, ListNumbersError, ThrowOnError>({
    ...options,
    url: '/api/services/sla/numbers',
  });
};

/**
 * Create number
 * Create SLA number
 */
export const createNumber = <ThrowOnError extends boolean = false>(
  options?: Options<CreateNumberData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<CreateNumberResponse, CreateNumberError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/services/sla/numbers',
  });
};

/**
 * Search number
 * Find an SLA number
 */
export const findSlaNumber = <ThrowOnError extends boolean = false>(
  options: Options<FindSlaNumberData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<FindSlaNumberResponse, FindSlaNumberError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/services/sla/numbers/search',
  });
};

/**
 * Start onboarding
 * Start an external onboarding session
 */
export const startOnboardingSession = <ThrowOnError extends boolean = false>(
  options: Options<StartOnboardingSessionData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<unknown, StartOnboardingSessionError, ThrowOnError>({
    ...options,
    url: '/api/onboarding/{type}',
  });
};

/**
 * Get onboarding
 * Get an external onboarding session
 */
export const getOnboardingSession = <ThrowOnError extends boolean = false>(
  options: Options<GetOnboardingSessionData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetOnboardingSessionResponse,
    GetOnboardingSessionError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/onboarding/{id}',
  });
};

/**
 * Confirm onboarding
 * Confirm onboarding session
 */
export const confirmOnboardingSession = <ThrowOnError extends boolean = false>(
  options: Options<ConfirmOnboardingSessionData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    ConfirmOnboardingSessionResponse,
    ConfirmOnboardingSessionError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/onboarding/{id}/confirm',
  });
};

/**
 * Activate onboarding
 * Activate onboarding session
 */
export const activateOnboardingSession = <ThrowOnError extends boolean = false>(
  options: Options<ActivateOnboardingSessionData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    ActivateOnboardingSessionResponse,
    ActivateOnboardingSessionError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/onboarding/{id}/activate',
  });
};

/**
 * Return onboarding
 * Return to 3rd party platform after onboarding
 */
export const returnAfterOnboarding = <ThrowOnError extends boolean = false>(
  options: Options<ReturnAfterOnboardingData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<unknown, ReturnAfterOnboardingError, ThrowOnError>({
    ...options,
    url: '/api/iam/onboarding/{id}/return',
  });
};

/**
 * Get hosted
 * Return hosted config
 */
export const getHostedConfig = <ThrowOnError extends boolean = false>(
  options: Options<GetHostedConfigData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetHostedConfigResponse,
    GetHostedConfigError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/commerce/hosted/config',
  });
};

/**
 * List tokens
 * Return a list of stateful / non expiring
 */
export const listAuthTokens = <ThrowOnError extends boolean = false>(
  options?: Options<ListAuthTokensData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListAuthTokensResponse, ListAuthTokensError, ThrowOnError>(
    {
      ...options,
      url: '/api/iam/tokenauth/tokens',
    },
  );
};

/**
 * Create token
 * Create an auth token
 */
export const createAuthToken = <ThrowOnError extends boolean = false>(
  options: Options<CreateAuthTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    CreateAuthTokenResponse,
    CreateAuthTokenError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/tokenauth/tokens',
  });
};

/**
 * Retract token
 * Retract / invalidate a non expiring auth token
 */
export const retractAuthToken = <ThrowOnError extends boolean = false>(
  options: Options<RetractAuthTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<
    RetractAuthTokenResponse,
    RetractAuthTokenError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/tokenauth/tokens/{id}',
  });
};

/**
 * Get token
 * Return a non expiring auth token by id
 */
export const getAuthToken = <ThrowOnError extends boolean = false>(
  options: Options<GetAuthTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetAuthTokenResponse, GetAuthTokenError, ThrowOnError>({
    ...options,
    url: '/api/iam/tokenauth/tokens/{id}',
  });
};

/**
 * Validate token
 * Validate and read an auth token
 */
export const validateToken = <ThrowOnError extends boolean = false>(
  options?: Options<ValidateTokenData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<ValidateTokenResponse, ValidateTokenError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/tokenauth/validator',
  });
};
