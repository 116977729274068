export const gray = {
  900: '#24303C',
  800: '#37495B',
  700: '#496179',
  600: '#5C7A98',
  500: '#7892AD',
  400: '#96ABBF',
  300: '#B5C3D2',
  200: '#D8E0E7',
  100: '#E8ECF1',
  75: '#F2F4F7',
  50: '#F9F9FB',
  25: '#FBFCFD',
};

export const darkGray = {
  700: '#12181E',
  600: '#151C23',
  500: '#182028',
  400: '#1B242D',
  300: '#1E2832',
  200: '#212C37',
  100: '#24303C',
};

export const primary = {
  900: '#031725',
  800: '#062F4B',
  700: '#094771',
  600: '#0C5E96',
  500: '#0F76BC',
  400: '#BEE1F9',
  300: '#DCEFFC',
  200: '#ECF6FD',
  100: '#F9FCFF',
};

export const secondary = {
  900: '#004125',
  800: '#007240',
  700: '#00A35C',
  600: '#00D478',
  500: '#0AFF95',
  400: '#B9FEE0',
  300: '#DAFEEF',
  200: '#EAFFF6',
  100: '#F9FFFC',
};

export const red = {
  900: '#3C100C',
  800: '#641B15',
  700: '#8D261E',
  600: '#B53227',
  500: '#D44438',
  400: '#F4CFCC',
  300: '#F9E8E7',
  200: '#FCF5F4',
  100: '#FEFAFA',
};

export const vermilion = {
  900: '#2C1404',
  800: '#582909',
  700: '#843E0E',
  600: '#B05213',
  500: '#DC6718',
  400: '#F8DBC7',
  300: '#FCEEE5',
  200: '#FDF7F3',
  100: '#FEFBF9',
};

export const orange = {
  900: '#302300',
  800: '#584000',
  700: '#815E01',
  600: '#A97C01',
  500: '#D29902',
  400: '#FEEDC2',
  300: '#FEF2D2',
  200: '#FEF7E2',
  100: '#FEFDF7',
};

export const yellow = {
  900: '#161800',
  800: '#3C4100',
  700: '#636A00',
  600: '#899200',
  500: '#AFBB00',
  400: '#FAFEC1',
  300: '#FDFEE2',
  200: '#FEFFF2',
  100: '#FEFFF7',
};

export const springGreen = {
  900: '#0A1E15',
  800: '#123626',
  700: '#1B4E38',
  600: '#236749',
  500: '#2C7F5A',
  400: '#CBEDDE',
  300: '#E3F5ED',
  200: '#EFF9F5',
  100: '#F9FDFB',
};

export const cyan = {
  900: '#022525',
  800: '#043C3C',
  700: '#065352',
  600: '#076A69',
  500: '#098180',
  400: '#C5FBFA',
  300: '#DCFCFC',
  200: '#ECFDFD',
  100: '#F8FEFE',
};

export const cerulean = {
  900: '#0E132A',
  800: '#1B254E',
  700: '#283672',
  600: '#354796',
  500: '#4258BA',
  400: '#CBD1EC',
  300: '#E3E7F5',
  200: '#EFF1F9',
  100: '#F9FAFD',
};

export const volta = {
  900: '#110C24',
  800: '#221948',
  700: '#33256C',
  600: '#453291',
  500: '#563FB5',
  400: '#D2CBED',
  300: '#E7E3F5',
  200: '#F1EFF9',
  100: '#FAF9FD',
};

export const purple = {
  900: '#1F0E2A',
  800: '#3A1B4E',
  700: '#552872',
  600: '#703596',
  500: '#8B42BA',
  400: '#DFCBEC',
  300: '#EEE3F5',
  200: '#F5EFF9',
  100: '#FBF9FD',
};

export const magenta = {
  900: '#1E0A1E',
  800: '#421742',
  700: '#662366',
  600: '#8B308B',
  500: '#AF3DAF',
  400: '#EDCBED',
  300: '#F5E3F5',
  200: '#F9EFF9',
  100: '#FDF9FD',
};

export const rose = {
  900: '#2C0C1F',
  800: '#52173B',
  700: '#792156',
  600: '#9F2C72',
  500: '#C5378D',
  400: '#EFC8E0',
  300: '#F6E2EE',
  200: '#FAEFF6',
  100: '#FDF9FB',
};

export const vibrant = {
  red: '#FA584C',
  vermilion: '#FF822E',
  orange: '#FFC626',
  yellow: '#E4F23A',
  springGreen: '#0AFF95',
  cyan: '#04EFEA',
  blue: '#3FB2FF',
  cerulean: '#5A7AFC',
  volta: '#805FFF',
  purple: '#C06BFB',
  magenta: '#EE6FEE',
  rose: '#FA64BD',
};
