import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@afosto/components';
import ToastCloseButton from '../ToastCloseButton';
import ToastIcon from '../ToastIcon';
import useToast from '../hooks/useToast';
import * as Styled from './ToastContent.styles';

const ToastContent = React.forwardRef(
  ({ id, icon, message, description, severity, dismissible, actions, style }, ref) => {
    const { closeToast } = useToast();

    const handleClose = () => {
      closeToast(id);
    };

    return (
      <Styled.ToastBase ref={ref} style={style}>
        {severity && <ToastIcon severity={severity} icon={icon} />}
        <Box flex={1}>
          <Box display="flex" alignItems="flex-start">
            <div>
              <Typography fontWeight={500} color="white">
                {message}
              </Typography>
              {description && (
                <Typography color="gray.200" mt={0.5}>
                  {description}
                </Typography>
              )}
            </div>
            {dismissible && <ToastCloseButton onClose={handleClose} />}
          </Box>
          {actions && <Styled.ActionsWrapper>{actions}</Styled.ActionsWrapper>}
        </Box>
      </Styled.ToastBase>
    );
  },
);

ToastContent.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.elementType,
  severity: PropTypes.oneOf(['default', 'success', 'error', 'warning', 'info']),
  dismissible: PropTypes.bool,
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  style: PropTypes.object,
};

ToastContent.defaultProps = {
  description: undefined,
  icon: undefined,
  severity: undefined,
  dismissible: false,
  actions: undefined,
  style: undefined,
};

export default ToastContent;
