import { client, type Options } from "@afosto/client-fetch";
import type {
  GetBillingInformationData,
  GetBillingInformationError,
  GetBillingInformationResponse,
  CreateCustomerData,
  CreateCustomerError,
  CreateCustomerResponse2,
  UpdateBillingInformationData,
  UpdateBillingInformationError,
  UpdateBillingInformationResponse,
  GetCouponCodeData,
  GetCouponCodeError,
  GetCouponCodeResponse,
  DeleteDefaultPaymentMethodData,
  DeleteDefaultPaymentMethodError,
  DeleteDefaultPaymentMethodResponse,
  GetDefaultPaymentMethodData,
  GetDefaultPaymentMethodError,
  GetDefaultPaymentMethodResponse,
  StartPaymentSessionData,
  StartPaymentSessionError,
  StartPaymentSessionResponse,
  GetSessionStatusData,
  GetSessionStatusError,
  GetSessionStatusResponse,
  ForwardSessionToBankData,
  ForwardSessionToBankError,
  HandleStripeWebhookData,
  HandleStripeWebhookError,
  HandleStripeWebhookResponse,
  ListProductsData,
  ListProductsError,
  ListProductsResponse,
  ListSubscriptionsData,
  ListSubscriptionsError,
  ListSubscriptionsResponse,
  AddSubscriptionData,
  AddSubscriptionError,
  AddSubscriptionResponse,
  AddAddOnData,
  AddAddOnError,
  AddAddOnResponse,
  ListSubscriptionInvoicesData,
  ListSubscriptionInvoicesError,
  ListSubscriptionInvoicesResponse,
  GetUpcomingData,
  GetUpcomingError,
  GetUpcomingResponse,
} from './types';

export { client };

/**
 * Get billing
 * Customer information regarding billing data
 */
export const getBillingInformation = <ThrowOnError extends boolean = false>(
  options?: Options<GetBillingInformationData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetBillingInformationResponse,
    GetBillingInformationError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/customer',
  });
};

/**
 * Create customer
 * Create a customer
 */
export const createCustomer = <ThrowOnError extends boolean = false>(
  options: Options<CreateCustomerData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    CreateCustomerResponse2,
    CreateCustomerError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/customer',
  });
};

/**
 * Update billing
 * Update customer information regarding billing data
 */
export const updateBillingInformation = <ThrowOnError extends boolean = false>(
  options: Options<UpdateBillingInformationData, ThrowOnError>,
) => {
  return (options?.client ?? client).put<
    UpdateBillingInformationResponse,
    UpdateBillingInformationError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/customer',
  });
};

/**
 * Get coupon
 * Get the coupon by code
 */
export const getCouponCode = <ThrowOnError extends boolean = false>(
  options: Options<GetCouponCodeData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetCouponCodeResponse, GetCouponCodeError, ThrowOnError>({
    ...options,
    url: '/api/iam/coupons/{code}',
  });
};

/**
 * Delete method
 * Delete the default payment method
 */
export const deleteDefaultPaymentMethod = <ThrowOnError extends boolean = false>(
  options?: Options<DeleteDefaultPaymentMethodData, ThrowOnError>,
) => {
  return (options?.client ?? client).delete<
    DeleteDefaultPaymentMethodResponse,
    DeleteDefaultPaymentMethodError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/payments/default',
  });
};

/**
 * Get method
 * Get the default payment method
 */
export const getDefaultPaymentMethod = <ThrowOnError extends boolean = false>(
  options?: Options<GetDefaultPaymentMethodData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetDefaultPaymentMethodResponse,
    GetDefaultPaymentMethodError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/payments/default',
  });
};

/**
 * Start session
 * Create a session to add a payment method
 */
export const startPaymentSession = <ThrowOnError extends boolean = false>(
  options: Options<StartPaymentSessionData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    StartPaymentSessionResponse,
    StartPaymentSessionError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/payments/sessions',
  });
};

/**
 * Get session
 * Get the session status
 */
export const getSessionStatus = <ThrowOnError extends boolean = false>(
  options: Options<GetSessionStatusData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetSessionStatusResponse,
    GetSessionStatusError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/payments/sessions/{id}',
  });
};

/**
 * Forward session
 * Forward the session to the bank
 */
export const forwardSessionToBank = <ThrowOnError extends boolean = false>(
  options: Options<ForwardSessionToBankData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<unknown, ForwardSessionToBankError, ThrowOnError>({
    ...options,
    url: '/api/iam/payments/sessions/{id}/start',
  });
};

/**
 * Receive webhook
 * Handle stripe webhooks
 */
export const handleStripeWebhook = <ThrowOnError extends boolean = false>(
  options: Options<HandleStripeWebhookData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    HandleStripeWebhookResponse,
    HandleStripeWebhookError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/payments/webhooks/stripe',
  });
};

/**
 * List products
 * Obtain a list of products
 */
export const listProducts = <ThrowOnError extends boolean = false>(
  options?: Options<ListProductsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListProductsResponse, ListProductsError, ThrowOnError>({
    ...options,
    url: '/api/iam/products',
  });
};

/**
 * List subscriptions
 * Obtain a live list of active subscriptions
 */
export const listSubscriptions = <ThrowOnError extends boolean = false>(
  options?: Options<ListSubscriptionsData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListSubscriptionsResponse,
    ListSubscriptionsError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subscriptions',
  });
};

/**
 * Upsert subscription
 * Create a subscription or update an existing one
 */
export const addSubscription = <ThrowOnError extends boolean = false>(
  options: Options<AddSubscriptionData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    AddSubscriptionResponse,
    AddSubscriptionError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/subscriptions',
  });
};

/**
 * Create addon
 * Add an addon to a subscription
 */
export const addAddOn = <ThrowOnError extends boolean = false>(
  options: Options<AddAddOnData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<AddAddOnResponse, AddAddOnError, ThrowOnError>({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/iam/subscriptions/addons',
  });
};

/**
 * List invoices
 * Obtain a live list of active invoices
 */
export const listSubscriptionInvoices = <ThrowOnError extends boolean = false>(
  options?: Options<ListSubscriptionInvoicesData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListSubscriptionInvoicesResponse,
    ListSubscriptionInvoicesError,
    ThrowOnError
  >({
    ...options,
    url: '/api/iam/subscriptions/invoices',
  });
};

/**
 * View invoice
 * Obtain a live projection of the upcoming invoice
 */
export const getUpcoming = <ThrowOnError extends boolean = false>(
  options?: Options<GetUpcomingData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<GetUpcomingResponse, GetUpcomingError, ThrowOnError>({
    ...options,
    url: '/api/iam/subscriptions/invoices/next',
  });
};
