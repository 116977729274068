import { forwardRef } from 'react';
import { SvgIcon as MuiSvgIcon } from '@mui/material';
import type { SvgIconProps } from './types';

const SvgIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => {
  const { component = 'svg', fontSize = 'inherit', ...rest } = props || {};

  return <MuiSvgIcon ref={ref} {...rest} component={component} fontSize={fontSize} />;
});

export default SvgIcon;
