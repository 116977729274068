import { Backdrop, CircularProgress, Dialog } from '@afosto/components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FailedPrintJobs from './components/FailedPrintJobs';
import SheetPositionsSelection from './components/SheetPositionsSelection';
import PrinterSelection from './components/PrinterSelection';

const PrintDialog = ({
  open,
  onClose,
  onPrint,
  onExited,
  printers,
  documents,
  defaultType,
  allowMerging,
  isPrinting,
  failedJobs,
  isFetchingPrinters,
}) => {
  const [activeType, setActiveType] = useState(defaultType || 'PAPER');
  const [selectedPrinter, setSelectedPrinter] = useState(null);
  const [showSelectPositionsStep, setShowSelectPositionsStep] = useState(false);
  const [selectedPositions, setSelectedPositions] = useState({
    a: true,
    b: true,
    c: true,
    d: true,
  });
  const hasFailedJobs = failedJobs.length > 0;

  const handleExited = () => {
    setActiveType(defaultType);
    setSelectedPrinter(null);
    setShowSelectPositionsStep(false);
    setSelectedPositions({ a: true, b: true, c: true, d: true });
    if (typeof onExited === 'function') {
      onExited();
    }
  };

  const handleBack = () => {
    setShowSelectPositionsStep(false);
    setSelectedPositions({ a: true, b: true, c: true, d: true });
    setSelectedPrinter(null);
  };

  const handleChangeActiveType = (event, value) => {
    setActiveType(value);
  };

  const handleClickPrinter = printer => async () => {
    if (allowMerging && printer?.type === 'PAPER') {
      setShowSelectPositionsStep(true);
      setSelectedPrinter(printer);
      return;
    }

    await onPrint({ printer, documents });
  };

  const handlePrintMergedPdf = async () => {
    const selectedPositionsValues = Object.values(selectedPositions);
    const formattedDocuments = [...documents];
    selectedPositionsValues.forEach((included, idx) => {
      if (!included) {
        formattedDocuments.splice(idx, 0, '');
      }
    });

    await onPrint({
      printer: selectedPrinter,
      documents: [
        `https://afosto.app/api/tools/pdf?per_page=4&files=${formattedDocuments.join(',')}`,
      ],
    });
  };

  const handleRetryJob = job => async () => {
    await onPrint({ printer: { id: job.printerId }, documents: [job.content] });
  };

  const handleRetryAllJobs = async () => {
    await onPrint({
      printer: { id: failedJobs.at(0).printerId },
      documents: failedJobs.map(({ content }) => content),
    });
  };

  const handleClickPosition = idx => () => {
    setSelectedPositions(prevState => ({
      ...prevState,
      [idx]: !prevState[idx],
    }));
  };

  useEffect(() => {
    if (open) {
      setActiveType(defaultType);
    }
  }, [open, defaultType]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      sx={{ '.MuiDialog-paper': { height: '90vh', maxHeight: 504 } }}
      TransitionProps={{ onExited: handleExited }}
    >
      {!showSelectPositionsStep && !hasFailedJobs && (
        <PrinterSelection
          activeType={activeType}
          onChangeActiveType={handleChangeActiveType}
          printers={printers}
          onClickPrinter={handleClickPrinter}
          isFetchingPrinters={isFetchingPrinters}
          onClose={onClose}
          documents={documents}
        />
      )}
      {showSelectPositionsStep && !hasFailedJobs && (
        <SheetPositionsSelection
          onClickPosition={handleClickPosition}
          onClose={onClose}
          onBack={handleBack}
          selectedPositions={selectedPositions}
          isPrinting={isPrinting}
          onPrintMergedPdf={handlePrintMergedPdf}
        />
      )}
      {hasFailedJobs && (
        <FailedPrintJobs
          isPrinting={isPrinting}
          failedJobs={failedJobs}
          onRetryAllJobs={handleRetryAllJobs}
          onRetryJob={handleRetryJob}
          onClose={onClose}
        />
      )}
      <Backdrop
        open={isPrinting}
        sx={{ position: 'absolute', zIndex: 10, backgroundColor: 'rgba(255,255,255,.75)' }}
      >
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
};

PrintDialog.propTypes = {
  allowMerging: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  printers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.oneOf(['PAPER', 'LABEL']),
      state: PropTypes.oneOf(['ONLINE', 'OFFLINE']),
      serverId: PropTypes.number,
      id: PropTypes.number,
      createdAt: PropTypes.number,
    }),
  ),
  defaultType: PropTypes.oneOf(['PAPER', 'LABEL']),
  documents: PropTypes.arrayOf(PropTypes.string),
};

PrintDialog.defaultProps = {
  open: false,
  onClose: undefined,
  onExited: undefined,
  printers: [],
  defaultType: 'PAPER',
  allowMerging: false,
};

export default PrintDialog;
