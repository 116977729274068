import { isDefined } from '@afosto/utils';
import useChannel from './useChannel';
import { useAuthentication } from '../../AuthenticationProvider';

const usePresenceChannel = (name, options = {}) => {
  const { tenantId } = useAuthentication();
  const channelName = `presence-${tenantId}_${name}`;

  return useChannel(channelName, {
    ...options,
    enabled: !!tenantId && (!isDefined(options.enabled) || options.enabled === true),
  });
};

export default usePresenceChannel;
