import IconButton from '../IconButton';
import SvgIcon from '../SvgIcon';
import { forwardRef } from 'react';
import Box from '../Box';
import Typography from '../Typography';
import type { TypographyProps } from '../Typography/types';
import type { DialogHeaderProps } from './types';

const DialogHeader = forwardRef<HTMLElement, DialogHeaderProps>(
  (
    {
      action,
      backButtonProps = {},
      closeButtonProps = {},
      description,
      descriptionTypographyProps: providedDescriptionTypographyProps,
      onBack,
      onClose,
      startAdornment,
      sx,
      title,
      titleTypographyProps: providedTitleTypographyProps,
    },
    ref,
  ) => {
    const titleTypographyProps = {
      variant: 'h5',
      color: 'gray.900',
      component: 'div',
      lineHeight: '24px',
      mb: description ? 1 : 0,
      ...(providedTitleTypographyProps || {}),
    } as TypographyProps;

    const descriptionTypographyProps = {
      color: 'gray.800',
      ...(providedDescriptionTypographyProps || {}),
    } as TypographyProps;

    const hasStartAdornment = !!onBack || startAdornment;

    return (
      <Box
        ref={ref}
        px={3}
        pt={!description ? 2.5 : 3}
        sx={{
          ...(!description
            ? {
                '&:not(:has(+ .MuiDialogActions-root))': {
                  borderBottom: theme => `1px solid ${theme.palette.gray[100]}`,
                  pb: 2.5,
                },
              }
            : { pb: 0 }),
          '&:has(+ .MuiDialogActions-root)': { pb: 2.5 },
          ...sx,
        }}
      >
        {!hasStartAdornment && !!title && (
          <Box display="flex" alignItems={description ? 'flex-start' : 'center'} gap={2}>
            <Typography {...titleTypographyProps}>{title}</Typography>
            {(action || onClose) && (
              <Box display="flex" gap={1} ml="auto" mt={-0.5} mb={-0.5}>
                {action}
                {onClose && !closeButtonProps?.disabled && (
                  <IconButton
                    variant="minimal"
                    size="small"
                    {...closeButtonProps}
                    onClick={onClose}
                  >
                    <SvgIcon>
                      <path d="M19.06 7.06a1.5 1.5 0 0 0-2.12-2.12L12 9.88 7.06 4.94a1.5 1.5 0 0 0-2.12 2.12L9.88 12l-4.94 4.94a1.5 1.5 0 0 0 2.12 2.12L12 14.12l4.94 4.94a1.5 1.5 0 0 0 2.12-2.12L14.12 12l4.94-4.94Z" />
                    </SvgIcon>
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        )}
        {hasStartAdornment && !!title && (
          <Box display="flex" alignItems={description ? 'flex-start' : 'center'} gap={2}>
            {!!onBack && (
              <IconButton
                variant="outlined"
                color="secondary"
                size="small"
                {...backButtonProps}
                onClick={onBack}
              >
                <SvgIcon>
                  <path d="M10.64 16.46 7.7 13.58h13.22c.59 0 1.07-.47 1.07-1.05v-1.06c0-.58-.48-1.05-1.07-1.05H7.7l2.93-2.88c.41-.4.41-1.08 0-1.49l-.76-.74a1.08 1.08 0 0 0-1.52 0L2.3 11.26a1.04 1.04 0 0 0 0 1.48l6.05 5.95c.42.41 1.1.41 1.52 0l.76-.74c.41-.41.41-1.08 0-1.5Z" />
                </SvgIcon>
              </IconButton>
            )}
            {!onBack && !!startAdornment && startAdornment}
            <div>
              <Typography {...titleTypographyProps}>{title}</Typography>
              {!!description && (
                <Typography {...descriptionTypographyProps}>{description}</Typography>
              )}
            </div>
            {(action || onClose) && (
              <Box display="flex" gap={1} ml="auto" mt={-0.5} mb={-0.5}>
                {action}
                {onClose && !closeButtonProps?.disabled && (
                  <IconButton
                    variant="minimal"
                    size="small"
                    {...closeButtonProps}
                    onClick={onClose}
                    sx={{ mr: -0.5 }}
                  >
                    <SvgIcon>
                      <path d="M19.06 7.06a1.5 1.5 0 0 0-2.12-2.12L12 9.88 7.06 4.94a1.5 1.5 0 0 0-2.12 2.12L9.88 12l-4.94 4.94a1.5 1.5 0 0 0 2.12 2.12L12 14.12l4.94 4.94a1.5 1.5 0 0 0 2.12-2.12L14.12 12l4.94-4.94Z" />
                    </SvgIcon>
                  </IconButton>
                )}
              </Box>
            )}
          </Box>
        )}
        {!hasStartAdornment && !!description && (
          <Typography {...descriptionTypographyProps}>{description}</Typography>
        )}
      </Box>
    );
  },
);

export default DialogHeader;
