import { Box, CircularProgress, Drawer } from '@afosto/components';
import { useGetLatest } from '@afosto/hooks';
import { useEffect, useState } from 'react';

const LiveChatDrawer = ({ open, loadLiveChat, closeDrawer }) => {
  const [shouldKeepMounted, setShouldKeepMounted] = useState(true);
  const getLatestShouldKeepMounted = useGetLatest(shouldKeepMounted);

  useEffect(() => {
    const latestShouldKeepMounted = getLatestShouldKeepMounted();
    if (open && !latestShouldKeepMounted) {
      setShouldKeepMounted(true);
    }
  }, [getLatestShouldKeepMounted, open]);

  return (
    <Drawer
      open={open}
      onClose={closeDrawer}
      anchor="right"
      sx={{ zIndex: 2000 }}
      ModalProps={{
        keepMounted: shouldKeepMounted,
        onTransitionEnter: loadLiveChat,
      }}
      PaperProps={{
        sx: {
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '420px',
          width: '100%',
        },
      }}
    >
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          width: '100%',
          '#hubspot-conversations-inline-parent': {
            display: 'flex',
            width: '100%',
          },
          '#hubspot-conversations-inline-iframe': {
            width: '100%',
            border: 'none',
          },
        }}
        id="hubspot-livechat"
      >
        <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', zIndex: -1 }} />
      </Box>
    </Drawer>
  );
};

export default LiveChatDrawer;
