import * as Sentry from '@sentry/react';
import { client } from '@afosto/client-fetch';
import { graphQLClient } from '@afosto/graphql-client';
import { isDefined } from '@afosto/utils';
import { getErrorMessage } from '@afosto/utils-shared';

const setupApiClient = () => {
  client.interceptors.error.use(async (error, response, request) => {
    try {
      const message = getErrorMessage(error, { skipSentry: true }) || '';
      const { status: statusCode, redirected } = response || {};
      const { method, url } = request || {};

      if (statusCode !== 404) {
        Sentry.withScope(scope => {
          scope.setFingerprint([
            'FetchError',
            ...(isDefined(method) ? [method] : []),
            ...(statusCode !== 401 && isDefined(url) ? [url] : []),
            ...(isDefined(statusCode) ? [`${statusCode}`] : [`no_response`]),
            ...(redirected ? ['redirected'] : ['not_redirected']),
            message,
          ]);
          scope.setExtras({
            message,
            method,
            url,
            errorCode: error?.error?.code,
            reference: error?.error?.details?.reference,
            status: isDefined(statusCode) ? statusCode : 'no_response',
            responseData: error?.data || 'no_response_data',
            tenantId: error?.meta?.tenant_id,
            traceId: error?.meta?.trace_id,
          });
          scope.setTags({
            requestType: 'fetch',
          });
          scope.setLevel('error');

          Sentry.captureMessage(
            `FetchError${method ? ` ${method}` : ''}${statusCode ? ` (${statusCode})` : ''}: ${
              message || 'Message unknown'
            }`,
          );
          Sentry.captureException(error);
        });
      }
    } catch {
      // Do nothing.
    }

    return error;
  });

  graphQLClient.client.url = 'https://afosto.app/graphql';
  graphQLClient.setExcludeConversionKeys(['i18n']);
  graphQLClient.setOnErrorHandler((error, context) => {
    const message = getErrorMessage(error, { skipSentry: true }) || '';
    const { status: statusCode } = error?.response || {};
    const operationNameMatch = (context?.query || '').match(/(query|mutation)\s+(\w+)/);
    const operationName = operationNameMatch ? operationNameMatch[2] : 'unknown_operation';

    if (statusCode !== 404) {
      Sentry.withScope(scope => {
        scope.setFingerprint([
          'GqlError',
          ...(isDefined(statusCode) ? [`${statusCode}`] : [`no_response`]),
          ...(isDefined(operationName) ? [operationName] : []),
          ...(isDefined(context?.query) ? [context.query] : ['no_query']),
          message,
        ]);
        scope.setExtras({
          message,
          url: context?.url,
          operationName,
          query: context?.query,
          status: isDefined(statusCode) ? statusCode : 'no_response',
          errors:
            isDefined(error?.response?.errors) && error?.response?.errors?.length > 0
              ? JSON.stringify(error.response.errors)
              : '',
          extensions: isDefined(error?.response?.extensions)
            ? JSON.stringify(error.response.extensions)
            : '',
          responseData: isDefined(error?.response?.data)
            ? JSON.stringify(error.response.data)
            : 'no_response_data',
        });
        scope.setTags({
          requestType: 'gql',
        });
        scope.setLevel('error');

        Sentry.captureMessage(
          `GqlError${operationName ? ` ${operationName}: ` : ': '}${message || 'Message unknown'}`,
        );
        Sentry.captureException(error);
      });
    }
  });
};

export default setupApiClient;
