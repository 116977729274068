import { client, type Options } from '@afosto/client-fetch';
import type {
  GetPrintAccountData,
  GetPrintAccountError,
  GetPrintAccountResponse,
  ActivateAccountData,
  ActivateAccountError,
  ActivateAccountResponse,
  EnqueuePrintJobData,
  EnqueuePrintJobError,
  EnqueuePrintJobResponse,
  ListPrintersData,
  ListPrintersError,
  ListPrintersResponse,
  ListPrintServersData,
  ListPrintServersError,
  ListPrintServersResponse,
} from './types';

export { client };

/**
 * Get account
 * Return the printnode account
 */
export const getPrintAccount = <ThrowOnError extends boolean = false>(
  options?: Options<GetPrintAccountData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    GetPrintAccountResponse,
    GetPrintAccountError,
    ThrowOnError
  >({
    ...options,
    url: '/api/spooler/account',
  });
};

/**
 * Activate account
 * Create a new printnode account
 */
export const activateAccount = <ThrowOnError extends boolean = false>(
  options?: Options<ActivateAccountData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    ActivateAccountResponse,
    ActivateAccountError,
    ThrowOnError
  >({
    ...options,
    url: '/api/spooler/account',
  });
};

/**
 * Enqueue jobs
 * Add an print job to the queue
 */
export const enqueuePrintJob = <ThrowOnError extends boolean = false>(
  options: Options<EnqueuePrintJobData, ThrowOnError>,
) => {
  return (options?.client ?? client).post<
    EnqueuePrintJobResponse,
    EnqueuePrintJobError,
    ThrowOnError
  >({
    ...options,
    headers: {
      'Content-Type': 'application/json',
      ...options?.headers,
    },
    url: '/api/spooler/queue',
  });
};

/**
 * List printers
 * List available printers
 */
export const listPrinters = <ThrowOnError extends boolean = false>(
  options?: Options<ListPrintersData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<ListPrintersResponse, ListPrintersError, ThrowOnError>({
    ...options,
    url: '/api/spooler/account/printers',
  });
};

/**
 * List servers
 * List available print servers
 */
export const listPrintServers = <ThrowOnError extends boolean = false>(
  options?: Options<ListPrintServersData, ThrowOnError>,
) => {
  return (options?.client ?? client).get<
    ListPrintServersResponse,
    ListPrintServersError,
    ThrowOnError
  >({
    ...options,
    url: '/api/spooler/account/servers',
  });
};
