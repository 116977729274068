import type { Options } from '@afosto/client-fetch';
import { queryOptions, type UseMutationOptions } from '@tanstack/react-query';
import type {
  GetBillingInformationData,
  CreateCustomerData,
  CreateCustomerError,
  CreateCustomerResponse2,
  UpdateBillingInformationData,
  UpdateBillingInformationError,
  UpdateBillingInformationResponse,
  GetCouponCodeData,
  DeleteDefaultPaymentMethodData,
  DeleteDefaultPaymentMethodError,
  DeleteDefaultPaymentMethodResponse,
  GetDefaultPaymentMethodData,
  StartPaymentSessionData,
  StartPaymentSessionError,
  StartPaymentSessionResponse,
  GetSessionStatusData,
  ForwardSessionToBankData,
  HandleStripeWebhookData,
  HandleStripeWebhookError,
  HandleStripeWebhookResponse,
  ListProductsData,
  ListSubscriptionsData,
  AddSubscriptionData,
  AddSubscriptionError,
  AddSubscriptionResponse,
  AddAddOnData,
  AddAddOnError,
  AddAddOnResponse,
  ListSubscriptionInvoicesData,
  GetUpcomingData,
} from '../types';
import {
  client,
  getBillingInformation,
  createCustomer,
  updateBillingInformation,
  getCouponCode,
  deleteDefaultPaymentMethod,
  getDefaultPaymentMethod,
  startPaymentSession,
  getSessionStatus,
  forwardSessionToBank,
  handleStripeWebhook,
  listProducts,
  listSubscriptions,
  addSubscription,
  addAddOn,
  listSubscriptionInvoices,
  getUpcoming,
} from '../api';

type QueryKey<TOptions extends Options> = [
  Pick<TOptions, 'baseUrl' | 'body' | 'headers' | 'path' | 'query'> & {
    _id: string;
    _infinite?: boolean;
  },
];

const createQueryKey = <TOptions extends Options>(
  id: string,
  options?: TOptions,
  infinite?: boolean,
): QueryKey<TOptions>[0] => {
  const params: QueryKey<TOptions>[0] = {
    _id: id,
    baseUrl: (options?.client ?? client).getConfig().baseUrl,
  } as QueryKey<TOptions>[0];
  if (infinite) {
    params._infinite = infinite;
  }
  if (options?.body) {
    params.body = options.body;
  }
  if (options?.headers) {
    params.headers = options.headers;
  }
  if (options?.path) {
    params.path = options.path;
  }
  if (options?.query) {
    params.query = options.query;
  }
  return params;
};

export const getBillingInformationQueryKey = (options?: Options<GetBillingInformationData>) => [
  createQueryKey('getBillingInformation', options),
];

export const getBillingInformationOptions = (options?: Options<GetBillingInformationData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getBillingInformation({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getBillingInformationQueryKey(options),
  });
};

export const createCustomerQueryKey = (options: Options<CreateCustomerData>) => [
  createQueryKey('createCustomer', options),
];

export const createCustomerOptions = (options: Options<CreateCustomerData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await createCustomer({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: createCustomerQueryKey(options),
  });
};

export const createCustomerMutation = (options?: Partial<Options<CreateCustomerData>>) => {
  const mutationOptions: UseMutationOptions<
    CreateCustomerResponse2,
    CreateCustomerError,
    Options<CreateCustomerData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await createCustomer({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const updateBillingInformationMutation = (
  options?: Partial<Options<UpdateBillingInformationData>>,
) => {
  const mutationOptions: UseMutationOptions<
    UpdateBillingInformationResponse,
    UpdateBillingInformationError,
    Options<UpdateBillingInformationData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await updateBillingInformation({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getCouponCodeQueryKey = (options: Options<GetCouponCodeData>) => [
  createQueryKey('getCouponCode', options),
];

export const getCouponCodeOptions = (options: Options<GetCouponCodeData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getCouponCode({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getCouponCodeQueryKey(options),
  });
};

export const deleteDefaultPaymentMethodMutation = (
  options?: Partial<Options<DeleteDefaultPaymentMethodData>>,
) => {
  const mutationOptions: UseMutationOptions<
    DeleteDefaultPaymentMethodResponse,
    DeleteDefaultPaymentMethodError,
    Options<DeleteDefaultPaymentMethodData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await deleteDefaultPaymentMethod({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getDefaultPaymentMethodQueryKey = (options?: Options<GetDefaultPaymentMethodData>) => [
  createQueryKey('getDefaultPaymentMethod', options),
];

export const getDefaultPaymentMethodOptions = (options?: Options<GetDefaultPaymentMethodData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getDefaultPaymentMethod({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getDefaultPaymentMethodQueryKey(options),
  });
};

export const startPaymentSessionQueryKey = (options: Options<StartPaymentSessionData>) => [
  createQueryKey('startPaymentSession', options),
];

export const startPaymentSessionOptions = (options: Options<StartPaymentSessionData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await startPaymentSession({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: startPaymentSessionQueryKey(options),
  });
};

export const startPaymentSessionMutation = (
  options?: Partial<Options<StartPaymentSessionData>>,
) => {
  const mutationOptions: UseMutationOptions<
    StartPaymentSessionResponse,
    StartPaymentSessionError,
    Options<StartPaymentSessionData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await startPaymentSession({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getSessionStatusQueryKey = (options: Options<GetSessionStatusData>) => [
  createQueryKey('getSessionStatus', options),
];

export const getSessionStatusOptions = (options: Options<GetSessionStatusData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getSessionStatus({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getSessionStatusQueryKey(options),
  });
};

export const forwardSessionToBankQueryKey = (options: Options<ForwardSessionToBankData>) => [
  createQueryKey('forwardSessionToBank', options),
];

export const forwardSessionToBankOptions = (options: Options<ForwardSessionToBankData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await forwardSessionToBank({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: forwardSessionToBankQueryKey(options),
  });
};

export const handleStripeWebhookQueryKey = (options: Options<HandleStripeWebhookData>) => [
  createQueryKey('handleStripeWebhook', options),
];

export const handleStripeWebhookOptions = (options: Options<HandleStripeWebhookData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await handleStripeWebhook({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: handleStripeWebhookQueryKey(options),
  });
};

export const handleStripeWebhookMutation = (
  options?: Partial<Options<HandleStripeWebhookData>>,
) => {
  const mutationOptions: UseMutationOptions<
    HandleStripeWebhookResponse,
    HandleStripeWebhookError,
    Options<HandleStripeWebhookData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await handleStripeWebhook({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const listProductsQueryKey = (options?: Options<ListProductsData>) => [
  createQueryKey('listProducts', options),
];

export const listProductsOptions = (options?: Options<ListProductsData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listProducts({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listProductsQueryKey(options),
  });
};

export const listSubscriptionsQueryKey = (options?: Options<ListSubscriptionsData>) => [
  createQueryKey('listSubscriptions', options),
];

export const listSubscriptionsOptions = (options?: Options<ListSubscriptionsData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listSubscriptions({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listSubscriptionsQueryKey(options),
  });
};

export const addSubscriptionQueryKey = (options: Options<AddSubscriptionData>) => [
  createQueryKey('addSubscription', options),
];

export const addSubscriptionOptions = (options: Options<AddSubscriptionData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await addSubscription({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: addSubscriptionQueryKey(options),
  });
};

export const addSubscriptionMutation = (options?: Partial<Options<AddSubscriptionData>>) => {
  const mutationOptions: UseMutationOptions<
    AddSubscriptionResponse,
    AddSubscriptionError,
    Options<AddSubscriptionData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await addSubscription({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const addAddOnQueryKey = (options: Options<AddAddOnData>) => [
  createQueryKey('addAddOn', options),
];

export const addAddOnOptions = (options: Options<AddAddOnData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await addAddOn({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: addAddOnQueryKey(options),
  });
};

export const addAddOnMutation = (options?: Partial<Options<AddAddOnData>>) => {
  const mutationOptions: UseMutationOptions<
    AddAddOnResponse,
    AddAddOnError,
    Options<AddAddOnData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await addAddOn({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const listSubscriptionInvoicesQueryKey = (
  options?: Options<ListSubscriptionInvoicesData>,
) => [createQueryKey('listSubscriptionInvoices', options)];

export const listSubscriptionInvoicesOptions = (
  options?: Options<ListSubscriptionInvoicesData>,
) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listSubscriptionInvoices({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listSubscriptionInvoicesQueryKey(options),
  });
};

export const getUpcomingQueryKey = (options?: Options<GetUpcomingData>) => [
  createQueryKey('getUpcoming', options),
];

export const getUpcomingOptions = (options?: Options<GetUpcomingData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getUpcoming({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getUpcomingQueryKey(options),
  });
};
