import { setLocale } from 'yup';

export default () => {
  setLocale({
    // use functions to generate an error object that includes the value from the schema
    array: {
      min: ({ min }) => ({ key: 'arrayMinLength', values: { min } }),
      max: ({ max }) => ({ key: 'arrayMaxLength', values: { max } }),
    },
    string: {
      required: () => ({ key: 'required' }),
      email: () => ({ key: 'validEmail' }),
      url: () => ({ key: 'validUrl' }),
      matches: ({ regex }) => ({ key: 'invalidFormat', values: { regex } }),
    },
    object: {
      required: () => ({ key: 'required' }),
    },
    mixed: {
      required: () => ({ key: 'required' }),
    },
  });
};
