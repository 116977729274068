import SvgIcon from '../SvgIcon';

export const DEFAULT_ALERT_ICON_MAPPING = {
  secondary: (
    <SvgIcon>
      <path d="M21.69 12A9.69 9.69 0 112.3 12a9.69 9.69 0 0119.38 0zm-9.43-6.48c-2.13 0-3.49.9-4.55 2.49-.14.2-.1.48.1.63l1.36 1.03c.2.15.5.12.65-.08.7-.89 1.18-1.4 2.24-1.4.8 0 1.78.51 1.78 1.29 0 .58-.48.88-1.27 1.32-.92.52-2.13 1.16-2.13 2.76v.16c0 .26.2.47.47.47h2.18c.26 0 .47-.21.47-.47v-.05c0-1.12 3.25-1.16 3.25-4.17 0-2.27-2.35-3.98-4.55-3.98zM12 15.2a1.8 1.8 0 100 3.6 1.8 1.8 0 000-3.6z" />
    </SvgIcon>
  ),
  info: (
    <SvgIcon>
      <path d="M12 2.31a9.69 9.69 0 100 19.38 9.69 9.69 0 000-19.38zm0 4.3a1.64 1.64 0 110 3.28 1.64 1.64 0 010-3.28zm2.19 9.92c0 .26-.21.47-.47.47h-3.44a.47.47 0 01-.47-.47v-.94c0-.26.21-.46.47-.46h.47v-2.5h-.47a.47.47 0 01-.47-.47v-.94c0-.26.21-.47.47-.47h2.5c.26 0 .47.21.47.47v3.9h.47c.26 0 .47.21.47.47v.94z" />
    </SvgIcon>
  ),
  success: (
    <SvgIcon>
      <path d="M21.69 12A9.69 9.69 0 112.3 12a9.69 9.69 0 0119.38 0zm-10.81 5.13l7.19-7.19a.63.63 0 000-.88l-.89-.89a.62.62 0 00-.88 0l-5.86 5.87L7.7 11.3a.62.62 0 00-.88 0l-.89.88a.63.63 0 000 .89L10 17.13c.24.24.64.24.88 0z" />
    </SvgIcon>
  ),
  warning: (
    <SvgIcon>
      <path d="M21.77 18.39a1.67 1.67 0 01-1.44 2.5H3.67a1.67 1.67 0 01-1.45-2.5l8.34-14.45a1.67 1.67 0 012.88 0l8.33 14.45zM12 15.4a1.6 1.6 0 100 3.2 1.6 1.6 0 000-3.2zm-1.52-5.74l.26 4.72c.01.22.2.4.42.4h1.68c.22 0 .4-.18.42-.4l.26-4.72a.42.42 0 00-.42-.44h-2.2c-.24 0-.43.2-.42.44z" />
    </SvgIcon>
  ),
  error: (
    <SvgIcon>
      <path d="M12 2.313A9.686 9.686 0 002.312 12 9.686 9.686 0 0012 21.688 9.686 9.686 0 0021.688 12 9.686 9.686 0 0012 2.312zm4.75 12.23a.47.47 0 010 .664l-1.547 1.543a.47.47 0 01-.664 0L12 14.187 9.457 16.75a.47.47 0 01-.664 0L7.25 15.203a.47.47 0 010-.664L9.813 12 7.25 9.457a.47.47 0 010-.664l1.547-1.547a.47.47 0 01.664 0L12 9.812l2.543-2.562a.47.47 0 01.664 0l1.547 1.547a.47.47 0 010 .664L14.187 12l2.563 2.543z" />
    </SvgIcon>
  ),
};
