import { Box } from '@afosto/components';
import styled from '@emotion/styled';

export const IconWrapper = styled(Box)`
  margin-top: 4px;
  margin-right: 20px;
  font-size: 18px;

  &.toast-default {
    color: ${props => props.theme.palette.common.white};
  }

  &.toast-success {
    color: ${props => props.theme.palette.secondary[400]};
  }

  &.toast-error {
    color: ${props => props.theme.palette.error[400]};
  }

  &.toast-warning {
    color: ${props => props.theme.palette.warning[400]};
  }

  &.toast-info {
    color: ${props => props.theme.palette.info[400]};
  }
`;
