import React, { Suspense } from 'react';
import { App as BaseApp, SplashScreen } from '@afosto/shared-components';
import { APP_SCOPES, CLIENT_ID } from '../constants';

const isRunningFromFrame = window.self !== window.top;
const LazyRoutes = !isRunningFromFrame
  ? React.lazy(() => import('./components/AppRoutes'))
  : () => <div>frame</div>;

function App() {
  return (
    <BaseApp clientId={CLIENT_ID} scopes={APP_SCOPES}>
      <Suspense fallback={<SplashScreen />}>
        <LazyRoutes />
      </Suspense>
    </BaseApp>
  );
}

export default App;
