import PropTypes from 'prop-types';

const Logo = ({ dark, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 76 24" {...props}>
    <path
      fill={dark ? '#042739' : '#fff'}
      d="M72.48 5.61H69.5c-1.1 0-1.9.24-2.38.71-.47.47-.71 1.16-.71 2.06v6.8c0 .9.24 1.6.71 2.07.48.47 1.27.7 2.38.7h3c1.1 0 1.89-.23 2.37-.7.48-.48.71-1.16.71-2.06V8.38c0-.9-.23-1.59-.71-2.06s-1.27-.71-2.38-.71zm-.35 9.08c0 .41-.23.62-.68.62h-.93c-.45 0-.68-.2-.68-.62V8.88c0-.42.23-.62.68-.62h.93c.46 0 .68.2.68.62v5.8zM63.84 4.05a.8.8 0 00-.8-.8h-2.71v2.36H59.1v2.63h1.23v6.94c0 1.85 1.06 2.78 3.18 2.78h1.97V15.3h-.96c-.45 0-.68-.2-.68-.6V8.24h1.64V5.61h-1.64V4.05zM55.2 10.67h-1.44c-.25 0-.43-.04-.53-.14-.1-.1-.15-.24-.15-.46v-1.5c0-.2.05-.36.15-.45.1-.1.28-.14.53-.14h.38c.25 0 .42.04.53.14.1.1.15.24.15.46v.9h3.36v-1.1c0-1.85-1.06-2.77-3.19-2.77h-2.13c-2.13 0-3.2.92-3.2 2.77v1.87c0 1.83 1.05 2.74 3.14 2.74h1.44c.25 0 .43.05.53.14.1.1.15.25.15.46V15c0 .42-.23.62-.68.62h-.43c-.44 0-.65-.2-.65-.62v-.85h-3.42v1.05c0 1.84 1.05 2.77 3.17 2.77h2.23c2.13 0 3.2-.93 3.2-2.77v-1.8c0-1.81-1.05-2.72-3.15-2.72zM45.33 5.61h-3c-1.1 0-1.9.24-2.37.71-.48.47-.72 1.16-.72 2.06v6.8c0 .9.24 1.6.72 2.07.48.47 1.27.7 2.38.7h2.99c1.1 0 1.9-.23 2.37-.7.48-.48.72-1.16.72-2.06V8.38c0-.9-.24-1.59-.72-2.06-.47-.47-1.27-.71-2.37-.71zm-.35 9.08c0 .41-.23.62-.68.62h-.93c-.46 0-.68-.2-.68-.62V8.88c0-.42.22-.62.68-.62h.93c.45 0 .68.2.68.62v5.8zM37.37 3.85h1.23V1.2h-2.24c-2.12 0-3.18.92-3.19 2.76V5.6h-1.04v2.65h1.05v9a.7.7 0 00.7.7h2.81v-9.7H38V5.6h-1.32V4.47c0-.41.23-.62.69-.62zM30.61 14.83V5.61h-2.92a.53.53 0 00-.53.53v1.12c-.28-.98-.91-1.65-2.03-1.65h-.6c-1.97 0-2.95.92-2.95 2.77v6.8c0 1.85.98 2.78 2.94 2.78h.6c1.08 0 1.65-.5 2-1.32v.8a.52.52 0 00.52.52h4.02V15.3h-.38c-.4 0-.62-.16-.67-.48zm-3.44-3.85v3.71c0 .41-.21.62-.64.62h-.88c-.43 0-.64-.2-.64-.62V8.88c0-.42.21-.62.64-.62h.88c.43 0 .64.2.64.62v2.1z"
    />
    <path
      fill="#0AFF91"
      d="M16.8 15.36a2.6 2.6 0 01-2.58 2.6H7.4c-.6 0-1.11-.5-1.1-1.1 0-.13.03-.27.08-.4l.18-.28.91-1.44h4.23v-.01a.79.79 0 00.6-1.11l-.02-.02c-.02-.05-.06-.1-.1-.15l-1.44-2.17a1.56 1.56 0 01-.02-1.7l1.34-2.13 4.26 6.4.08.12c.26.42.4.9.4 1.39zM10.62 5.28a1.64 1.64 0 01.02 1.79L9.33 9.15l-5.55 8.8H0L7.39 6.25l1.88-2.99 1.35 2.03z"
    />
  </svg>
);

Logo.propTypes = {
  dark: PropTypes.bool,
};

Logo.defaultProps = {
  dark: false,
};

export default Logo;
