import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '../AuthenticationProvider';
import SplashScreen from '../SplashScreen';

const AppSplashScreen = ({ children, isLoading }) => {
  const { isAuthenticated, isAuthorizing } = useAuthentication();
  const location = useLocation();
  const isValidRoute = !['/auth/callback', '/auth/authorize'].includes(location?.pathname);
  const isRunningAuthentication = !isAuthenticated && isAuthorizing && isValidRoute;

  if (isLoading || isRunningAuthentication) {
    return <SplashScreen />;
  }

  return children;
};

AppSplashScreen.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
};

AppSplashScreen.defaultProps = {
  isLoading: false,
};

export default AppSplashScreen;
