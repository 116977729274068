import type { Options } from '@afosto/client-fetch';
import { queryOptions, type UseMutationOptions } from '@tanstack/react-query';
import type {
  GetListNavigationData,
  ListJobsData,
  CreateJobData,
  CreateJobError,
  CreateJobResponse,
  GetJobData,
  UpdateJobStatusData,
  UpdateJobStatusError,
  UpdateJobStatusResponse,
  ListNotificationsData,
  MarkNotificationsData,
  MarkNotificationsError,
  MarkNotificationsResponse,
  GetNotificationData,
  ListProjectListsData,
  CreateProjectListData,
  CreateProjectListError,
  CreateProjectListResponse,
  DeleteProjectListData,
  DeleteProjectListError,
  DeleteProjectListResponse,
  GetProjectListData,
  UpdateProjectListData,
  UpdateProjectListError,
  UpdateProjectListResponse,
  ListTasksData,
  CreateProjectTaskData,
  CreateProjectTaskError,
  CreateProjectTaskResponse,
  DeleteProjectTaskData,
  DeleteProjectTaskError,
  DeleteProjectTaskResponse,
  GetProjectTaskData,
  UpdateProjectTaskData,
  UpdateProjectTaskError,
  UpdateProjectTaskResponse,
  GetProjectTaskCommentsData,
  CreateProjectCommentData,
  CreateProjectCommentError,
  CreateProjectCommentResponse,
  DeleteProjectCommentData,
  DeleteProjectCommentError,
  DeleteProjectCommentResponse,
  GetProjectCommentData,
  UpdateProjectCommentData,
  UpdateProjectCommentError,
  UpdateProjectCommentResponse,
  GetProjectTaskAttachmentsData,
  CreateAttachmentData,
  CreateAttachmentError,
  CreateAttachmentResponse,
  DeleteProjectAttachmentData,
  DeleteProjectAttachmentError,
  DeleteProjectAttachmentResponse,
  GetAttachmentData,
  ReceivePusherWebhooksData,
  ReceivePusherWebhooksError,
  ReceivePusherWebhooksResponse,
  GetPusherChannelStateData,
} from '../types';
import {
  client,
  getListNavigation,
  listJobs,
  createJob,
  getJob,
  updateJobStatus,
  listNotifications,
  markNotifications,
  getNotification,
  listProjectLists,
  createProjectList,
  deleteProjectList,
  getProjectList,
  updateProjectList,
  listTasks,
  createProjectTask,
  deleteProjectTask,
  getProjectTask,
  updateProjectTask,
  getProjectTaskComments,
  createProjectComment,
  deleteProjectComment,
  getProjectComment,
  updateProjectComment,
  getProjectTaskAttachments,
  createAttachment,
  deleteProjectAttachment,
  getAttachment,
  receivePusherWebhooks,
  getPusherChannelState,
} from '../api';

type QueryKey<TOptions extends Options> = [
  Pick<TOptions, 'baseUrl' | 'body' | 'headers' | 'path' | 'query'> & {
    _id: string;
    _infinite?: boolean;
  },
];

const createQueryKey = <TOptions extends Options>(
  id: string,
  options?: TOptions,
  infinite?: boolean,
): QueryKey<TOptions>[0] => {
  const params: QueryKey<TOptions>[0] = {
    _id: id,
    baseUrl: (options?.client ?? client).getConfig().baseUrl,
  } as QueryKey<TOptions>[0];
  if (infinite) {
    params._infinite = infinite;
  }
  if (options?.body) {
    params.body = options.body;
  }
  if (options?.headers) {
    params.headers = options.headers;
  }
  if (options?.path) {
    params.path = options.path;
  }
  if (options?.query) {
    params.query = options.query;
  }
  return params;
};

export const getListNavigationQueryKey = (options?: Options<GetListNavigationData>) => [
  createQueryKey('getListNavigation', options),
];

export const getListNavigationOptions = (options?: Options<GetListNavigationData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getListNavigation({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getListNavigationQueryKey(options),
  });
};

export const listJobsQueryKey = (options?: Options<ListJobsData>) => [
  createQueryKey('listJobs', options),
];

export const listJobsOptions = (options?: Options<ListJobsData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listJobs({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listJobsQueryKey(options),
  });
};

export const createJobQueryKey = (options: Options<CreateJobData>) => [
  createQueryKey('createJob', options),
];

export const createJobOptions = (options: Options<CreateJobData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await createJob({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: createJobQueryKey(options),
  });
};

export const createJobMutation = (options?: Partial<Options<CreateJobData>>) => {
  const mutationOptions: UseMutationOptions<
    CreateJobResponse,
    CreateJobError,
    Options<CreateJobData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await createJob({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getJobQueryKey = (options: Options<GetJobData>) => [createQueryKey('getJob', options)];

export const getJobOptions = (options: Options<GetJobData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getJob({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getJobQueryKey(options),
  });
};

export const updateJobStatusMutation = (options?: Partial<Options<UpdateJobStatusData>>) => {
  const mutationOptions: UseMutationOptions<
    UpdateJobStatusResponse,
    UpdateJobStatusError,
    Options<UpdateJobStatusData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await updateJobStatus({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const listNotificationsQueryKey = (options?: Options<ListNotificationsData>) => [
  createQueryKey('listNotifications', options),
];

export const listNotificationsOptions = (options?: Options<ListNotificationsData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listNotifications({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listNotificationsQueryKey(options),
  });
};

export const markNotificationsMutation = (options?: Partial<Options<MarkNotificationsData>>) => {
  const mutationOptions: UseMutationOptions<
    MarkNotificationsResponse,
    MarkNotificationsError,
    Options<MarkNotificationsData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await markNotifications({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getNotificationQueryKey = (options: Options<GetNotificationData>) => [
  createQueryKey('getNotification', options),
];

export const getNotificationOptions = (options: Options<GetNotificationData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getNotification({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getNotificationQueryKey(options),
  });
};

export const listProjectListsQueryKey = (options?: Options<ListProjectListsData>) => [
  createQueryKey('listProjectLists', options),
];

export const listProjectListsOptions = (options?: Options<ListProjectListsData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listProjectLists({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listProjectListsQueryKey(options),
  });
};

export const createProjectListQueryKey = (options: Options<CreateProjectListData>) => [
  createQueryKey('createProjectList', options),
];

export const createProjectListOptions = (options: Options<CreateProjectListData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await createProjectList({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: createProjectListQueryKey(options),
  });
};

export const createProjectListMutation = (options?: Partial<Options<CreateProjectListData>>) => {
  const mutationOptions: UseMutationOptions<
    CreateProjectListResponse,
    CreateProjectListError,
    Options<CreateProjectListData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await createProjectList({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const deleteProjectListMutation = (options?: Partial<Options<DeleteProjectListData>>) => {
  const mutationOptions: UseMutationOptions<
    DeleteProjectListResponse,
    DeleteProjectListError,
    Options<DeleteProjectListData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await deleteProjectList({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getProjectListQueryKey = (options: Options<GetProjectListData>) => [
  createQueryKey('getProjectList', options),
];

export const getProjectListOptions = (options: Options<GetProjectListData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getProjectList({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getProjectListQueryKey(options),
  });
};

export const updateProjectListMutation = (options?: Partial<Options<UpdateProjectListData>>) => {
  const mutationOptions: UseMutationOptions<
    UpdateProjectListResponse,
    UpdateProjectListError,
    Options<UpdateProjectListData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await updateProjectList({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const listTasksQueryKey = (options?: Options<ListTasksData>) => [
  createQueryKey('listTasks', options),
];

export const listTasksOptions = (options?: Options<ListTasksData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await listTasks({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: listTasksQueryKey(options),
  });
};

export const createProjectTaskQueryKey = (options: Options<CreateProjectTaskData>) => [
  createQueryKey('createProjectTask', options),
];

export const createProjectTaskOptions = (options: Options<CreateProjectTaskData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await createProjectTask({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: createProjectTaskQueryKey(options),
  });
};

export const createProjectTaskMutation = (options?: Partial<Options<CreateProjectTaskData>>) => {
  const mutationOptions: UseMutationOptions<
    CreateProjectTaskResponse,
    CreateProjectTaskError,
    Options<CreateProjectTaskData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await createProjectTask({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const deleteProjectTaskMutation = (options?: Partial<Options<DeleteProjectTaskData>>) => {
  const mutationOptions: UseMutationOptions<
    DeleteProjectTaskResponse,
    DeleteProjectTaskError,
    Options<DeleteProjectTaskData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await deleteProjectTask({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getProjectTaskQueryKey = (options: Options<GetProjectTaskData>) => [
  createQueryKey('getProjectTask', options),
];

export const getProjectTaskOptions = (options: Options<GetProjectTaskData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getProjectTask({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getProjectTaskQueryKey(options),
  });
};

export const updateProjectTaskMutation = (options?: Partial<Options<UpdateProjectTaskData>>) => {
  const mutationOptions: UseMutationOptions<
    UpdateProjectTaskResponse,
    UpdateProjectTaskError,
    Options<UpdateProjectTaskData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await updateProjectTask({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getProjectTaskCommentsQueryKey = (options?: Options<GetProjectTaskCommentsData>) => [
  createQueryKey('getProjectTaskComments', options),
];

export const getProjectTaskCommentsOptions = (options?: Options<GetProjectTaskCommentsData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getProjectTaskComments({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getProjectTaskCommentsQueryKey(options),
  });
};

export const createProjectCommentQueryKey = (options: Options<CreateProjectCommentData>) => [
  createQueryKey('createProjectComment', options),
];

export const createProjectCommentOptions = (options: Options<CreateProjectCommentData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await createProjectComment({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: createProjectCommentQueryKey(options),
  });
};

export const createProjectCommentMutation = (
  options?: Partial<Options<CreateProjectCommentData>>,
) => {
  const mutationOptions: UseMutationOptions<
    CreateProjectCommentResponse,
    CreateProjectCommentError,
    Options<CreateProjectCommentData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await createProjectComment({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const deleteProjectCommentMutation = (
  options?: Partial<Options<DeleteProjectCommentData>>,
) => {
  const mutationOptions: UseMutationOptions<
    DeleteProjectCommentResponse,
    DeleteProjectCommentError,
    Options<DeleteProjectCommentData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await deleteProjectComment({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getProjectCommentQueryKey = (options: Options<GetProjectCommentData>) => [
  createQueryKey('getProjectComment', options),
];

export const getProjectCommentOptions = (options: Options<GetProjectCommentData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getProjectComment({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getProjectCommentQueryKey(options),
  });
};

export const updateProjectCommentMutation = (
  options?: Partial<Options<UpdateProjectCommentData>>,
) => {
  const mutationOptions: UseMutationOptions<
    UpdateProjectCommentResponse,
    UpdateProjectCommentError,
    Options<UpdateProjectCommentData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await updateProjectComment({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getProjectTaskAttachmentsQueryKey = (
  options?: Options<GetProjectTaskAttachmentsData>,
) => [createQueryKey('getProjectTaskAttachments', options)];

export const getProjectTaskAttachmentsOptions = (
  options?: Options<GetProjectTaskAttachmentsData>,
) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getProjectTaskAttachments({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getProjectTaskAttachmentsQueryKey(options),
  });
};

export const createAttachmentQueryKey = (options: Options<CreateAttachmentData>) => [
  createQueryKey('createAttachment', options),
];

export const createAttachmentOptions = (options: Options<CreateAttachmentData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await createAttachment({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: createAttachmentQueryKey(options),
  });
};

export const createAttachmentMutation = (options?: Partial<Options<CreateAttachmentData>>) => {
  const mutationOptions: UseMutationOptions<
    CreateAttachmentResponse,
    CreateAttachmentError,
    Options<CreateAttachmentData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await createAttachment({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const deleteProjectAttachmentMutation = (
  options?: Partial<Options<DeleteProjectAttachmentData>>,
) => {
  const mutationOptions: UseMutationOptions<
    DeleteProjectAttachmentResponse,
    DeleteProjectAttachmentError,
    Options<DeleteProjectAttachmentData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await deleteProjectAttachment({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getAttachmentQueryKey = (options: Options<GetAttachmentData>) => [
  createQueryKey('getAttachment', options),
];

export const getAttachmentOptions = (options: Options<GetAttachmentData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getAttachment({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getAttachmentQueryKey(options),
  });
};

export const receivePusherWebhooksQueryKey = (options: Options<ReceivePusherWebhooksData>) => [
  createQueryKey('receivePusherWebhooks', options),
];

export const receivePusherWebhooksOptions = (options: Options<ReceivePusherWebhooksData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await receivePusherWebhooks({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: receivePusherWebhooksQueryKey(options),
  });
};

export const receivePusherWebhooksMutation = (
  options?: Partial<Options<ReceivePusherWebhooksData>>,
) => {
  const mutationOptions: UseMutationOptions<
    ReceivePusherWebhooksResponse,
    ReceivePusherWebhooksError,
    Options<ReceivePusherWebhooksData>
  > = {
    mutationFn: async localOptions => {
      const { data } = await receivePusherWebhooks({
        ...options,
        ...localOptions,
        throwOnError: true,
      });
      return data;
    },
  };
  return mutationOptions;
};

export const getPusherChannelStateQueryKey = (options: Options<GetPusherChannelStateData>) => [
  createQueryKey('getPusherChannelState', options),
];

export const getPusherChannelStateOptions = (options: Options<GetPusherChannelStateData>) => {
  return queryOptions({
    queryFn: async ({ queryKey, signal }) => {
      const { data } = await getPusherChannelState({
        ...options,
        ...queryKey[0],
        signal,
        throwOnError: true,
      });
      return data;
    },
    queryKey: getPusherChannelStateQueryKey(options),
  });
};
