import { theme } from '@afosto/components';
import { createTheme } from '@afosto/components/ThemeProvider';
// import Link from './Link';

const sharedTheme = createTheme(theme, {
  // components: {
  //   MuiLink: {
  //     defaultProps: {
  //       component: Link,
  //     },
  //   },
  //   MuiButtonBase: {
  //     defaultProps: {
  //       LinkComponent: Link,
  //     },
  //   },
  // },
});

export default sharedTheme;
