import { createUrlWithParams } from './createUrlWithParams';
import { parseUrlQuery } from './parseUrlQuery';

export const getUrlWithPreservedQueryParams = (url: string, preservedKeys: string[] = []) => {
  const [urlPath] = url.split('?');
  const pathQueryParams = parseUrlQuery(url);
  const urlParams = parseUrlQuery(window.location.search);
  const preservedParams = Object.keys(urlParams)
    .filter(key => preservedKeys.includes(key))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: urlParams[key as keyof typeof urlParams],
      }),
      {},
    );
  return createUrlWithParams(urlPath, { ...pathQueryParams, ...preservedParams });
};

export default getUrlWithPreservedQueryParams;
