// TODO: Fix error type
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import * as Sentry from '@sentry/react';
import type { ClientError } from '@afosto/graphql-client';

export const getErrorMessage = (error: Error | ClientError | unknown, options = {}) => {
  const errorOptions = {
    skipSentry: false,
    ...(options || {}),
  };
  const fetchError = error?.error || {};
  const errorResponse = error?.response || {};
  const errorResponseData = errorResponse?.data || {};
  const errorResponseError = errorResponseData?.error || {};
  const gqlResponseErrors = errorResponse?.errors || [];
  const [firstGqlError] = gqlResponseErrors || [];
  const gqlErrorExtensions = firstGqlError?.extensions || {};
  const pointers = errorResponseError?.details?.pointers || firstGqlError?.extensions?.pointers;
  const [firstPointer] = pointers || [];
  const errorMessage =
    fetchError?.message ||
    firstPointer?.message ||
    errorResponseError?.message ||
    errorResponseError?.details?.reference ||
    errorResponseData?.message ||
    firstGqlError?.message ||
    gqlErrorExtensions?.reference ||
    error?.message;

  if (!errorOptions?.skipSentry) {
    try {
      if (error) {
        Sentry.withScope(scope => {
          scope.setExtra('message', errorMessage || '');

          if (errorMessage) {
            scope.setFingerprint([errorMessage]);
          }

          Sentry.captureException(error);
        });
      }
    } catch {
      // Do nothing.
    }
  }

  return errorMessage;
};

export default getErrorMessage;
