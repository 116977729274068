import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { client as tsApiClient } from '@afosto/client-fetch';
import { useGetLatest } from '@afosto/hooks';
import {
  backup2Fa as backup2faRequest,
  createTenant as createTenantRequest,
  getRedirect as getRedirectRequest,
  getSession as getSessionRequest,
  getSessionSubtenants as getSessionSubtenantsRequest,
  getTenant as getTenantRequest,
  joinTenant as joinTenantRequest,
  loginUser as loginUserRequest,
  requestToken as requestTokenRequest,
  requestVerify as requestVerifyRequest,
  resetPassword as resetPasswordRequest,
  userLogout as userLogoutRequest,
  verify2Fa as verify2faRequest,
  verifyUser as verifyUserRequest,
} from '@afosto/iam-service/api';
import type {
  AuthorizationResponse,
  Backup2FaData,
  CreateTenantData,
  GetRedirectData,
  JoinTenantData,
  LoginUserData,
  RequestTokenData,
  ResetPasswordData,
  Tenant,
  TwoFactor,
  Verify2FaData,
  VerifyUserData,
} from '@afosto/iam-service/types';
import {
  addQueryParamsToUrl,
  createUrlWithParams,
  gravatarUrl,
  parseUrlQuery,
} from '@afosto/utils';
import {
  AUTHENTICATION_DEFAULT_SCOPES,
  AUTHENTICATION_ERROR_CODES,
  AUTHENTICATION_ROUTES_MAPPING,
} from '../constants';
import type {
  AuthenticationAccessTokenData,
  AuthenticationIdTokenData,
  AuthenticationMessageEvent,
  AuthenticationNavigateHandler,
  AuthenticationProviderOptions,
  AuthenticationProviderProps,
  AuthenticationRoutesMapping,
  AuthenticationSession,
  AuthenticationSessionClearedHandler,
  AuthenticationSessionCreatedHandler,
  AuthenticationSessionError,
  AuthenticationSessionResponseData,
  AuthenticationUser,
  AuthenticationUserChangedHandler,
} from './types';

export const useAuthenticationProvider = (options: AuthenticationProviderOptions) => {
  const {
    baseUri = 'https://afosto.app',
    clientId,
    disableAuthentication = false,
    disableApiInterceptor = false,
    onNavigate,
    onSessionCleared,
    onSessionCreated,
    onUserChanged,
    redirectUri: providedRedirectUri,
    responseType = 'token id_token',
    routesMapping: providedRoutesMapping,
    scopes = [],
  } = options || {};

  const [authorization, setAuthorization] = useState<AuthorizationResponse | null>(null);
  const [authorizationParams, setAuthorizationParams] = useState<GetRedirectData['query'] | null>(
    null,
  );
  const [disableReferrer, setDisableReferrer] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthorizing, setIsAuthorizing] = useState(!disableAuthentication);
  const [isForwardingAuthorization, setIsForwardingAuthorization] = useState(false);
  const [isLoadingTenant, setIsLoadingTenant] = useState(false);
  const [session, setSession] = useState<AuthenticationSession | null>(null);
  const [sessionError, setSessionError] = useState<AuthenticationSessionError | null>(null);
  const [tenant, setTenant] = useState<Tenant | null>(null);
  const [tokenRefreshTimer, setTokenRefreshTimer] = useState<ReturnType<typeof setTimeout> | null>(
    null,
  );
  const [twoFactor, setTwoFactor] = useState<TwoFactor | null>(null);
  const [user, setUser] = useState<AuthenticationUser | null>(null);

  const routeMapping = {
    ...AUTHENTICATION_ROUTES_MAPPING,
    ...(providedRoutesMapping || {}),
  } as AuthenticationRoutesMapping;
  const redirectUri = providedRedirectUri
    ? providedRedirectUri
    : `${window.location.origin}${routeMapping.callback}`;

  const getLatestOnSessionCleared = useGetLatest(onSessionCleared);
  const getLatestOnSessionCreated = useGetLatest(onSessionCreated);
  const getLatestOnNavigate = useGetLatest(onNavigate);
  const getLatestOnUserChanged = useGetLatest(onUserChanged);
  const getLatestRouteMapping = useGetLatest(routeMapping);
  const getLatestSession = useGetLatest(session);
  const getLatestSessionError = useGetLatest(sessionError);
  const getLatestTenant = useGetLatest(tenant);
  const getLatestTokenRefreshTimer = useGetLatest(tokenRefreshTimer);

  const defaultAuthorizationParams = useMemo(
    () => ({
      clientId,
      redirectUri,
      responseType,
      scope: [...AUTHENTICATION_DEFAULT_SCOPES, ...scopes].join(' '),
    }),
    [clientId, redirectUri, responseType, scopes],
  );

  const isRunningSilent = window.self !== window.top;
  const scopesString = scopes.join(' ');

  const setSessionTenant = useCallback(async (id: string) => {
    try {
      setIsLoadingTenant(true);

      const response = await getTenantRequest({
        path: {
          id,
        },
      });
      const tenantData = (response?.data?.data || {}) as Tenant;
      const sessionTenant = tenantData?.id ? tenantData : null;

      setTenant(sessionTenant);

      setIsLoadingTenant(false);
    } catch {
      setIsLoadingTenant(false);
    }
  }, []);

  const preserveTenantInUrl = useCallback(
    (id: string) => {
      const { pathname, search } = window.location || {};
      const { t: tenantId } = parseUrlQuery(search || '') as { t?: string };

      if (id === tenantId) {
        return;
      }

      const newPath = addQueryParamsToUrl(`${pathname}${search}`, { t: id });
      const navigate = (getLatestOnNavigate() || {}) as AuthenticationNavigateHandler;

      navigate(newPath, { replace: true });
    },
    [getLatestOnNavigate],
  );

  const clearSession = useCallback(() => {
    setSession(null);
    setSessionError(null);
    setTenant(null);
    setUser(null);
    setIsAuthenticated(false);

    const onSessionClearedHandler = (getLatestOnSessionCleared() ||
      {}) as AuthenticationSessionClearedHandler;

    if (onSessionClearedHandler && typeof onSessionClearedHandler === 'function') {
      onSessionClearedHandler();
    }
  }, [getLatestOnSessionCleared]);

  const createSession = useCallback(
    (sessionData: AuthenticationSessionResponseData) => {
      const {
        access_token: accessToken,
        id_token: idToken,
        expires_in: expiresIn,
      } = sessionData || {};
      const {
        roles,
        scopes: tokenScopes,
        session: sessionId,
        tenant: tokenTenantId,
      } = jwtDecode(accessToken) as AuthenticationAccessTokenData;
      const {
        email,
        email_verified: emailVerified,
        family_name: familyName,
        given_name: givenName,
        name,
        picture,
        sub: id,
      } = jwtDecode(idToken) as AuthenticationIdTokenData;
      const userPicture =
        picture.indexOf('https://www.gravatar.com') > -1
          ? gravatarUrl(email, {
              size: 32,
              d: '404',
            })
          : picture;
      const createdSession = sessionData
        ? ({ accessToken, expiresIn, idToken, sessionId } as AuthenticationSession)
        : null;

      tsApiClient.setConfig({
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const onSessionCreatedHandler = (getLatestOnSessionCreated() ||
        {}) as AuthenticationSessionCreatedHandler;

      if (onSessionCreatedHandler && typeof onSessionCreatedHandler === 'function') {
        onSessionCreatedHandler(createdSession);
      }

      setSessionError(null);
      setSession(createdSession);
      setIsAuthenticated(true);

      setUser({
        id,
        email,
        emailVerified,
        familyName,
        givenName,
        name,
        roles,
        scopes: tokenScopes,
        picture: userPicture,
      });

      setSessionTenant(tokenTenantId).catch(() => {
        // Do nothing.
      });

      if (tokenTenantId) {
        preserveTenantInUrl(tokenTenantId);
      }

      setIsAuthorizing(false);
    },
    [getLatestOnSessionCreated, preserveTenantInUrl, setSessionTenant],
  );

  const getActiveSession = useCallback(async () => {
    const response = await getSessionRequest({ credentials: 'include' });
    return response?.data?.data;
  }, []);

  const getSessionSubtenants = useCallback(async (query = {}) => {
    const response = await getSessionSubtenantsRequest({ query, credentials: 'include' });
    return response.data;
  }, []);

  const getAuthorizationUri = useCallback(
    (silent?: boolean, providedTenantId?: string) => {
      const providedScopes = scopesString.split(' ');

      return createUrlWithParams(
        `${baseUri}/api/oauth/session/authorize`,
        {
          client_id: clientId,
          redirect_uri: encodeURIComponent(redirectUri),
          response_type: responseType,
          scope: [...AUTHENTICATION_DEFAULT_SCOPES, ...providedScopes].join(' '),
          ...(silent === true ? { silent: 1 } : {}),
          ...(providedTenantId ? { tenant: providedTenantId } : {}),
        },
        { encode: false },
      );
    },
    [baseUri, clientId, redirectUri, responseType, scopesString],
  );

  const authorize = useCallback(
    async (params: GetRedirectData['query']) => {
      const { pathname } = window.location || {};
      const navigate = (getLatestOnNavigate() || {}) as AuthenticationNavigateHandler;
      const { authorize: authorizeRoute } =
        getLatestRouteMapping() || ({} as AuthenticationRoutesMapping);
      const response = await getRedirectRequest({ query: params, credentials: 'include' });
      const { forwardUri, isAuthorizationApproved } = response.data?.data ?? {};

      if (!isAuthorizationApproved) {
        setAuthorization(response.data?.data || null);

        if (navigate && typeof navigate === 'function' && pathname !== authorizeRoute) {
          navigate(authorizeRoute);
        }

        return false;
      }

      if (forwardUri) {
        setIsForwardingAuthorization(true);
        window.location.href = forwardUri;
      }
    },
    [getLatestOnNavigate, getLatestRouteMapping],
  );

  const silentAuthorization = useCallback(
    (providedTenantId?: string) =>
      new Promise((resolve, reject) => {
        setIsAuthorizing(true);

        const { id: tenantId } = (getLatestTenant() || {}) as { id?: string };

        const iframe = window.document.createElement('iframe');
        iframe.name = `${Date.now()}`;
        iframe.src = getAuthorizationUri(true, providedTenantId || tenantId);
        iframe.style.display = 'none';

        const removeIframe = () => {
          if (iframe.parentNode) {
            iframe.parentNode.removeChild(iframe);
          }
        };

        const cancelTimer = setTimeout(() => {
          removeIframe();
          setSessionError({
            error: 'Session invalid',
            code: AUTHENTICATION_ERROR_CODES.sessionInvalid,
          });
          setSession(null);
          setIsAuthorizing(false);
          reject(new Error('Timeout'));
        }, 60000);

        const messageHandler = (event: AuthenticationMessageEvent) => {
          if (event.type === 'message' && event.data?.type === 'AUTH_CALLBACK') {
            const { data } = event?.data ?? {};

            clearTimeout(cancelTimer);

            if (data && data.access_token) {
              window.removeEventListener('message', messageHandler);
              removeIframe();
              createSession(data as AuthenticationSessionResponseData);
              resolve(data);
            } else {
              if (data?.error) {
                const { error, code } = data || {};
                setSessionError({
                  error,
                  code: code ? `ATZ-IAM-${code.replace('ATZ-IAM-', '')}` : null,
                });
              } else {
                setSessionError({
                  error: 'Session invalid',
                  code: AUTHENTICATION_ERROR_CODES.sessionInvalid,
                });
                setSession(null);
              }

              removeIframe();
              setIsAuthorizing(false);
              reject(data?.error);
            }
          }
        };

        window.addEventListener('message', messageHandler);
        window.document.body.appendChild(iframe);
      }),
    [createSession, getAuthorizationUri, getLatestTenant],
  );

  const loginAuthorize = useCallback(
    async (params: GetRedirectData['query'], retry = false) => {
      const navigate = (getLatestOnNavigate() || {}) as AuthenticationNavigateHandler;
      const { error: errorRoute } = getLatestRouteMapping() || ({} as AuthenticationRoutesMapping);

      await authorize(params).catch(async error => {
        const errorResponse = error?.response || {};
        const errorResponseData = errorResponse?.data || {};
        const errorResponseError = errorResponseData?.error || {};
        const { code, details, message: errorMessage } = error?.error || {};
        const message = errorMessage || errorResponseError?.message || errorResponseData?.message;
        const { reference } = details || {};

        if (
          code === 403 &&
          params?.tenant &&
          reference === AUTHENTICATION_ERROR_CODES.noAccessToSubtenant &&
          !retry
        ) {
          await loginAuthorize(authorizationParams ?? defaultAuthorizationParams, true);
          return;
        }

        if (navigate && typeof navigate === 'function') {
          navigate(`${errorRoute}?error=${message || ''}&code=${reference || ''}`);
        }
      });
    },
    [
      authorize,
      authorizationParams,
      defaultAuthorizationParams,
      getLatestOnNavigate,
      getLatestRouteMapping,
    ],
  );

  const login = useCallback(
    async (payload: LoginUserData['body']['data']) => {
      const navigate = (getLatestOnNavigate() || {}) as AuthenticationNavigateHandler;
      const { twoFactor: twoFactorRoute } =
        getLatestRouteMapping() || ({} as AuthenticationRoutesMapping);

      const response = await loginUserRequest({
        body: {
          data: payload,
        },
        credentials: 'include',
      });
      const { state, twoFactor: twoFactorSettings } = response?.data?.data || {};

      if (twoFactorSettings) {
        setTwoFactor(twoFactorSettings);
      }

      if (state === '2FA_MISSING') {
        navigate(twoFactorRoute);
        return;
      }

      if (state === 'AUTHENTICATED') {
        const { t: tenantId } = parseUrlQuery(window.location.search || '') as { t?: string };
        const params = {
          ...(authorizationParams ?? defaultAuthorizationParams),
          ...(tenantId ? { tenant: tenantId } : {}),
        };
        await loginAuthorize(params);
        return;
      }

      throw new Error('Login failed');
    },
    [
      authorizationParams,
      defaultAuthorizationParams,
      getLatestOnNavigate,
      loginAuthorize,
      getLatestRouteMapping,
    ],
  );

  const logout = useCallback(async () => {
    await userLogoutRequest({
      credentials: 'include',
    });

    setDisableReferrer(true);
    clearSession();
  }, [clearSession]);

  const forgotPassword = useCallback(async (payload: RequestTokenData['body']['data']) => {
    const response = await requestTokenRequest({
      body: {
        data: payload,
      },
    });
    return response.data;
  }, []);

  const register = useCallback(async (payload: CreateTenantData['body']['data']) => {
    const response = await createTenantRequest({
      body: {
        data: payload,
      },
    });
    return response.data;
  }, []);

  const resetPassword = useCallback(async (payload: ResetPasswordData['body']['data']) => {
    const response = await resetPasswordRequest({
      body: {
        data: payload,
      },
    });
    return response.data;
  }, []);

  const acceptInvite = useCallback(async (payload: JoinTenantData['body']['data']) => {
    const response = await joinTenantRequest({
      body: {
        data: payload,
      },
    });
    return response.data;
  }, []);

  const verifyUser = useCallback(async (payload: VerifyUserData['body']['data']) => {
    const response = await verifyUserRequest({
      body: {
        data: payload,
      },
    });
    return response.data;
  }, []);

  const verifyTwoFactorToken = useCallback(async (payload: Verify2FaData['body']['data']) => {
    const response = await verify2faRequest({
      body: {
        data: payload,
      },
      credentials: 'include',
    });

    return response.data;
  }, []);

  const requestToken = useCallback(async (payload: RequestTokenData['body']['data']) => {
    const response = await requestTokenRequest({
      body: {
        data: payload,
      },
    });

    return response.data;
  }, []);

  const requestEmailVerification = useCallback(async () => {
    const response = await requestVerifyRequest({
      credentials: 'include',
    });
    return response.data;
  }, []);

  const requestTwoFactorBackupToken = useCallback(async (data: Backup2FaData['body']['data']) => {
    const response = await backup2faRequest({
      body: {
        data,
      },
      credentials: 'include',
    });
    return response.data;
  }, []);

  const switchTenant = useCallback(
    async (id: string) => {
      await authorize({
        ...(authorizationParams ?? defaultAuthorizationParams),
        tenant: id,
      });
    },
    [authorize, authorizationParams, defaultAuthorizationParams],
  );

  useEffect(() => {
    const { pathname, search } = window.location || {};
    const { t: tenantId } = parseUrlQuery(search || '') as { t?: string };
    const entryPointIsCallback = pathname === routeMapping.callback;
    const entryPointIsAuthorization = pathname === routeMapping.authorize;

    if (
      !isRunningSilent &&
      !disableAuthentication &&
      !entryPointIsCallback &&
      !entryPointIsAuthorization
    ) {
      silentAuthorization(tenantId).catch(() => {
        // Do nothing
      });
    }
  }, [disableAuthentication, isRunningSilent, silentAuthorization]);

  useEffect(() => {
    const refreshTimer = getLatestTokenRefreshTimer();

    if (!isRunningSilent && !disableAuthentication && refreshTimer) {
      clearTimeout(refreshTimer);
    }

    const visibilityHandler = () => {
      if (document.visibilityState === 'hidden' && refreshTimer) {
        clearTimeout(refreshTimer);
      }

      if (document.visibilityState === 'visible') {
        scheduleSilentAuthorization();
      }
    };

    const scheduleSilentAuthorization = () => {
      const { exp: tokenExpiration = 0 } = (
        session ? jwtDecode(session?.accessToken) : {}
      ) as AuthenticationAccessTokenData;
      const currentTime = Math.floor(new Date().getTime() / 1000);
      const expirationTime =
        tokenExpiration > currentTime ? (tokenExpiration - currentTime - 60) * 1000 : 0;

      if (expirationTime > 0) {
        setTokenRefreshTimer(
          setTimeout(
            () =>
              silentAuthorization().catch(() => {
                // Do nothing.
              }),
            expirationTime,
          ),
        );
      } else {
        silentAuthorization().catch(() => {
          // Do nothing
        });
      }
    };

    if (!isRunningSilent && !disableAuthentication && session?.accessToken) {
      scheduleSilentAuthorization();
      document.addEventListener('visibilitychange', visibilityHandler, false);
    }

    return () => {
      if (!isRunningSilent && !disableAuthentication && session?.accessToken) {
        document.removeEventListener('visibilitychange', visibilityHandler, false);
      }
    };
  }, [
    disableAuthentication,
    getLatestTokenRefreshTimer,
    isRunningSilent,
    session,
    silentAuthorization,
  ]);

  useEffect(() => {
    const fetchUnauthorizedHandler = async (response: Response) => {
      const latestSession = (getLatestSession() || {}) as AuthenticationSession;
      const latestSessionError = getLatestSessionError();

      if (response.status === 401 && latestSession?.accessToken && !latestSessionError) {
        await silentAuthorization().catch(() => {
          // Do nothing.
        });
      }

      return response;
    };

    if (!disableApiInterceptor) {
      tsApiClient.interceptors.response.use(fetchUnauthorizedHandler);
    }

    return () => {
      if (!disableApiInterceptor) {
        tsApiClient.interceptors.response.eject(fetchUnauthorizedHandler);
      }
    };
  }, [disableApiInterceptor, getLatestSession, getLatestSessionError, silentAuthorization]);

  useEffect(() => {
    const userChangedHandler = (getLatestOnUserChanged() || {}) as AuthenticationUserChangedHandler;

    if (
      !isRunningSilent &&
      user &&
      userChangedHandler &&
      typeof userChangedHandler === 'function'
    ) {
      userChangedHandler(user);
    }
  }, [getLatestOnUserChanged, isRunningSilent, user]);

  return {
    acceptInvite,
    authorization,
    authorizationParams,
    authorize,
    clearSession,
    createSession,
    defaultAuthorizationParams,
    disableReferrer,
    forgotPassword,
    getActiveSession,
    getSessionSubtenants,
    isAuthorizing,
    isAuthenticated,
    isForwardingAuthorization,
    isLoadingTenant,
    isRunningSilent,
    login,
    logout,
    onNavigate,
    register,
    resetPassword,
    requestEmailVerification,
    requestToken,
    requestTwoFactorBackupToken,
    session,
    sessionError,
    setAuthorizationParams,
    setDisableReferrer,
    setIsAuthorizing,
    setSessionError,
    setTwoFactor,
    setUser,
    silentAuthorization,
    switchTenant,
    tenant,
    tenantId: tenant?.id,
    twoFactor,
    user,
    verifyTwoFactorToken,
    verifyUser,
  };
};

export const AuthenticationContext = createContext(
  {} as ReturnType<typeof useAuthenticationProvider>,
);

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error('useAuthentication must be used within an AuthenticationProvider');
  }

  return context;
};

export const AuthenticationProvider = (props: AuthenticationProviderProps) => {
  const { children, ...otherProps } = props;

  const authenticationProviderState = useAuthenticationProvider(otherProps);

  return (
    <AuthenticationContext.Provider value={authenticationProviderState}>
      {children}
    </AuthenticationContext.Provider>
  );
};
