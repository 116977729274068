import camelcaseKeys from 'camelcase-keys';
import { GraphQLClient, type RequestDocument, type Variables } from 'graphql-request';
import snakecaseKeys from 'snakecase-keys';
import { AFOSTO_GQL_ENDPOINT } from './constants';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import type { GraphQLClientConfig, GraphQLOnErrorHandler, GraphQLRequestOptions } from './types';

export const createGraphQLClient = (config: GraphQLClientConfig = {}) => {
  const {
    convertResponseToCamelCase: convertResponseToCamelCaseConfig,
    convertVariablesToSnakeCase: convertVariablesToSnakeCaseConfig,
    excludeConversionKeys: excludeConversionKeysConfig,
    fetch: clientConfigFetch = fetch,
    onError: clientConfigOnError,
    stopPaths: stopPathsConfig,
    url = AFOSTO_GQL_ENDPOINT,
    ...otherClientConfiguration
  } = config;
  const client = new GraphQLClient(url, {
    ...otherClientConfiguration,
    fetch: clientConfigFetch,
  });
  let convertResponseToCamelCase = convertResponseToCamelCaseConfig !== false;
  let convertVariablesToSnakeCase = convertVariablesToSnakeCaseConfig !== false;
  let excludeConversionKeys = Array.isArray(excludeConversionKeysConfig)
    ? excludeConversionKeysConfig
    : [];
  let stopPaths = Array.isArray(stopPathsConfig) ? stopPathsConfig : [];
  let onError = clientConfigOnError;

  /**
   * Execute a GraphQL API request.
   */
  const request = async <T>(
    query: RequestDocument | TypedDocumentNode<T, Variables>,
    variables = {},
    options: GraphQLRequestOptions<T>,
  ): Promise<T> => {
    const {
      convertResponseToCamelCase: convertResponseToCamelCaseOption,
      convertVariablesToSnakeCase: convertVariablesToSnakeCaseOption,
      excludeConversionKeys: excludeConversionKeysOption,
      stopPaths: stopPathsOption,
      transformResponse,
      ...otherOptions
    } = options || {};
    const requestExcludeConversionKeys = [
      ...excludeConversionKeys,
      ...(Array.isArray(excludeConversionKeysOption) ? excludeConversionKeysOption : []),
    ];
    const requestStopPaths = [
      ...stopPaths,
      ...(Array.isArray(stopPathsOption) ? stopPathsOption : []),
    ];
    let requestVariables = variables;
    let shouldConvertResponseToCamelCase = convertResponseToCamelCase;
    let shouldConvertVariablesToSnakeCase = convertVariablesToSnakeCase;

    if (
      convertResponseToCamelCaseOption !== null &&
      convertResponseToCamelCaseOption !== undefined
    ) {
      shouldConvertResponseToCamelCase = convertResponseToCamelCaseOption;
    }

    if (
      convertVariablesToSnakeCaseOption !== null &&
      convertVariablesToSnakeCaseOption !== undefined
    ) {
      shouldConvertVariablesToSnakeCase = convertVariablesToSnakeCaseOption;
    }

    if (shouldConvertVariablesToSnakeCase) {
      requestVariables = snakecaseKeys(variables, {
        deep: true,
        exclude: requestExcludeConversionKeys,
      });
    }

    let response: T = await client
      .request<T>(query, requestVariables, otherOptions)
      .catch(error => {
        if (onError && typeof onError === 'function') {
          onError(error, {
            query,
            variables: requestVariables,
            options,
            url,
          });
        }

        return Promise.reject(error);
      });

    if (Array.isArray(transformResponse)) {
      transformResponse.forEach(transform => {
        response = transform(response);
      });
    }

    if (shouldConvertResponseToCamelCase) {
      response = camelcaseKeys(response as never, {
        deep: true,
        exclude: requestExcludeConversionKeys,
        stopPaths: requestStopPaths,
      });
    }

    return response;
  };

  /**
   * Set the bearer token on the authorization header.
   */
  const setAuthorizationHeader = (token: string | null) => {
    client.setHeader('Authorization', token ? `Bearer ${token}` : '');
  };

  /**
   * Set the convert response to camel case configuration.
   */
  const setConvertResponseToCamelCase = (convert: boolean) => {
    convertResponseToCamelCase = convert;
  };

  /**
   * Set the convert variables to snake case configuration.
   */
  const setConvertVariablesToSnakeCase = (convert: boolean) => {
    convertVariablesToSnakeCase = convert;
  };

  /**
   * Set the keys that should be excluded from being converted to camel case and snake case.
   */
  const setExcludeConversionKeys = (keys: string[]) => {
    excludeConversionKeys = keys;
  };

  /**
   * Set the onError handler for the client.
   */
  const setOnErrorHandler = (onErrorCallback: GraphQLOnErrorHandler) => {
    onError = onErrorCallback;
  };

  /**
   * Set the paths that should be excluded from being converted to camel case.
   */
  const setStopPaths = (keys: string[]) => {
    stopPaths = keys;
  };

  return {
    client,
    request,
    setAuthorizationHeader,
    setConvertResponseToCamelCase,
    setConvertVariablesToSnakeCase,
    setExcludeConversionKeys,
    setOnErrorHandler,
    setStopPaths,
  };
};
