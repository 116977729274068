import { createUrlWithParams } from './createUrlWithParams';
import { parseUrlQuery } from './parseUrlQuery';

export const addQueryParamsToUrl = (url = '', params = {}) => {
  const [baseUrl, hash] = url.split('#');
  const [pathname] = baseUrl.split('?');
  const queryParams = parseUrlQuery(baseUrl);
  const paramKeys = Object.keys(params);
  const filteredQueryParams = Object.keys(queryParams)
    .filter(key => !paramKeys.includes(key))
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: queryParams[key],
      }),
      {},
    );

  const createdUrl = createUrlWithParams(pathname, {
    ...filteredQueryParams,
    ...params,
  });

  return `${createdUrl}${hash ? `#${hash}` : ''}`;
};

export default addQueryParamsToUrl;
