import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import history from '../../AppBase/history';

const isRunningFromFrame = window.self !== window.top;

Sentry.init({
  dsn: 'https://a1587c3c357dbf58da616c02a2c8e3a8@o4507548490530816.ingest.de.sentry.io/4507548494004304',
  release: import.meta.env.VITE_VERSION,
  enabled: import.meta.env.MODE === 'production' && !isRunningFromFrame,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.extraErrorDataIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      history,
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      blockAllMedia: false,
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: window?.location
        ? [window.location.origin, /^https:\/\/afosto.app.*/]
        : [],
      networkRequestHeaders: ['Accept-Language', 'Cache-Control', 'Referrer'],
      networkResponseHeaders: ['X-Ratelimit-Limit', 'X-Ratelimit-Remaining', 'X-Ratelimit-Reset'],
    }),
    Sentry.httpClientIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // 'localhost',
    // /^https:\/\/localhost\.afosto.app/,
    // /^https:\/\/afosto\.app/,
  ],
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
