import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, DialogActions, DialogContent, DialogHeader } from '@afosto/components';
import { getErrorMessage } from '@afosto/utils-shared';
import { useAuthentication } from '../../../AuthenticationProvider';
import useToast from '../../../hooks/useToast';
import translations from './translations';

const DefaultError = ({ onClose }) => {
  const intl = useIntl();
  const { clearSession, sessionError, silentAuthorization } = useAuthentication();
  const { code, error: errorMessage } = sessionError || {};
  const { enqueueToast } = useToast();
  const [isSilentAuthorizing, setIsSilentAuthorizing] = useState(false);
  const [sessionExtendError, setSessionExtendError] = useState(false);

  const handleBackToLogin = () => {
    clearSession();
    onClose();
  };

  const handleSilentAuthorize = async () => {
    try {
      setIsSilentAuthorizing(true);
      await silentAuthorization();
      onClose();
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueToast({
        severity: 'error',
        message: intl.formatMessage(translations.extendSessionErrorTitle),
        description: message,
        dismissible: true,
      });

      setSessionExtendError(true);
    } finally {
      setIsSilentAuthorizing(false);
    }
  };

  return (
    <>
      <DialogHeader
        title={intl.formatMessage(translations[sessionExtendError ? 'titleExtendError' : 'title'])}
        description={intl.formatMessage(
          translations[sessionExtendError ? 'descriptionExtendError' : 'description'],
        )}
      />
      <DialogContent>
        {!sessionExtendError && errorMessage && (
          <Alert variant="text" severity="error" sx={{ marginTop: 3 }} icon>
            {code ? `${code}: ` : ''}
            {errorMessage}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {!sessionExtendError && (
          <Button onClick={handleSilentAuthorize} loading={isSilentAuthorizing} size="large">
            <FormattedMessage {...translations.extendSession} />
          </Button>
        )}
        {sessionExtendError && (
          <Button onClick={handleBackToLogin} size="large">
            <FormattedMessage {...translations.loginAgain} />
          </Button>
        )}
      </DialogActions>
    </>
  );
};

DefaultError.propTypes = {
  onClose: PropTypes.func,
};

DefaultError.defaultProps = {
  onClose: undefined,
};

export default DefaultError;
