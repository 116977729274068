import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, DialogActions, DialogHeader } from '@afosto/components';
import { useAuthentication } from '../../../AuthenticationProvider';
import translations from './translations';

const InvalidSession = ({ onClose }) => {
  const intl = useIntl();
  const { clearSession } = useAuthentication();

  const handleBackToLogin = () => {
    clearSession();
    onClose();
  };

  return (
    <>
      <DialogHeader
        title={intl.formatMessage(translations.title)}
        description={intl.formatMessage(translations.description)}
      />
      <DialogActions>
        <Button onClick={handleBackToLogin} size="large">
          <FormattedMessage {...translations.loginButtonLabel} />
        </Button>
      </DialogActions>
    </>
  );
};

InvalidSession.propTypes = {
  onClose: PropTypes.func,
};

InvalidSession.defaultProps = {
  onClose: undefined,
};

export default InvalidSession;
