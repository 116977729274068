import { useEffect, useState } from 'react';
import usePusher from './usePusher';

const DEFAULT_CHANNEL_OPTIONS = {
  enabled: true,
};

const useChannel = (name, options = {}) => {
  const { enabled } = { ...DEFAULT_CHANNEL_OPTIONS, ...options };
  const { client, subscribeChannel, unsubscribeChannel } = usePusher();

  const [channel, setChannel] = useState(null);

  useEffect(() => {
    if (!client) {
      return;
    }

    if (!name) {
      // eslint-disable-next-line no-console
      console.warn('No channel name provided');
      return;
    }

    if (enabled) {
      const pusherChannel = subscribeChannel(name);
      setChannel(pusherChannel);
    } else {
      setChannel(null);
    }

    // eslint-disable-next-line consistent-return
    return () => {
      if (client && name && enabled) {
        unsubscribeChannel(name);
      }
    };
  }, [client, enabled, name, subscribeChannel, unsubscribeChannel]);

  return channel;
};

export default useChannel;
