import PropTypes from 'prop-types';
import LiveChatDrawer from './components/LiveChatDrawer';
import { createContext, useContext, useEffect, useState } from 'react';

const LiveChatContext = createContext({
  showLiveChatDrawer: false,
  unreadCount: 0,
  isEnabled: true,
});

export const useLiveChat = () => useContext(LiveChatContext);

const LiveChatProvider = ({ children, disableLiveChat }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [unreadCount, setUnreadCount] = useState(0);
  const [open, setOpen] = useState(false);

  const handleCloseChatDrawer = () => {
    setOpen(false);
  };

  const handleOpenChatDrawer = () => {
    setOpen(true);
  };

  const handleLoadLiveChat = () => {
    const status = window?.HubSpotConversations?.widget?.status();

    if (status.loaded) {
      window?.HubSpotConversations?.widget?.refresh();
      window?.HubSpotConversations?.widget?.open();
    } else {
      window?.HubSpotConversations?.widget?.load({ widgetOpen: true });
    }
  };

  useEffect(() => {
    // Add event listener.
    window.hsConversationsOnReady = [
      () => {
        setHasLoaded(true);
      },
    ];

    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: '#hubspot-livechat',
      enableWidgetCookieBanner: true,
    };

    // Create script component.
    let script = document.createElement('script');
    script.src = `//js.hs-scripts.com/143241827.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.hsConversationsOnReady = [];
    };
  }, []);

  useEffect(() => {
    window?.HubSpotConversations?.widget?.load({ widgetOpen: true });
  }, [hasLoaded]);

  useEffect(() => {
    window?.HubSpotConversations?.on('unreadConversationCountChanged', payload => {
      setUnreadCount(payload?.unreadCount);
    });

    return () => {
      window?.HubSpotConversations?.off('unreadConversationCountChanged', payload => {
        setUnreadCount(payload?.unreadCount);
      });
    };
  }, []);

  return (
    <LiveChatContext.Provider
      value={{
        showLiveChatDrawer: open,
        openLiveChatDrawer: handleOpenChatDrawer,
        closeLiveChatDrawer: handleCloseChatDrawer,
        unreadCount,
        isEnabled: !disableLiveChat,
      }}
    >
      {children}
      {!disableLiveChat && (
        <LiveChatDrawer
          open={open}
          loadLiveChat={handleLoadLiveChat}
          closeDrawer={handleCloseChatDrawer}
        />
      )}
    </LiveChatContext.Provider>
  );
};

LiveChatProvider.propTypes = {
  children: PropTypes.node,
  disableLiveChat: PropTypes.bool,
};

LiveChatProvider.defaultProps = {
  children: undefined,
  disableLiveChat: false,
};

export default LiveChatProvider;
