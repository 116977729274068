import { IconButton } from '@afosto/components';
import styled from '@emotion/styled';

export const CloseButton = styled(IconButton)`
  && {
    color: ${props => props.theme.palette.gray[300]} !important;

    &:hover {
      color: ${props => props.theme.palette.gray[75]} !important;
    }

    &:active {
      color: ${props => props.theme.palette.gray[75]} !important;
    }
  }
`;
