export const APP_SCOPES = [
  'app:integrations:read',
  'app:integrations:write',
  'aut:emails:read',
  'aut:emails:write',
  'aut:keyset:read',
  'aut:keyset:write',
  'aut:rules:read',
  'aut:rules:write',
  'doc:tables:read',
  'iam:logs:read',
  'iam:roles:read',
  'iam:series:read',
  'iam:tenants:write',
  'cat:channel:read',
  'cat:channel:write',
  'cat:prices:read',
  'cat:prices:write',
  'rel:contacts:read',
  'rel:contacts:write',
  'aut:project:read',
  'aut:project:write',
  'cat:prices:read',
  'cnt:batch:read',
  'cnt:batches:read',
  'cnt:batches:write',
  'cnt:datasets:read',
  'cnt:datasets:write',
  'cnt:files:read',
  'cnt:files:write',
  'cnt:instant:read',
  'cnt:instant:write',
  'odr:inventory:read',
  'odr:inventory:write',
  'odr:businesses:read',
  'odr:businesses:write',
  'odr:labels:write',
  'odr:locations:read',
  'odr:locations:write',
  'odr:carts:read',
  'odr:carts:write',
  'odr:deliveries:read',
  'odr:deliveries:write',
  'odr:coupons:read',
  'odr:coupons:write',
  'odr:labels:read',
  'odr:locations:read',
  'odr:methods:read',
  'odr:orders:read',
  'odr:orders:write',
  'odr:refunds:create',
  'odr:payments:create',
  'odr:payments:update',
  'odr:payment-methods:read',
  'odr:parcels:read',
  'odr:parcels:write',
  'odr:points:read',
  'odr:points:write',
  'odr:product:read',
  'odr:product:write',
  'odr:purchases:read',
  'odr:purchases:write',
  'odr:routing:read',
  'odr:shipping-methods:read',
  'odr:shipping-methods:write',
  'odr:supply:read',
  'odr:supply:update',
  'odr:zones:read',
  'odr:zones:write',
  'qcq:proxies:read',
  'stk:subscriptions:update',
  'aut:mirror:read',
  'aut:mirror:write',
];

export const CLIENT_ID = import.meta.env.VITE_CLIENT_ID;
