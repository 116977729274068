import styled from '@emotion/styled';
import { Paper } from '@afosto/components';

export const ToastBase = styled(Paper)`
  min-width: 600px;
  padding: ${props => props.theme.spacing(2)} ${props => props.theme.spacing(2.5)};
  font-family: ${props => props.theme.typography.bodyMedium.fontFamily};
  font-size: ${props => props.theme.typography.bodyMedium.fontSize};
  line-height: ${props => props.theme.typography.bodyMedium.lineHeight};
  font-weight: 500;
  background-color: ${props => props.theme.palette.gray[900]};
  border: 0;
  box-shadow: 0 10px 20px rgba(40, 61, 74, 0.15), 0 3px 6px rgba(40, 61, 74, 0.1);
  display: flex;
  align-items: center;
`;
