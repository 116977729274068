import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  Divider,
  IconButton,
} from '@afosto/components';
import { Times } from '@afosto/icons/solid';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import Icon from '../../../Icon';
import translations from '../../translations';

const SheetPositionsSelection = ({
  selectedPositions,
  onBack,
  onPrintMergedPdf,
  isPrinting,
  onClickPosition,
  onClose,
}) => {
  const intl = useIntl();

  return (
    <>
      <DialogHeader
        title={
          <Box display="flex" alignItems="center">
            <FormattedMessage {...translations.positionsTitle} />
            <IconButton sx={{ ml: 'auto' }} variant={'minimal'} size="small" onClick={onClose}>
              <Icon icon={Times} />
            </IconButton>
          </Box>
        }
        description={intl.formatMessage(translations.positionsDescription)}
        sx={{ position: 'sticky', zIndex: 2, top: 0, backgroundColor: 'common.white' }}
      />
      <Divider sx={{ mt: 2.5 }} />
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, pb: 3, overflow: 'auto' }}
      >
        <Box
          display="grid"
          gridTemplateColumns="repeat(2, 76px)"
          gridTemplateRows="repeat(2, 1fr)"
          gap={2}
          justifyContent="center"
          height={288}
          p={2}
          mx="auto"
          border="1px solid"
          borderColor="grey.200"
          borderRadius={1}
        >
          {Object.keys(selectedPositions).map((key, idx) => {
            const excluded = !selectedPositions[key];
            return (
              <Box
                key={key}
                onClick={onClickPosition(key)}
                component="button"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={excluded ? 'error.100' : 'transparent'}
                border="1px solid"
                borderColor={excluded ? 'error.500' : 'grey.200'}
                borderRadius={1}
                sx={theme => ({
                  ...theme.typography.h4,
                  transition: theme.transitions.create('background-color', {
                    duration: theme.transitions.duration.shortest,
                  }),
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: excluded ? 'error.200' : 'grey.50',
                  },
                })}
              >
                <FormattedNumber value={idx + 1} />
              </Box>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ position: 'sticky', mt: 'auto', bottom: 0, backgroundColor: 'common.white' }}
      >
        <Button onClick={onBack} variant="outlined" color="secondary" size="large">
          <FormattedMessage {...translations.back} />
        </Button>
        <Button size="large" onClick={onPrintMergedPdf} loading={isPrinting}>
          <FormattedMessage {...translations.print} />
        </Button>
      </DialogActions>
    </>
  );
};

export default SheetPositionsSelection;
