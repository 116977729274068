import { ThemeProvider } from '@afosto/components';
// eslint-disable-next-line @nx/enforce-module-boundaries
import '../../../fonts/index.css';
import dayjs from 'dayjs';
import PrintProvider from '../PrintProvider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import nl from 'javascript-time-ago/locale/nl';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider } from 'react-intl';
import AppBase from '../AppBase';
import AppProvider from '../AppProvider';
import AppSplashScreen from '../AppSplashScreen';
import { ConfirmationServiceProvider } from '../ConfirmDialog/ConfirmDialogService';
import JobsProvider from '../JobsProvider';
import LiveChatProvider from '../LiveChatProvider';
import NotificationsProvider from '../NotificationsProvider';
import PusherProvider from '../PusherProvider';
import SessionErrorDialog from '../SessionErrorDialog';
import SubscriptionsProvider from '../SubscriptionsProvider';
import theme from '../theme';
import ToastProvider from '../ToastProvider';
import { dutch, english } from './translations';
import intlErrorHandler from './utils/intlErrorHandler';
import setupValidationLocale from './utils/setupValidationLocale';

TimeAgo.addDefaultLocale(nl);
TimeAgo.addLocale(en);

setupValidationLocale();

const messages = {
  en: english,
  'en-au': english,
  'en-bz': english,
  'en-ca': english,
  'en-ie': english,
  'en-jm': english,
  'en-nz': english,
  'en-za': english,
  'en-tt': english,
  'en-gb': english,
  'en-us': english,
  nl: dutch,
  'nl-nl': dutch,
  'nl-be': dutch,
};

const DEFAULT_LOCALE = 'en';
const BROWSER_LOCALE = navigator.language?.toLowerCase();
const LOCALE = Object.keys(messages).includes(BROWSER_LOCALE) ? BROWSER_LOCALE : DEFAULT_LOCALE;

const AppMain = ({
  children,
  clientId,
  disableAuthentication,
  disablePrintProvider,
  disableLiveChat,
  // disableLogRocket,
  disableNotifications,
  disableReactQueryDevTools,
  disableSubscriptions,
  scopes,
  userMenuItems,
  appState,
}) => {
  const [navigationIsOpen, setNavigationIsOpen] = useState(false);
  const [organisationSwitchIsOpen, setOrganisationSwitchIsOpen] = useState(false);
  const [apps, setApps] = useState([]);
  const [isAppsLoading, setIsAppsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dayjs.locale(LOCALE);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HelmetProvider>
        <IntlProvider locale={LOCALE} messages={messages[LOCALE]} onError={intlErrorHandler}>
          <AppProvider
            value={{
              locale: LOCALE,
              navigationIsOpen,
              setNavigationIsOpen,
              organisationSwitchIsOpen,
              setOrganisationSwitchIsOpen,
              userMenuItems,
              apps,
              setApps,
              setIsAppsLoading,
              isAppsLoading,
              isLoading,
              setIsLoading,
              ...appState,
            }}
          >
            <AppBase
              clientId={clientId}
              disableAuthentication={disableAuthentication}
              scopes={scopes}
            >
              <PusherProvider>
                <JobsProvider>
                  <ToastProvider>
                    <ConfirmationServiceProvider>
                      <NotificationsProvider disableNotifications={disableNotifications}>
                        <SubscriptionsProvider disableSubscriptions={disableSubscriptions}>
                          <PrintProvider disablePrintProvider={disablePrintProvider}>
                            <LiveChatProvider disableLiveChat={disableLiveChat}>
                              <AppSplashScreen isLoading={isLoading}>
                                {children}
                                {!disableAuthentication && <SessionErrorDialog />}
                              </AppSplashScreen>
                              {!disableReactQueryDevTools && (
                                <ReactQueryDevtools initialIsOpen={false} />
                              )}
                            </LiveChatProvider>
                          </PrintProvider>
                        </SubscriptionsProvider>
                      </NotificationsProvider>
                    </ConfirmationServiceProvider>
                  </ToastProvider>
                </JobsProvider>
              </PusherProvider>
            </AppBase>
          </AppProvider>
        </IntlProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
};

AppMain.propTypes = {
  // eslint-disable-next-line react/require-default-props
  appState: PropTypes.object,
  /** The content in this wrapper will have acces to the theme, the user and other app level data. */
  children: PropTypes.node.isRequired,
  clientId: PropTypes.string.isRequired,
  /** Disable the Authentication provider. Mainly used for the storybook */
  disableAuthentication: PropTypes.bool,
  /** Disable HubSpot live chat. Mainly use fore the storybook */
  disableLiveChat: PropTypes.bool,
  /** Disable LogRocket. Mainly used for the storybook */
  disableLogRocket: PropTypes.bool,
  /** Disable the Notifications provider. Mainly used for the storybook */
  disableNotifications: PropTypes.bool,
  /** Disable the ReactQuery dev tools. Mainly used for the storybook */
  disableReactQueryDevTools: PropTypes.bool,
  /** Disable the Subscriptions provider. Mainly used for the storybook */
  disableSubscriptions: PropTypes.bool,
  scopes: PropTypes.array,
  userMenuItems: PropTypes.array,
};

AppMain.defaultProps = {
  appState: {},
  disableAuthentication: false,
  disableLiveChat: false,
  disableLogRocket: false,
  disableNotifications: false,
  disableReactQueryDevTools: false,
  disableSubscriptions: false,
  scopes: [],
  userMenuItems: undefined,
};

export default AppMain;
